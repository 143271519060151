import React, { FC } from "react";
import { observer } from "mobx-react";
import { isMobileOnly } from "react-device-detect";
import { useStore } from "../../../services";
import styled from "styled-components";
import { config, media } from "../../../config/Config";
import RightArrow from "../../../components/Icon/RightArrow";

const IncompatibleBrowser: FC = observer(() => {
    const store = useStore();
    const handleOnClick = (): void => {
        store.ui.isContinueIncompatibleBrowser = true;
    };
    return (
        <Container>
            <LogoContainer>
                <Logo src={"/images/t-logo-blue.svg"} />
            </LogoContainer>
            <Headline>This browser is not supported.</Headline>
            <Description>
                {isMobileOnly ? config.unsupportedBrowserDesc.mobile : config.unsupportedBrowserDesc.desktop}
            </Description>
            <Continue onClick={handleOnClick}>
                <ContinueText>Continue anyway</ContinueText>
                <ContinueIconContainer>
                    <ContinueIcon />
                    <ContinueIconOnHover />
                </ContinueIconContainer>
            </Continue>
        </Container>
    );
});
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    user-select: none;
    // Disable image selection
    img {
        pointer-events: none;
    }
    ${media.mobile} {
        width: 100%;
    }
`;

const LogoContainer = styled.div`
    height: 100px;
    width: 86px;
`;

const Logo = styled.img`
    height: 100px;
    width: 86px;
`;

const Headline = styled.div`
    height: 51px;
    color: #001e82;
    font-size: 42px;
    font-weight: bold;
    line-height: 51px;
    text-align: center;
    margin-top: 25px;
    ${media.mobile} {
        font-size: 24px;
        line-height: normal;
    }
`;

const Description = styled.div`
    width: 784px;
    color: #3e3e3e;
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-top: 25px;
    ${media.mobile} {
        width: auto;
        padding: 20px;
        margin: 0;
        font-size: 22px;
    }
`;

const ContinueText = styled.div`
    color: #0064d2;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
`;

const ContinueIconContainer = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContinueIcon = styled(RightArrow).attrs((props) => ({ fill: "#0064d2" }))`
    height: 11.5px;
    width: 11.5px;
    margin-top: 7px;
`;
const ContinueIconOnHover = styled(RightArrow).attrs((props) => ({ fill: "#001e82" }))`
    height: 11.5px;
    width: 11.5px;
    margin-top: 7px;
    display: none;
`;

const Continue = styled.div`
    height: 26px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        cursor: pointer;

        ${ContinueText} {
            transition: color 0.2s;
            color: #001e82;
            text-align: center;
            margin-right: 5px;
        }
        ${ContinueIcon} {
            display: none;
        }
        ${ContinueIconOnHover} {
            display: block;
        }
    }
    ${media.mobile} {
        margin-top: 15px;
    }
`;

export default IncompatibleBrowser;
