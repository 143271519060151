import React from "react";
import styled from "styled-components";

export interface Props {
    text: string;
    backgroundColour: string;
    svgIcon: React.ReactNode;
    buttonText?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCloseToastClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ConfigurationToast: React.FC<Props> = (props: Props) => {
    const textButton = props.buttonText !== undefined;

    return (
        <Container>
            <ToastContainer backgroundColor={props.backgroundColour}>
                <FormatContainer>
                    <IconContainer>{props.svgIcon}</IconContainer>
                    <MultiTextContainer>
                        <TextContainer>{props.text}</TextContainer>
                        {textButton && <TextButton onClick={props.onClick}>{props.buttonText}</TextButton>}
                    </MultiTextContainer>
                    <CloseButtonContainer>
                        <CloseButton onClick={props.onCloseToastClick}>✕</CloseButton>
                    </CloseButtonContainer>
                </FormatContainer>
            </ToastContainer>
        </Container>
    );
};

export default ConfigurationToast;

const Container = styled.div`
    font-size: 14px;
    width: 800px;
    height: auto;
`;

const FormatContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
`;

const IconContainer = styled.div`
    width: 50px;
    height: 50px;
    padding-left: 30px;
    transform: translateY(-50%);
`;

const MultiTextContainer = styled.div`
    position: relative;
    width: 80%;
    font-size: 18px;
    padding-bottom: 10px;
    margin: 0;
    transform: translateY(-50%);
`;

const TextContainer = styled.div`
    display: inline;
`;

const TextButton = styled.button`
    font-size: 18px;
    text-decoration: underline;
    display: inline;
    color: blue;
    cursor: pointer;
    background: none;
    border: none;
`;

const CloseButtonContainer = styled.div`
    position: relative;
    width: 10%;
    padding-left: 20px;
    transform: translateY(-60%);
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #637481;
`;

const ToastContainer = styled.div<{ backgroundColor: string }>`
    background: ${(props): string => props.backgroundColor};
    padding-top: 60px;
    border-radius: 8px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
`;
