import React, { FC } from "react";
import styled from "styled-components";
import PopulationBar from "../PopulationBar/PopulationBar";

export interface Props {
    capacity: number;
    population: number;
    headerText?: string;
}

const CarouselCard: FC<Props> = (props: Props) => {
    const { capacity, headerText, population } = props;

    const bookablePercent = (population: number | undefined, capacity: number | undefined) => {
        if (population && capacity) {
            return Math.round((population / capacity) * 100);
        } else return 0;
    };

    const bookableLine = (scaling: number, bookablePercent: number): number => {
        return (scaling / 100) * bookablePercent;
    };

    const barScaling = 100;

    return (
        <CardContainer tabIndex={0}>
            <Card>
                <CardHeader>{headerText}</CardHeader>
                <DeskPopulationContainer labelSize={12}>
                    <LeftText>
                        <PopulationCount>{bookablePercent(population, capacity)}</PopulationCount>
                        <LabelContainer>% bookable</LabelContainer>
                    </LeftText>
                    <RightText>
                        <PopulationCapacityContainer>
                            {population} of {capacity}
                        </PopulationCapacityContainer>
                    </RightText>
                    <LineContainer>
                        <UpperPopulationLine
                            offset={
                                bookableLine(barScaling, bookablePercent(population, capacity)) < barScaling
                                    ? bookableLine(barScaling, bookablePercent(population, capacity))
                                    : barScaling
                            }
                        />
                        <PopulationLine
                            offset={
                                bookableLine(barScaling, bookablePercent(population, capacity)) < barScaling
                                    ? bookableLine(barScaling, bookablePercent(population, capacity))
                                    : barScaling
                            }
                        />
                        <UpperCapacityLine offset={barScaling} />
                        <CapacityLine offset={barScaling} />
                    </LineContainer>
                    <PopulationBar
                        population={population}
                        capacity={capacity}
                        showCapacity={false}
                        scaling={true}
                        scalingAmount={barScaling}
                        iconSize={20}
                        barWeight={8}
                        barColour="#008a00"
                    />
                    <TextContainer offset={bookableLine(barScaling, bookablePercent(population, capacity))}>
                        <NonBookableText
                            offset={
                                bookableLine(barScaling, bookablePercent(population, capacity)) < barScaling
                                    ? bookableLine(barScaling, bookablePercent(population, capacity))
                                    : barScaling
                            }
                        >
                            {capacity - population > 0 ? capacity - population : 0} Not bookable
                        </NonBookableText>
                    </TextContainer>
                    <NonBookable>
                        <PopulationBar
                            population={capacity - population < capacity ? capacity - population : 0}
                            capacity={capacity}
                            showCapacity={false}
                            scaling={true}
                            scalingAmount={barScaling}
                            iconSize={20}
                            barWeight={4}
                            barColour="#d0021b"
                            barOffset={population}
                        />
                    </NonBookable>
                </DeskPopulationContainer>
            </Card>
        </CardContainer>
    );
};

export default CarouselCard;

const CapacityLine = styled.div<{ offset?: number }>`
    border-left: 2px solid #e9eef4;
    left: ${(props): string => (props.offset ? props.offset + "%" : "0%")};
    height: 38px;
    position: absolute;
`;

const UpperCapacityLine = styled.div<{ offset?: number }>`
    border-left: 1px solid black;
    left: ${(props): string => (props.offset ? props.offset + "%" : "0%")};
    height: 8px;
    position: absolute;
    z-index: 2;
`;

const PopulationLine = styled.div<{ offset?: number }>`
    border-left: 1px solid #e9eef4;
    left: ${(props): string => (props.offset ? props.offset + "%" : "0%")};
    height: 38px;
    position: absolute;
`;

const UpperPopulationLine = styled.div<{ offset?: number }>`
    border-left: 1px solid black;
    left: ${(props): string => (props.offset ? props.offset + "%" : "0%")};
    height: 8px;
    position: absolute;
    z-index: 1;
`;

const NonBookableText = styled.div<{ offset: number }>`
    font-size: 12px;
    white-space: nowrap;
    margin-top: -5px;
    padding-left: ${(props): string => (props.offset > 80 ? "" : props.offset + "%")};
    margin-left: 4px;
    float: ${(props): string => (props.offset > 80 ? "right" : "")};
`;

const TextContainer = styled.div<{ offset: number }>`
    z-index: 1;
    margin-bottom: 6px;
    position: relative;
    white-space: nowrap;
`;

const LineContainer = styled.div`
    position: relative;
    margin-left: 4px;
`;

const CardContainer = styled.div<{ width?: number }>`
    position: relative;
    height: 214px;
    background-color: #ffffff;
`;

const Card = styled.div`
    transition: all 0.4s;
`;

const CardHeader = styled.div`
    font-weight: 700;
    padding-left: 30px;
    padding-top: 26px;
    font-size: 20px; ;
`;

const NonBookable = styled.div`
    margin-top: -5px;
`;

const LabelContainer = styled.div`
    display: inline-block;
`;
const PopulationCount = styled.div`
    display: inline-block;
`;
const DeskPopulationContainer = styled.div<{ labelSize?: number }>`
    font-size: ${(props): string => (props.labelSize ? props.labelSize + "px" : "16px")};
    padding: 13px 26px 0;
    position: relative;
`;

const PopulationCapacityContainer = styled.div`
    display: inline-block;
`;

const LeftText = styled.div`
    display: inline-block;
    font-size: 14px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
`;

const RightText = styled.div`
    float: right;
    font-size: 14px;
    margin-right: 48px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
`;
