import React, { FC } from "react";
import App from "../../config/Config";
import styled from "styled-components";

const Branding: FC = () => {
    const { title, logo } = App.config.brand;

    return (
        <AppBranding>
            <BrandLogo src={logo} />
            <BrandTitle>{title.toUpperCase()}</BrandTitle>
        </AppBranding>
    );
};

const AppBranding = styled.div`
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-right: 1px solid rgb(236, 236, 241);
    box-align: center;
    box-pack: center;
    display: flex;
    height: 65px;
    justify-content: center;
    line-height: 1.15;
    min-width: 190px;
    user-select: none;
    width: 195px;
`;

const BrandLogo = styled.img`
    line-height: 1.15;
    user-select: none;
    border-style: none;
    height: 24px;
    width: 21px;
    margin-right: 6px;
    pointer-events: none;
`;

const BrandTitle = styled.div`
    user-select: none;
    height: 24px;
    width: 150px;
    color: rgb(62, 62, 62);
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
`;

export default Branding;
