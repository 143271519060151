import React, { Component, ReactNode } from "react";
import { AppContextProvider } from "./components";
import { SecureRoute } from "./services";
import { Login, NotFound, HomePage } from "./views/Common";
import { BuildingsEfficiency, BuildingsOverview, RealTimeFloor } from "./views/Spaces";
import { Report } from "./views/Report";
import { Assets, Buildings, Floors } from "./views/Management";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Organisations } from "./views/Devices";
import { FloorPlan } from "./views/Management/Floors/FloorPlan/FloorPlan";

class App extends Component {
    public render(): ReactNode {
        return (
            <AppContextProvider>
                <Router>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Login />} />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/home"
                                element={
                                    <SecureRoute>
                                        <HomePage />
                                    </SecureRoute>
                                }
                            />
                            <Route path="/spaces">
                                <Route
                                    index
                                    element={
                                        <SecureRoute>
                                            <BuildingsOverview />
                                        </SecureRoute>
                                    }
                                />
                                <Route path="buildings">
                                    <Route
                                        index
                                        element={
                                            <SecureRoute>
                                                <BuildingsOverview />
                                            </SecureRoute>
                                        }
                                    />
                                    <Route path=":buildingId">
                                        <Route
                                            index
                                            element={
                                                <SecureRoute>
                                                    <BuildingsEfficiency />
                                                </SecureRoute>
                                            }
                                        />
                                        <Route path="floors/:floorNumber">
                                            <Route
                                                index
                                                element={
                                                    <SecureRoute>
                                                        <RealTimeFloor />
                                                    </SecureRoute>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route
                                path="/reports"
                                element={
                                    <SecureRoute>
                                        <Report />
                                    </SecureRoute>
                                }
                            />
                            <Route path="/building-management">
                                <Route
                                    index
                                    element={
                                        <SecureRoute>
                                            <Buildings />
                                        </SecureRoute>
                                    }
                                />
                                <Route path="buildings">
                                    <Route
                                        index
                                        element={
                                            <SecureRoute>
                                                <Buildings />
                                            </SecureRoute>
                                        }
                                    />
                                    <Route path=":buildingId">
                                        <Route
                                            index
                                            element={
                                                <SecureRoute>
                                                    <Floors />
                                                </SecureRoute>
                                            }
                                        />
                                        <Route path="floors">
                                            <Route
                                                index
                                                element={
                                                    <SecureRoute>
                                                        <Floors />
                                                    </SecureRoute>
                                                }
                                            />
                                            <Route path=":floorId">
                                                <Route
                                                    index
                                                    element={
                                                        <SecureRoute>
                                                            <Assets />
                                                        </SecureRoute>
                                                    }
                                                />
                                                <Route path="floorplan">
                                                    <Route
                                                        index
                                                        element={
                                                            <SecureRoute>
                                                                <FloorPlan />
                                                            </SecureRoute>
                                                        }
                                                    />
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                            <Route path="/device-management">
                                <Route
                                    index
                                    element={
                                        <SecureRoute>
                                            <Organisations />
                                        </SecureRoute>
                                    }
                                />
                                <Route path="organisations">
                                    <Route
                                        index
                                        element={
                                            <SecureRoute>
                                                <Organisations />
                                            </SecureRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </Router>
            </AppContextProvider>
        );
    }
}

export default App;
