import React, { ComponentType, ReactNode } from "react";
import useStore from "./useStore";

/**
 * WithAppContext HOC
 * @param WrappedComponent - Component to pass
 *
 * @description
 * Takes a component and wraps it in an AppContext to pass context values.
 * Use with class components. FCs can better implement with with useContext hook.
 *
 * @example
 * import { WithAppContext } from "services";
 * // other code of InnerComponent
 * export default WithAppContext(InnerComponent);
 *
 * References:
 *  - https://stackoverflow.com/a/49870973
 *  - https://shevchenkonik.com/blog/react-typescript-mobx
 *  - https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example
 *  - https://maxrozen.com/2020/04/10/implement-higher-order-component-react-typescript/
 */
function withStore<T extends React.Component>(WrappedComponent: ComponentType<T>): ReactNode {
    const ComponentWithStore = (props: T): ReactNode => {
        const store = useStore();

        return <WrappedComponent {...(props as T)} value={store} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    ComponentWithStore.displayName = `WithAppContext(${displayName})`;

    return ComponentWithStore;
}

export default withStore;
