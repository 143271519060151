import { SmartSpaces } from "./SmartSpaces";
import { createRequest, errorCallback, maxContinuousWaitTime, continuousWaitTimeIncrease } from "./ServiceHandlers";

/**
 * Get spaceInsights data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getSpaceInsightsWithRetry = async (
    waitTime: number,
    authToken: string,
    getSpaceInsightsArguments: {
        spaceId?: number;
        planId?: number;
        insightType?: "PLAN" | "DESK" | "MEETING_ROOM";
        recentOccupancyDuration?: number;
    },
): Promise<{ [id: string]: Array<SmartSpaces.WebApp.SpaceInsight> }> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getSpaceInsights(authToken, getSpaceInsightsArguments).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getSpaceInsightsWithRetry(wait, authToken, getSpaceInsightsArguments));
                },
            );
        }, waitTime * 1000),
    );
};

/**
 * Gets the space insights, with configuration options / filters for space, plan, insight type and recent occupancy duration.
 *
 * @param token - the auth token
 * @param spaceId - space of the plans to be fetched
 */
const getSpaceInsights = async (
    token: string,
    params?: {
        spaceId?: number;
        planId?: number;
        insightType?: "PLAN" | "DESK" | "MEETING_ROOM";
        recentOccupancyDuration?: number;
    },
): Promise<{ [id: string]: Array<SmartSpaces.WebApp.SpaceInsight> }> => {
    const { body } = await createRequest({
        apiPath: `/space-insights`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        ...(params && (params.spaceId || params.planId || params.insightType || params.recentOccupancyDuration)
            ? {
                  body: {
                      ...(params.spaceId
                          ? {
                                spaceId: params.spaceId,
                            }
                          : {}),
                      ...(params.planId
                          ? {
                                planId: params.planId,
                            }
                          : {}),
                      ...(params.insightType
                          ? {
                                type: params.insightType,
                            }
                          : {}),
                      ...(params.recentOccupancyDuration
                          ? {
                                recentOccupancyDuration: params.recentOccupancyDuration,
                            }
                          : {}),
                  },
              }
            : {}),
    });

    const insights: Array<SmartSpaces.WebApp.SpaceInsight> = typeof body === "object" ? body : JSON.parse(body);
    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const insightsHashTable = insights.reduce((a: any, e: any) => {
        if (!a[e.planId]) {
            a[e.planId] = [];
        }
        a[e.planId].push(e);
        return a;
    }, {});
    return insightsHashTable;
};
