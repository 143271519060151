import { useEffect, MutableRefObject } from "react";

/*
  Custom outside click Hook
  See: https://stackoverflow.com/a/54292872/699963
*/
export const useOuterClickNotifier = (
    onOuterClick: (e: MouseEvent) => void,
    innerRef: MutableRefObject<HTMLDivElement | null>,
): void => {
    useEffect(
        () => {
            if (innerRef.current) {
                // add listener only, if element exists
                const handleClick = (e: MouseEvent): void => {
                    if (innerRef.current && e.target instanceof Node && !innerRef.current.contains(e.target)) {
                        onOuterClick(e);
                    }
                };

                document.addEventListener("click", handleClick);
                // unmount previous listener first
                return (): void => document.removeEventListener("click", handleClick);
            }
            return;
        },
        [onOuterClick, innerRef], // invoke again, if deps have changed
    );
};
