import React, { FC, ReactNode, useEffect, useState, useReducer } from "react";
import { Dashboard, Management } from "../../../layouts";
import { observer } from "mobx-react";
import { useStore, UIView, UIStore } from "../../../services";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import CarouselCard from "../../../components/Tiles/CarouselCard/CarouselCard";
import OccupancyList from "../../../components/List/OccupancyList/OccupancyList";
import styled from "styled-components";
import BusinessIcon from "@material-ui/icons/Business";
import { toJS } from "mobx";
import { exportCSVFile, getNewDate } from "../../../services/Hooks/csvTools";
import { Throbber } from "../../../components";
import LoadableContainer from "../../../components/Common/LoadableContainer/LoadableContainer";
import { useParams } from "react-router";
import ToggleAssetModal, { ModalTypes } from "../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import ManageFloor from "./ManageFloor/ManageFloor";

export interface ListData {
    id: number;
    listItem: {
        label: string;
        deskPopulation?: number;
        deskCapacity?: number;
        meetingRoomPopulation?: number;
        meetingRoomCapacity?: number;
        carparkPopulation?: number;
        carparkCapacity?: number;
        urlPath?: string;
        displayToggle: boolean;
        bookable: boolean;
    };
}

const csvHeaders = {
    email: "Email",
    bookingDate: "Booking Date",
    level: "Level",
    resourceName: "Name",
};

enum BookingTypes {
    Unspecified,
    SiteVisit,
    Desk,
    Room,
    CollaborationSpace,
    CarSpace,
}

const toggleProps = {
    primaryButtonBackgroundColour: "#0064d2",
    secondaryButtonTextColour: "#001e82",
    primaryButtonText: "Confirm",
    secondaryButtonText: "Cancel",
    closeOnClickSecondary: true,
    primaryButtonTextColour: "#ffffff",
    closeOnClickPrimary: false,
};

const Floors: FC = observer(() => {
    const dropDownList: Array<string> = ["All Floors"];

    const store = useStore();

    const metaData = toJS(store.data.management.customerMetaData);
    const dropDownData = toJS(store.data.management.adminFloors);
    const floors = dropDownData?.results;
    const adminBuilding = toJS(store.data.management.adminBuilding);
    const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);

    const hasLoaded = floors?.length > 0 && adminBuilding?.buildingId === buildingId;
    const buildingLabel: string = hasLoaded
        ? `${adminBuilding?.buildingName}, ${adminBuilding?.address.city}, ${adminBuilding?.address.country}`
        : "";

    const selectedBuilding = {
        id: buildingId,
        label: buildingLabel,
    };

    let listData: Array<ListData> = [];

    const [updateList, setUpdateList] = useReducer((x) => x + 1, 0);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(true);
    const [toggleFloorBooking, setToggleFloorBooking] = useState(false);
    const [numBookable, setNumBookable] = useState(0);
    const [modalType, setModalType] = useState(0);
    const [exportBookings, setExportBookings] = useState(false);
    const [exportCSV, setExportCSV] = useState<Array<Admin.UserBooking>>([
        { email: "", bookingDate: "", level: 0, resourceName: "" },
    ]);
    const [selectedFloorData, setSelectedFloorData] = useState<Admin.Floor>({} as Admin.Floor);

    useEffect(() => {
        setIsMetaDataLoading(true);
        store.ui.setSelectedBuilding(selectedBuilding);

        // Make the API call if there is parity
        if (adminBuilding.buildingId !== buildingId) {
            store.subscription.management.getAdminBuilding(buildingId);
        }

        // Remove any selected location filtering.
        store.ui.setSelectedLocation("");

        store.subscription.management.getAllCustomerMetaData(undefined, store.ui.selectedBuilding?.id).then(() => {
            setIsMetaDataLoading(false);
        });

        store.ui.setCurrentView(UIView.FloorManagement);
    }, [hasLoaded]);

    const breadcrumbs = UIStore.generateBreadcrumbs(UIView.BuildingManagement, selectedBuilding);

    // -- This will be updated in the future when floor filtration feature is defined
    const selectedItem = (item?: string) => item;

    // -- Drop down integration For Post MVP
    const generateDropDown = (): Array<string> => {
        floors.forEach((floor) => {
            dropDownList.push(floor.floorName);
        });
        return dropDownList;
    };

    const modalCancel = () => {
        setUpdateList();
        setToggleFloorBooking(false);
    };

    const compareBookingDates = (a: Admin.UserBooking, b: Admin.UserBooking): number => {
        if (a.bookingDate < b.bookingDate) return -1;
        if (a.bookingDate > b.bookingDate) return 1;
        return 0;
    };

    const modalConfirm = () => {
        setModalType(ModalTypes.ToggleStatusLoading);

        if (exportBookings && ModalTypes.NonBookableWithBookings) {
            const fileName = `CancelledBookingFor${exportCSV[0].level}_${getNewDate()}`;
            exportCSVFile(exportCSV, fileName, csvHeaders);
        }

        const assetBooked = setSelectedAsset(selectedFloorData.floorId, selectedFloorData.bookable);

        assetBooked
            .then(async () => {
                const adminFloor = toJS(store.data.management.adminFloor);

                await Promise.allSettled([
                    store.subscription.management.getCustomerFloorData(buildingId),
                    store.subscription.management.getAllCustomerMetaData(undefined, buildingId),
                ]);

                setToggleFloorBooking(false);
                setModalType(ModalTypes.Hidden);

                // Update stored floor data when floor attributes change
                if (adminFloor.floorId === selectedFloorData.floorId) {
                    store.data.management.setAdminFloorData(selectedFloorData);
                }
            })
            .catch(() => {
                setModalType(ModalTypes.Error);
                setToggleFloorBooking(true);
                setUpdateList();
            });
    };

    const setSelectedAsset = async (floorId: number, status: boolean): Promise<boolean> => {
        const floorBooked = await store.subscription.management.setFloorBookable(floorId, status);
        return floorBooked;
    };

    const onChangeBooking = (floorId: number, bookable: boolean, floorName: string) => {
        setToggleFloorBooking(true);
        setModalType(ModalTypes.Loading);
        setSelectedFloorData({ floorId, bookable, floorName } as Admin.Floor);
        if (bookable === true) {
            setModalType(ModalTypes.Bookable);
            setExportBookings(false);
            return;
        }
        const bookings = store.subscription.management.getCsvBookings(buildingId, floorId);
        bookings.then((res) => {
            if (bookable === false && res.length > 0) {
                const toExport = res.map((result) => {
                    const booking: Admin.UserBooking = {
                        email: result.email,
                        bookingDate: result.bookingDate,
                        level: result.level,
                        resourceName: result.resourceName,
                    };
                    return booking;
                });

                toExport.sort(compareBookingDates);
                setExportCSV(toExport);
                setExportBookings(true);
                setNumBookable(res.length);
                setModalType(ModalTypes.NonBookableWithBookings);
            } else if (bookable === false) {
                setModalType(ModalTypes.NonBookable);
                setExportBookings(false);
            }
        });
    };

    //

    const onChangeCheckbox = (checked: boolean): void => {
        setExportBookings(checked);
    };

    const generateSummaryCards = (): ReactNode => {
        let deskTotal = 0;
        let deskBookable = 0;
        let carSpaceTotal = 0;
        let carSpaceBookable = 0;
        let roomTotal = 0;
        let roomBookable = 0;

        let showDeskSummary = false;
        let showCarSpaceSummary = false;
        let showRoomSummary = false;

        metaData.forEach((resource) => {
            if (resource.resourceType === BookingTypes[BookingTypes.Desk]) {
                deskTotal = resource.total;
                deskBookable = resource.bookable;
                showDeskSummary = deskTotal > 0 ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.CarSpace]) {
                carSpaceTotal = resource.total;
                carSpaceBookable = resource.bookable;
                showCarSpaceSummary = carSpaceTotal > 0 ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.Room]) {
                roomTotal = resource.total;
                roomBookable = resource.bookable;
                showRoomSummary = roomTotal > 0 ? true : false;
            }
        });

        return (
            <SummariesContainer showSummaries={showDeskSummary || showRoomSummary || showCarSpaceSummary}>
                <LoadableContainer isLoading={isMetaDataLoading}>
                    {showDeskSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Desks" capacity={deskTotal} population={deskBookable} />
                        </SummaryContainer>
                    )}

                    {showRoomSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Meeting Rooms" capacity={roomTotal} population={roomBookable} />
                        </SummaryContainer>
                    )}

                    {showCarSpaceSummary && (
                        <SummaryContainer>
                            <CarouselCard
                                headerText="Car Parks"
                                capacity={carSpaceTotal}
                                population={carSpaceBookable}
                            />
                        </SummaryContainer>
                    )}
                </LoadableContainer>
            </SummariesContainer>
        );
    };

    const displayModal = (modalType: number, numBookings: number, selectedFloorData: Admin.Floor): ReactNode => {
        // Set loading indicator as the default
        let modal = (
            <ToggleAssetModal
                title="Retrieving booking status..."
                renderCheckbox={false}
                checkboxText=""
                primaryButtonText=""
                secondaryButtonText="Cancel"
                onClickPrimary={modalCancel}
                onClickSecondary={modalCancel}
                onEscapeClicked={modalCancel}
            >
                <ModalContent>
                    <Throbber />
                </ModalContent>
            </ToggleAssetModal>
        );

        switch (modalType) {
            case ModalTypes.Bookable:
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Do you really want to make this level bookable?"
                        renderCheckbox={false}
                        checkboxText=""
                        onClickPrimary={modalConfirm}
                        onClickSecondary={modalCancel}
                        onEscapeClicked={modalCancel}
                    >
                        <ModalText>
                            Any resources set to bookable on this level will
                            <br />
                            become available in the myWorkplace app.
                        </ModalText>
                    </ToggleAssetModal>
                );
                break;

            case ModalTypes.Error:
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Sorry, an error has occurred"
                        renderCheckbox={false}
                        checkboxText=""
                        primaryButtonText="Close"
                        secondaryButtonText=""
                        onClickPrimary={modalCancel}
                        onClickSecondary={modalCancel}
                        onEscapeClicked={modalCancel}
                    >
                        <ModalText>Please try again later.</ModalText>
                    </ToggleAssetModal>
                );
                break;

            case ModalTypes.NonBookableWithBookings:
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Do you really want to make this level not bookable?"
                        renderCheckbox={true}
                        onChangeCheckbox={onChangeCheckbox}
                        checkboxText="Download CSV of upcoming bookings"
                        onClickPrimary={modalConfirm}
                        onClickSecondary={modalCancel}
                        onEscapeClicked={modalCancel}
                    >
                        <ModalText>
                            <strong>
                                {`${numBookings} upcoming ${
                                    numBookings < 2 ? "booking" : "bookings"
                                } will be cancelled if you`}
                                <br />
                                proceed.{" "}
                            </strong>
                            See below to download booking details.
                        </ModalText>
                        <ModalText>
                            Any resources set to bookable on this level will no
                            <br />
                            longer be available in the myWorkplace app.
                        </ModalText>
                    </ToggleAssetModal>
                );
                break;

            case ModalTypes.NonBookable:
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        onClickPrimary={modalConfirm}
                        onClickSecondary={modalCancel}
                        onEscapeClicked={modalCancel}
                        title="Do you really want to make this level not bookable?"
                        renderCheckbox={false}
                        checkboxText=""
                    >
                        <ModalText>
                            Any resources set to bookable on this level will no
                            <br />
                            longer be available in the myWorkplace app.
                        </ModalText>
                        <ModalText>There are currently no bookings on this level.</ModalText>
                    </ToggleAssetModal>
                );
                break;

            case ModalTypes.ToggleStatusLoading:
                modal = (
                    <ToggleAssetModal
                        renderCheckbox={false}
                        title="Updating floor status"
                        checkboxText=""
                        primaryButtonText=""
                        secondaryButtonText=""
                        onClickPrimary={modalCancel}
                        onClickSecondary={modalCancel}
                        onEscapeClicked={modalCancel}
                    >
                        <ModalContent>
                            <Throbber />
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;

            default:
                break;
        }

        return modal;
    };

    const generateOccupancyList = (): ReactNode => {
        listData = floors.map((floor) => {
            let desksBookable: number | undefined;
            let desksCapacity: number | undefined;
            let meetingRoomBookable: number | undefined;
            let meetingRoomCapacity: number | undefined;
            let carParksBookable: number | undefined;
            let carParksCapacity: number | undefined;

            floor.bookables?.length &&
                floor.bookables.forEach((bookingType) => {
                    if (bookingType.resourceType === BookingTypes[BookingTypes.Desk]) {
                        desksBookable = bookingType.bookable;
                        desksCapacity = bookingType.total;
                    }
                    if (bookingType.resourceType === BookingTypes[BookingTypes.Room]) {
                        meetingRoomBookable = bookingType.bookable;
                        meetingRoomCapacity = bookingType.total;
                    }
                    if (bookingType.resourceType === BookingTypes[BookingTypes.CarSpace]) {
                        carParksBookable = bookingType.bookable;
                        carParksCapacity = bookingType.total;
                    }
                });

            const data: ListData = {
                id: floor.floorId,
                listItem: {
                    label: floor.floorName,
                    bookable: floor.bookable,
                    deskPopulation: desksBookable,
                    deskCapacity: desksCapacity,
                    meetingRoomPopulation: meetingRoomBookable,
                    meetingRoomCapacity: meetingRoomCapacity,
                    carparkPopulation: carParksBookable,
                    carparkCapacity: carParksCapacity,
                    displayToggle: true,
                    urlPath: `/building-management/buildings/${buildingId}/floors/${floor.floorId}`,
                },
            };

            return data;
        });
        return (
            <>
                <ActionContainer>
                    <ManageFloor />
                </ActionContainer>
                <OccupancyList
                    header="Floors"
                    icon={<BusinessIcon style={{ fontSize: 28, color: "#333333" }} />}
                    listData={listData}
                    onChangeBookable={onChangeBooking}
                />
            </>
        );
    };

    return (
        <Dashboard>
            {toggleFloorBooking && displayModal(modalType, numBookable, selectedFloorData)}
            {hasLoaded ? (
                <>
                    <Management
                        key={updateList}
                        breadcrumbs={breadcrumbs}
                        summaries={generateSummaryCards()}
                        list={generateOccupancyList()}
                        selectListItems={generateDropDown()}
                        onClickSelect={selectedItem}
                        disableDropdown={true}
                    />
                </>
            ) : (
                <Container>
                    <Throbber />
                </Container>
            )}
        </Dashboard>
    );
});

export default Floors;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;

const SummariesContainer = styled.div<{ showSummaries?: boolean }>`
    display: flex;
    margin-top: ${(props): string => (props.showSummaries ? "32px" : "0px")};
    height: ${(props): string => (props.showSummaries ? "214px" : "0px")};
`;

const SummaryContainer = styled.div`
    width: 100%;
    max-width: 50%;
    padding-right: 8px;
    &:last-child {
        padding-right: 0;
    }
`;

const ModalContent = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 28px;
`;

const ModalText = styled.div`
    width: 348px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: normal;
    padding-bottom: 12px;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
