import React, { FC, useState } from "react";
import styled from "styled-components";
import SVGIconDown from "../../Icon/Down";
import SVGIconUp from "../../Icon/Up";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    value: number;
}

const CounterButton: FC<Props> = (props: Props) => {
    const { value } = props;
    const [count, setCount] = useState(value);
    const decrementCount = (): void => {
        count > 0 && setCount(count - 1);
    };
    const incrementCount = (): void => {
        setCount(count + 1);
    };
    const changeCount = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCount(parseInt(e.target.value));
    };
    return (
        <CounterContainer>
            <ButtonDiv aria-label="decrement" onClick={decrementCount}>
                <SVGIconDown />
            </ButtonDiv>
            <CounterText name="count" type="number" value={count} onChange={changeCount}></CounterText>
            <ButtonDiv aria-label="increment" onClick={incrementCount}>
                <SVGIconUp />
            </ButtonDiv>
        </CounterContainer>
    );
};

export default CounterButton;
/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const CounterContainer = styled.div`
    display: flex;
    width: 144px;
    height: 36px;
    text-align: center;
    margin: 15px: 0px;
    background-color: rgb(249, 249, 249);
`;

const CounterText = styled.input`
    height: 32px;
    width: 73px;
    background-color: rgb(249, 249, 249);
    border-top: thin solid;
    border-bottom: thin solid;
    border-left: none;
    border-right: none;
    text-align: center;
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const ButtonDiv = styled.button`
    height: 36px;
    width: 36px;
    border: thin solid;
    background-color: rgb(249, 249, 249);
`;
