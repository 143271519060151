import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { Dashboard } from "../../../layouts";
import { BreadCrumbNavigationBar, InsightTile, BuildingLevelList } from "../../../components/index";
import { FloorData } from "../../../components/List/BuildingLevelList";
import { SmartSpaces } from "../../../services/WebService/SmartSpaces";
import SVGIconBuilding from "../../../components/Icon/Building";
import SVGIconDesk from "../../../components/Icon/Desk";
import SVGIconVideoConference from "../../../components/Icon/VideoConference";
import { observer } from "mobx-react";
import { useStore, UIStore } from "../../../services";
import { UIView, OccupancyMode } from "../../../services/Store/UIStore";
import { useParams } from "react-router";

// Local types
type Building = SmartSpaces.WebApp.Space;
type Floors = { [id: number]: SmartSpaces.WebApp.Plan };
type FloorInsights = { [id: number]: Array<SmartSpaces.WebApp.SpaceInsight> };
type SpaceInsightPlans = Array<SmartSpaces.WebApp.SpaceInsightPlan>;
type SpaceInsightDesks = Array<SmartSpaces.WebApp.SpaceInsightDesk>;
type SpaceInsightMeetingRooms = Array<SmartSpaces.WebApp.SpaceInsightMeetingRoom>;

interface SpaceInsight {
    spaceInsightPlan: boolean;
    spaceInsightDesk: boolean;
    spaceInsightMeetingRoom: boolean;
}

const BuildingsEfficiency: FC = observer(() => {
    const store = useStore();

    const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);

    const building = Object.values(store.data.spaces.buildings)
        .filter((building: Building) => {
            return building.id === buildingId ? building : null;
        })
        .reduce((building: Building) => {
            return building;
        });

    const selectedBuilding = { id: buildingId, label: building.name };
    const breadcrumbs = UIStore.generateBreadcrumbs(UIView.BuildingEfficiency, selectedBuilding);

    const occupancyMode = store.ui.occupancyMode;

    useEffect(() => {
        store.ui.setCurrentView(UIView.BuildingEfficiency);
        store.ui.setSelectedBuilding(selectedBuilding);
    }, [store.ui, selectedBuilding]);

    const filteredFloors: Floors = Object.values(store.data.spaces.floors).reduce(
        // Should come up with better solution to avoid disable eslint line
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, e: any) => {
            if (e.spaceId === buildingId) {
                a[e.id] = e;
            }
            return a;
        },
        {},
    );

    const filteredFloorInsight = store.data.spaces.floorInsights;
    const recentOccupancyDuration = store.data.spaces.customer.recentOccupancyDuration;

    const calculateFloorsData = (Floors: Floors, floorInsights: FloorInsights): Array<FloorData> => {
        const floorsData = Object.values(Floors)
            .sort((a, b) => b.floorNumber - a.floorNumber)
            .map((floor) => {
                const planInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "PLAN",
                ) as SpaceInsightPlans;
                const deskInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "DESK",
                ) as SpaceInsightDesks;
                const roomInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "MEETING_ROOM",
                ) as SpaceInsightMeetingRooms;
                const occupancy = {
                    floorOccupancyCount: planInsights.reduce((a, e) => a + (e.countIn - e.countOut), 0),
                    floorOccupancyCapacity: floor.capacity,
                    deskOccupancyCount: deskInsights.reduce((a, e) => a + e.occupied, 0),
                    deskRecentOccupancyCount:
                        deskInsights.reduce((a, e) => a + e.recentlyOccupied, 0) -
                        deskInsights.reduce((a, e) => a + e.occupied, 0),
                    deskOccupancyCapacity: deskInsights.reduce((a, e) => a + e.available, 0),
                    roomOccupancyCount: roomInsights.reduce((a, e) => a + e.occupied, 0),
                    roomOccupancyCapacity: roomInsights.reduce((a, e) => a + e.available, 0),
                };

                return {
                    id: floor.id,
                    name: floor.name,
                    occupancy,
                    shouldShowFloorOccupancyInsights: planInsights.length > 0,
                };
            });

        return floorsData;
    };

    const calculateAvailableInsights = (Floors: Floors, floorInsights: FloorInsights): SpaceInsight => {
        // To ensure that we take into account all levels first set to false and if any floors have an insight set to true.
        let availablePlanInsight = false;
        let availableDeskInsight = false;
        let availableRoomInsight = false;

        Object.values(Floors)
            .sort((a, b) => b.floorNumber - a.floorNumber)
            .forEach((floor) => {
                const planInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "PLAN",
                ) as SpaceInsightPlans;
                availablePlanInsight = planInsights.length ? true : availablePlanInsight;
                const deskInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "DESK",
                ) as SpaceInsightDesks;
                availableDeskInsight = deskInsights.length ? true : availableDeskInsight;
                const roomInsights = (floorInsights[floor.id] || []).filter(
                    (e) => e.type === "MEETING_ROOM",
                ) as SpaceInsightMeetingRooms;
                availableRoomInsight = roomInsights.length ? true : availableRoomInsight;
            });

        // Track what insights we have access to allow us to show and hide tabs.
        return {
            spaceInsightPlan: availablePlanInsight,
            spaceInsightDesk: availableDeskInsight,
            spaceInsightMeetingRoom: availableRoomInsight,
        };
    };

    const floorsData = calculateFloorsData(filteredFloors, filteredFloorInsight);
    const availableInsights = calculateAvailableInsights(filteredFloors, filteredFloorInsight);

    const buildingSummaryInsightOccupancy = parseInt(
        (
            floorsData
                // Aggregate
                .reduce(
                    (a, e) =>
                        a.concat((e.occupancy.floorOccupancyCount || 0) / (e.occupancy.floorOccupancyCapacity || 1)),
                    [] as Array<number>,
                )
                // Average
                .reduce((a, e, i) => (a * i + e) / (i + 1), 0) *
            // Convert to percentage
            100
        ).toFixed(0),
        10,
    );

    const buildingSummaryInsightDesks = parseInt(
        (
            floorsData
                // Aggregate
                .reduce(
                    (a, e) =>
                        a.concat(
                            ((e.occupancy.deskOccupancyCount || 0) + (e.occupancy.deskRecentOccupancyCount || 0)) /
                                (e.occupancy.deskOccupancyCapacity || 1),
                        ),
                    [] as Array<number>,
                )
                // Average
                .reduce((a, e, i) => (a * i + e) / (i + 1), 0) *
            // Convert to percentage
            100
        ).toFixed(0),
        10,
    );

    const buildingSummaryInsightRooms = parseInt(
        (
            floorsData
                // Aggregate
                .reduce(
                    (a, e) =>
                        a.concat((e.occupancy.roomOccupancyCount || 0) / (e.occupancy.roomOccupancyCapacity || 1)),
                    [] as Array<number>,
                )
                // Average
                .reduce((a, e, i) => (a * i + e) / (i + 1), 0) *
            // Convert to percentage
            100
        ).toFixed(0),
        10,
    );

    const handleSetOccupancyMode = (occupancyMode: OccupancyMode): void => {
        store.ui.setOccupancyMode(occupancyMode);
    };

    return (
        <Dashboard>
            <Container>
                <HeaderContainer>
                    <HeaderColumn>
                        <BreadCrumbNavigationBar textColor="#001E82" breadcrumbs={breadcrumbs} />
                    </HeaderColumn>
                    <HeaderColumn>
                        <BuildingSummaryInsightsTilesContainer>
                            {floorsData.some((e) => e.shouldShowFloorOccupancyInsights) && (
                                <InsightTile
                                    title={"Building "}
                                    percentValue={buildingSummaryInsightOccupancy}
                                    icon={<SVGIconBuilding fill={"#0064D2"} />}
                                    occupancyMode={occupancyMode}
                                />
                            )}
                            {floorsData.some((e) => e.occupancy.deskOccupancyCapacity) && (
                                <InsightTile
                                    title={"Desks "}
                                    percentValue={buildingSummaryInsightDesks}
                                    icon={<SVGIconDesk fill={"#0064D2"} />}
                                    occupancyMode={occupancyMode}
                                />
                            )}
                            {floorsData.some((e) => e.occupancy.roomOccupancyCapacity) && (
                                <InsightTile
                                    title={"Rooms "}
                                    percentValue={buildingSummaryInsightRooms}
                                    icon={<SVGIconVideoConference fill={"#0064D2"} />}
                                    occupancyMode={occupancyMode}
                                />
                            )}
                        </BuildingSummaryInsightsTilesContainer>
                    </HeaderColumn>
                </HeaderContainer>
                <BuildingLevelList
                    floorsData={floorsData}
                    buildingId={buildingId}
                    availableInsights={availableInsights}
                    shouldShowDeskRecentOccupancyInsights={recentOccupancyDuration !== 0}
                    occupancyMode={occupancyMode}
                    setOccupancyMode={handleSetOccupancyMode}
                    wrldId={building.wrldId}
                />
            </Container>
        </Dashboard>
    );
});

export default BuildingsEfficiency;

const Container = styled.div`
    width: 100%;
    min-width: 897px;
    ${media.mobile} {
        min-width: auto;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 24px;
    align-items: center;
    ${media.mobile} {
        margin-bottom: 20px;
    }
`;

const HeaderColumn = styled.div`
    flex-grow: 1;
    ${media.mobile} {
        overflow: hidden;
    }
`;

const BuildingSummaryInsightsTilesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    float: right;
    width: 100%;
    > div {
        margin-left: 40px;
        transition: margin-left 2s;
        transition-timing-function: ease;
    }
    ${media.mobile} {
        display: none;
    }
    ${media.between("mobile", "tablet")} {
        > div {
            margin-left: 5px;
        }
    }
`;
