import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconMinus extends React.Component<Props> {
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 14 14">
                <rect transform="translate(-8, -8)" x="8" y="14" width="14" height="2" rx="1" />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg``;
