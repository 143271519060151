import React, { FC, ReactNode } from "react";
import styled, { StyledComponent } from "styled-components";

/**
 * Enum to declare what side the button is on.
 * @enum {number}
 */
type Side = "Left" | "Middle" | "Right";
/**
 * Props
 */
export interface Props {
    elements: Array<string>;
    onCallback?: (selected: string, tabIndex: string) => void;
    ToggleStyle: "Blue" | "White";
    selected?: string;
}

const Toggle: FC<Props> = (props: Props) => {
    const [selected, setSelected] = React.useState(props.selected ?? "0");
    /**
     * Toggles the Selected
     */
    // eslint-disable-next-line
    const onClick = (data: any): void => {
        setSelected(data.target.id);
        // If there is a callback then send data.
        props.onCallback?.(props.elements[data.target.id], data.target.id);
    };
    /**
     * Checks if the button is selected or not.
     */
    const checkIfSelected = (id: string): boolean => {
        if (id === selected) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Calculates where in the switch the button is, allows edge curving.
     */
    const calculateSide = (elementIndex: number): Side => {
        if (elementIndex === 0) {
            return "Left";
        }
        if (elementIndex === props.elements.length - 1) {
            return "Right";
        }
        return "Middle";
    };

    /**
     * Generates the buttons in the ToggleSwitch
     */
    const processElement = (name: string, index: number): ReactNode => {
        if (props.ToggleStyle && props.ToggleStyle === "White") {
            return (
                <ButtonWhite
                    key={index}
                    id={index.toString()}
                    selected={checkIfSelected(index.toString())}
                    onClick={onClick}
                >
                    {name}
                </ButtonWhite>
            );
        } else {
            return (
                <ButtonBlue
                    key={index}
                    id={index.toString()}
                    selected={checkIfSelected(index.toString())}
                    onClick={onClick}
                    side={calculateSide(index)}
                >
                    {name}
                </ButtonBlue>
            );
        }
    };
    // eslint-disable-next-line
    let ToggleSwitchWrapper: StyledComponent<"div", any, {}, never>;

    if (props.ToggleStyle && props.ToggleStyle === "White") {
        ToggleSwitchWrapper = ToggleSwitchWrapperWhite;
    } else {
        ToggleSwitchWrapper = ToggleSwitchWrapperBlue;
    }
    return <ToggleSwitchWrapper>{props.elements.map(processElement)}</ToggleSwitchWrapper>;
};

export default Toggle;

const ToggleSwitchWrapperWhite = styled.div`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
`;

const ToggleSwitchWrapperBlue = styled.div`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;

    box-shadow: inset 0px 0px 0px 1px #9e9e9e;
    box-sizing: border-box;
    border-radius: 3px;
`;

const ButtonWhite = styled.button<{ selected: boolean }>`
    height: 32px;
    min-width: 91.9px;
    border-radius: 16px;

    margin: 0px;
    border: 0px;

    background-color: ${(props): string => (props.selected ? "#FFFFFF" : "transparent")};
    z-index: 1;

    text-align: center;
    vertical-align: middle;

    font-size: 18px;
    font-weight: ${(props): string => (props.selected ? "bold" : "#normal")};
    line-height: 21px;

    color: ${(props): string => (props.selected ? "#0000000" : "#9E9E9E")};
    user-select: none;

    &:focus {
        /*Prevent the default on click effects.*/
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
`;

const ButtonBlue = styled.button<{ selected: boolean; side: Side }>`
    min-width: 75px;
    height: 23px;
    line-height: 23px;

    margin: 0px;
    padding: 0px;
    border: 0px;
    border-radius: ${(props): string =>
        props.side === "Left" ? "3px 0px 0px 3px" : props.side === "Right" ? "0px 3px 3px 0px" : ""};
    border: 1px solid ${(props): string => (props.selected ? "#0064D2" : "transparent")};
    background-color: ${(props): string => (props.selected ? "#0064D2" : "transparent")};
    z-index: 1;

    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    color: ${(props): string => (props.selected ? "#FFFFFF" : "#9E9E9E")};
    user-select: none;

    &:focus {
        /*Prevent the default on click effects.*/
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
`;
