import * as React from "react";
import styled from "styled-components";

export const TelstraLogo: React.FC = () => {
    return (
        <LogoImage>
            <LogoEllipse src={"/images/app-logo-disc.svg"} alt="" />
            <LogoT src={"/images/app-logo-letter.svg"} alt="" />
        </LogoImage>
    );
};

export default TelstraLogo;

const LogoImage = styled.div`
    height: 24px;
    width: 21px;
    margin-right: 15px;
    position: relative;
    align-items: center;
`;

const LogoEllipse = styled.img`
    position: relative;
`;

const LogoT = styled.img`
    position: absolute;
    bottom: 12px;
    right: 0px;
`;
