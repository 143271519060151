import Management from "./Data/Management";
import Spaces from "./Data/Spaces";

class DataStore {
    public management: Management;
    public spaces: Spaces;

    constructor(data = {} as DataStore) {
        // Data store class member objects
        this.management = new Management(data.management);
        this.spaces = new Spaces(data.spaces);
    }

    public toJSON(): {} {
        return {
            management: this.management.toJSON(),
            spaces: this.spaces.toJSON(),
        };
    }
}

export default DataStore;
