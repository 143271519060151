import React from "react";
import styled from "styled-components";

export interface IconStyling {
    width: string;
    height: string;
    textSize: string;
    mobileWidth: string;
    mobileHeight: string;
    mobileTextSize: string;
}
export enum WarningTypes {
    SUCCESS,
    WARNING,
    DEFAULT,
}

export interface UserModel {
    userId: number;
    name: string;
    email: string;
}

export interface Props {
    user?: UserModel;
    styling?: IconStyling;
    status?: WarningTypes;
}

const BookingIcon: React.FC<Props> = (props) => {
    const iconStyling = props?.styling;
    const defaultIconStyle: IconStyling = {
        width: "26px",
        height: "26px",
        textSize: "12px",
        mobileWidth: "55px",
        mobileHeight: "53px",
        mobileTextSize: "20px",
    };
    const { status } = props ?? { status: undefined };

    let displayName = "";
    const regexPattern = /[,-.`~!@#$%^&*()[\]{}]/g;
    //if name is not empty
    const name = props?.user?.name;
    if (name !== undefined && name !== "") {
        //splitting the first and last name
        const [first, last] = name.trim().split(" ");
        //removing special characters from the first name
        const firstName = first.replace(/[^a-zA-Z ]/g, "");

        //if the user does not have a last name
        if (last === undefined) {
            displayName = firstName.charAt(0).toUpperCase();
        } else {
            //if the user has both a first name and last name
            const lastName = last.replace(/[^a-zA-Z ]/g, "");
            displayName = `${firstName.charAt(0).replace(regexPattern, "")}${lastName
                .charAt(0)
                .replace(regexPattern, "")}`.toUpperCase();
        }
    }
    return (
        <TextWrapper aria-hidden="true" styling={iconStyling ? iconStyling : defaultIconStyle} status={status}>
            {displayName}
        </TextWrapper>
    );
};

const TextWrapper = styled.span<{
    styling?: IconStyling;
    status?: WarningTypes;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    color: #001e82;
    text-align: center;
    line-height: normal;
    background-color: #e2f4ff;

    width: 26px;
    height: 26px;
    font-size: 12px;
`;

export default BookingIcon;
