import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";

/**
 * RealTimeInsightTile props
 */
export interface Props {
    title: string;
    progressBarTitle: string;
    progressBarColour: string;
    fractionLabelColour: string;
    currentValueTitle: string;
    currentValue: number;
    maxValueTitle: string;
    maxValue: number;
    onClick?: () => void;
}

/**
 * RealTimeInsightTile state
 */
export interface State {
    currentValue: number;
    lastCurrentValue: number;
    isLoading: boolean;
}

export default class RealTimeInsightTile extends React.Component<Props, State> {
    private updateIntervalId: number | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentValue: 0,
            lastCurrentValue: 0,
            isLoading: true,
        };
    }

    public componentDidMount = (): void => {
        // 84% of 3s (see `fadeInOut` keyframes).
        // (0.84 * 3000ms) + (0.1 * 3000ms) = 2520 + 300 = 2820ms
        this.updateIntervalId = window.setInterval(this.updateChange, 2820);
    };

    public componentWillUnmount = (): void => {
        if (this.updateIntervalId) {
            window.clearInterval(this.updateIntervalId);
        }
    };

    private updateChange = (): void => {
        this.setState({
            currentValue: this.props.currentValue > -1 ? this.props.currentValue : 0,
            lastCurrentValue: this.state.currentValue > -1 ? this.state.currentValue : 0,
            isLoading: false,
        });
    };

    /**
     * Render
     */
    public render(): ReactNode {
        const {
            title,
            progressBarTitle,
            progressBarColour,
            fractionLabelColour,
            currentValueTitle,
            maxValueTitle,
            maxValue,
            onClick,
        } = this.props;
        const { currentValue, lastCurrentValue, isLoading } = this.state;

        if (isLoading) {
            return (
                <Background role="region" isInteractable={false}>
                    <LoadingIndicatorContainer>
                        <LoadingIndicator />
                    </LoadingIndicatorContainer>
                </Background>
            );
        }

        const changeValue = currentValue - lastCurrentValue;

        const isPositiveChange = changeValue > 0;
        return (
            <Background
                id="clickable"
                role="region"
                onClick={this.props.onClick}
                isInteractable={onClick !== undefined}
            >
                <Title>{title}</Title>
                <Divider />
                <ProgressBarTopLabelContainer>
                    <ProgressBarTopLabelTitle>{progressBarTitle}</ProgressBarTopLabelTitle>
                    <ProgressBarTopLabelChangeContainer>
                        {changeValue !== 0 && (
                            <ProgressBarTopLabelChangeIndicator
                                key={changeValue}
                                isPositiveChange={isPositiveChange}
                            >{`${isPositiveChange ? "+" : ""}${changeValue}`}</ProgressBarTopLabelChangeIndicator>
                        )}
                        <ProgressBarTopLabelCurrentValueFraction
                            fractionLabelColour={fractionLabelColour}
                        >{`${currentValue}/${maxValue}`}</ProgressBarTopLabelCurrentValueFraction>
                    </ProgressBarTopLabelChangeContainer>
                </ProgressBarTopLabelContainer>
                <ProgressBarContainer>
                    <ProgressBarBackground />
                    <ProgressBarFill
                        progressPercentage={Math.min((currentValue / maxValue) * 100, 100)}
                        progressBarColour={progressBarColour}
                    />
                </ProgressBarContainer>
                <ProgressBarBottomLabelCurrentValuePercentage>
                    {((currentValue / maxValue) * 100).toFixed(0)}%
                </ProgressBarBottomLabelCurrentValuePercentage>
                <InsightsOverviewContainer role="insight">
                    <InsightContainer>
                        <InsightLabel>{currentValueTitle}</InsightLabel>
                        <InsightValue>{currentValue}</InsightValue>
                    </InsightContainer>
                    <InsightsOverviewDivider />
                    <InsightContainer>
                        <InsightLabel>{maxValueTitle}</InsightLabel>
                        <InsightValue>{maxValue}</InsightValue>
                    </InsightContainer>
                </InsightsOverviewContainer>
            </Background>
        );
    }
}

const Background = styled.div<{ isInteractable: boolean }>`
    height: 187px;
    width: 329px;
    border: 1px solid #ececf1;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px 0 rgba(0, 153, 248, 0.15);

    position: relative;
    cursor: ${(props): string => (props.isInteractable ? "pointer" : "default")};

    /** Disable text selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
`;

const LoadingIndicatorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoadingIndicator = styled.div`
    width: 50px;
    height: 50px;

    border: 8px solid #e9eef4; /* Light grey */
    border-top: 8px solid #0099f8; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Title = styled.div`
    height: 21px;
    color: #3e3e3e;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;

    margin-top: 12px;
    margin-left: 12px;
`;

const Divider = styled.div`
    box-sizing: border-box;
    height: 1px;
    width: 329px;
    border: 0.5px solid #ececf1;

    margin-top: 10.16px;
`;

const ProgressBarTopLabelContainer = styled.div`
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 5.84px;
    margin-left: 12px;
    margin-right: 12px;
`;

const ProgressBarTopLabelTitle = styled.div`
    height: 14px;
    color: #000000;
    font-size: 11px;
    line-height: 14px;
`;

const ProgressBarTopLabelChangeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

// Create the fade in and out keyframes
const fadeInOut = keyframes`
    0% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const ProgressBarTopLabelChangeIndicator = styled.div<{ isPositiveChange: boolean }>`
    height: 14px;
    color: ${(props): string => (props.isPositiveChange ? "#14B851" : "#CF4E68")};
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    opacity: 0;
    -webkit-animation: ${fadeInOut} 3s;
    animation: ${fadeInOut} 3s;
`;

const ProgressBarTopLabelCurrentValueFraction = styled.div<{ fractionLabelColour: string }>`
    height: 14px;
    color: ${(props): string => props.fractionLabelColour};
    font-size: 11px;
    line-height: 14px;
    text-align: right;

    margin-left: 6px;
`;

const ProgressBarContainer = styled.div`
    height: 6px;

    margin-top: 6px;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
`;

const ProgressBarBackground = styled.div`
    height: 6px;
    border-radius: 5px;
    background-color: #e9eef4;
`;

const ProgressBarFill = styled.div<{ progressPercentage: number; progressBarColour: string }>`
    height: 6px;
    width: ${(props): string => `calc(${props.progressPercentage}%)`};
    border-radius: 5px;
    background-color: ${(props): string => props.progressBarColour};

    position: absolute;
    top: 0px;
`;

const ProgressBarBottomLabelCurrentValuePercentage = styled.div`
    height: 14px;
    color: #3e3e3e;
    font-size: 11px;
    line-height: 14px;
    text-align: right;

    margin-top: 6px;
    position: absolute;
    right: 12px;
`;

const InsightsOverviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 20px;
    margin-bottom: 12px;
    height: 79px;
`;

const InsightsOverviewDivider = styled.div`
    box-sizing: border-box;
    height: 79px;
    width: 1px;
    border: 0.5px solid #ececf1;
`;

const InsightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    flex-grow: 1;
    width: 0px; /* Note: Use 0px to ensure flex-grow makes the widths of all instances of InsightContainer the same. */
`;

const InsightLabel = styled.div`
    height: 14px;
    color: #9e9e9e;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
`;

const InsightValue = styled.div`
    height: 36px;
    color: #3e3e3e;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;

    margin-top: 2px;
`;
