import React, { FC, useState } from "react";
import TextInput from "../../../../components/Input/TextInput/TextInput";
import styled from "styled-components";

type InputState = "default" | "none" | "capacity" | "empty" | "invalid" | "invalidCapacity";

export interface Props {
    value: string;
    onNameChange: (inputValue: string) => void;
    assetTypeLabel: string;
    defaultHelperMessage?: InputState;
    label?: string;
    maxChar?: number;
    width?: number;
    isRequired?: boolean;
}

const AssetName: FC<Props> = (props: Props) => {
    const {
        value,
        onNameChange,
        assetTypeLabel,
        defaultHelperMessage,
        label,
        maxChar,
        width,
        isRequired = true,
    } = props;
    const allowedLength = maxChar ?? 35;
    const inputWidth = width ?? undefined;
    const generateAssetName = (assetType: string, capitalise?: boolean) => {
        if (label) return label;
        const assetName =
            assetType === "Desk"
                ? "Desk name"
                : assetType === "Room" && defaultHelperMessage !== "capacity"
                ? "Meeting room name"
                : "";
        if (!capitalise) {
            return assetName.toLowerCase();
        }
        return assetName;
    };

    const helperTexts = {
        none: "", // blank message
        default: `Select to  ${label ? `add ${label}` : "edit name"}`,
        empty: `You need to enter a ${generateAssetName(assetTypeLabel, false)} to save`,
        invalid: `${generateAssetName(assetTypeLabel, true)} can not be more than ${allowedLength} characters`,
        capacity: "Select to edit capacity",
        invalidCapacity: "You need to enter a numeric value",
    };

    const [inputValue, setInputValue] = useState(value ?? "");
    const [messageState, setMessageState] = useState<InputState>(
        defaultHelperMessage ? defaultHelperMessage : "default",
    );

    const isError = (): boolean => ["empty", "invalid", "invalidCapacity"].includes(messageState);

    const handleOnChange = (value: string): void => {
        if (value === "" && defaultHelperMessage !== "capacity") {
            setMessageState("empty");
        } else if (value.length > allowedLength) {
            setMessageState("invalid");
        } else if (defaultHelperMessage === "capacity" && isNaN(Number(value))) {
            setMessageState("invalidCapacity");
        } else {
            setMessageState("none");
        }
        setInputValue(value);
        onNameChange(value);
    };

    const handleOnFocusChange = (focused: boolean): void => {
        if (focused) {
            setMessageState("none");
        } else if (inputValue.length > allowedLength) {
            setMessageState("invalid");
        } else if (defaultHelperMessage === "capacity" && isNaN(Number(value))) {
            setMessageState("invalidCapacity");
        } else if (defaultHelperMessage === "capacity") {
            setMessageState("capacity");
        } else {
            setMessageState("default");
        }
    };

    return (
        <Container>
            <TextInput
                required={isRequired}
                label={assetTypeLabel === "Room" ? "" : `${generateAssetName(assetTypeLabel, true)}`}
                value={value}
                type="text"
                helperText={helperTexts[messageState]}
                width={defaultHelperMessage === "capacity" ? 150 : inputWidth}
                isError={isError()}
                onChange={handleOnChange}
                onFocusChange={handleOnFocusChange}
            />
        </Container>
    );
};

export default AssetName;

const Container = styled.div``;
