import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import SVGIconBuilding from "../../../components/Icon/Building";
import BuildingList from "../../../components/List/BuildingList";
import BrandedHeader from "../../../components/Common/BrandedHeader/BrandedHeader";
import { Dashboard } from "../../../layouts";
import { observer } from "mobx-react";
import { useStore } from "../../../services";
import { UIView } from "../../../services/Store/UIStore";

const BuildingsOverview: FC = observer(() => {
    const store = useStore();
    const customer = store.data.spaces.customer;
    const buildings = Object.values(store.data.spaces.buildings);

    useEffect(() => {
        store.ui.setCurrentView(UIView.Portfolio);
    }, [store.ui]);

    return (
        <Dashboard>
            <Container>
                <BrandedHeader
                    headerText={customer && customer.name}
                    headerLogo={customer.logo}
                    gradientColorLeft="#0064D2"
                    gradientColorRight="#5CD6E0"
                />
                <ListTitle>Buildings</ListTitle>
                <BuildingList icon={<SVGIconBuilding />} data={buildings} />
            </Container>
        </Dashboard>
    );
});

export default BuildingsOverview;

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-width: 980px;

    display: flex;
    flex-direction: column;
    background-color: rgb(241, 245, 249);
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

    /* Disable image selection */
    img {
        pointer-events: none;
    }
    ${media.mobile} {
        min-width: auto;
    }
`;

const ListTitle = styled.h3`
    float: left;
    color: #000000;
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    margin: 0px;
    margin-right: 15px;
    margin-left: 24px;
    margin-bottom: 18px;
`;
