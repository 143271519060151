import { toJS, makeAutoObservable } from "mobx";
import { SmartSpaces } from "../../WebService/SmartSpaces";

type Customer = SmartSpaces.WebApp.Customer;
type Buildings = { [id: number]: SmartSpaces.WebApp.Space };
type Floors = { [id: number]: SmartSpaces.WebApp.Plan };
type FloorInsights = { [id: number]: Array<SmartSpaces.WebApp.SpaceInsight> };
type AreaGroups = Array<SmartSpaces.WebApp.Group>;
type Telemetry = Array<SmartSpaces.WebApp.Telemetry>;

class Spaces {
    customer: Customer;
    buildings: Buildings;
    floors: Floors;
    floorInsights: FloorInsights;
    areaGroups: AreaGroups;
    telemetry: Telemetry;

    constructor(data = {} as Spaces) {
        const {
            customer = {} as Customer,
            buildings = {} as Buildings,
            floors = {} as Floors,
            floorInsights = {} as FloorInsights,
            areaGroups = {} as AreaGroups,
            telemetry = {} as Telemetry,
        } = data ?? {};

        this.customer = customer;
        this.buildings = buildings;
        this.floors = floors;
        this.floorInsights = floorInsights;
        this.areaGroups = areaGroups;
        this.telemetry = telemetry;

        makeAutoObservable(this);
    }

    public setCustomerData(customer: Customer): void {
        this.customer = customer;
    }

    public setBuildingsData(buildings: Buildings): void {
        this.buildings = buildings;
    }

    public setFloorsData(floors: Floors): void {
        this.floors = floors;
    }

    public setFloorInsightsData(floorInsights: FloorInsights): void {
        this.floorInsights = floorInsights;
    }

    public setAreaGroupsData(areaGroups: AreaGroups): void {
        this.areaGroups = areaGroups;
    }

    public setTelemetryData(telemetry: Telemetry): void {
        this.telemetry = telemetry;
    }

    public toJSON(): {} {
        return {
            customer: toJS(this.customer),
            buildings: toJS(this.buildings),
            floors: toJS(this.floors),
            floorInsights: toJS(this.floorInsights),
            areaGroups: toJS(this.areaGroups),
            telemetry: toJS(this.telemetry),
        };
    }
}

export default Spaces;
