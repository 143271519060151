import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
    level: number;
}

class MainMenu extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    private breadcrumbs = [
        { to: "/spaces/buildings", label: "Building List" },
        { to: "/spaces/buildings/1", label: "Building 1 Floors" },
        { to: "/spaces/buildings/1/floors/1", label: "Building 1 Floor 1" },
    ];

    public render(): ReactNode {
        return (
            <MenuBarContainer>
                <MenuBar>
                    {this.breadcrumbs.map((item, index) => (
                        <MenuBarItem key={index} selected={this.props.level === index} to={item.to}>
                            {item.label}
                        </MenuBarItem>
                    ))}
                </MenuBar>
            </MenuBarContainer>
        );
    }
}

const MenuBarContainer = styled.div`
    -webkit-box-flex: 1;
    display: flex;
    flex-grow: 1;
    padding: 1rem;
    user-select: none;
`;

const MenuBar = styled.nav`
    margin: 0;
    padding: 0;
    display: flex;
`;

const MenuBarItem = styled(Link)<{ selected: boolean }>`
    font-weight: bold;
    color: #0099f8;
    text-decoration: none;
    padding: 0.25rem 1rem;
    border-bottom: 2px solid #${(props): string => (props.selected ? "0099f8" : "fff")};

    .current {
        border-bottom: 2px solid;
    }
`;

export default MainMenu;
