import React, { ReactNode, FC } from "react";
import styled from "styled-components";
import { media } from "./../../config/Config";
import { OccupancyMode } from "./../../services/Store/UIStore";

/**
 * Definition of Props.
 */
export interface Props {
    title: string;
    percentValue: number;
    icon: ReactNode;
    occupancyMode: OccupancyMode;
}

const InsightTile: FC<Props> = (props: Props) => {
    const occupancyMode = props.occupancyMode ? props.occupancyMode : "";
    const percentValue = occupancyMode === "Occupied" ? props.percentValue : 100 - props.percentValue;

    return (
        <InsightTileContainer role="figure">
            <InsightTileIconContainer>
                <InsightTileIcon>{props.icon}</InsightTileIcon>
            </InsightTileIconContainer>
            <InsightTileTextContainer>
                <InsightTileInsightValue>{Math.min(percentValue > 0 ? percentValue : 0, 100)}%</InsightTileInsightValue>
                <InsightTileInsightLabel>{props.title + occupancyMode}</InsightTileInsightLabel>
            </InsightTileTextContainer>
        </InsightTileContainer>
    );
};

export default InsightTile;

const InsightTileContainer = styled.div`
    height: 80px;
    width: 170px;
    position: relative;
    box-shadow: 0px 20px 15px -9px rgba(1, 0, 50, 0.05);

    background-color: #ffffff;
    border: 1px solid #ececf1;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    transition: width 1s;
    transition-timing-function: ease;
    ${media.between("mobile", "tablet")} {
        width: 150px;
    }
`;

const InsightTileIconContainer = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 17.45px;
    background-color: #e2f4ff;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14.5px;
`;

const InsightTileIcon = styled.div`
    height: 18px;
    width: 15px;
    user-select: none;
`;

const InsightTileTextContainer = styled.div`
    margin-left: 14px;
    font-weight: bold;
`;

const InsightTileInsightValue = styled.div`
    height: 34px;
    width: 57px;

    color: #000000;
    font-size: 28px;
    line-height: 34px;
`;

const InsightTileInsightLabel = styled.div`
    height: 14px;
    width: 108.4px;
    color: #9e9e9e;
    font-size: 11px;
    line-height: 14px;
`;
