import React, { ReactNode } from "react";
import styled from "styled-components";
import { media } from "../../config/Config";
import ProgressBar from "./ProgressBar/ProgressBar";

/**
 * FloorListItemInsightProps
 */
export interface ListItemInsightProps {
    svgComponent?: JSX.Element;
    title?: string;
    subtitle?: string;
    colour?: string;
    reverseProgress?: boolean;
    progress?: number; // Range is [0, 1]
}

export default class ListItemInsight extends React.Component<ListItemInsightProps> {
    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <ListItemInsightContainer>
                <InsightOverview>
                    <IconWrapper>{this.props.svgComponent ?? <Bubble colour={this.props.colour} />}</IconWrapper>
                    <InsightTitles>
                        <InsightTitle> {this.props.title}</InsightTitle>
                        <InsightSubtitle> {this.props.subtitle} </InsightSubtitle>
                    </InsightTitles>
                </InsightOverview>
                {this.props.progress !== undefined && (
                    <ProgressBarWrapper>
                        <ProgressBar
                            colour={this.props.colour}
                            reverseProgress={this.props.reverseProgress}
                            progress={
                                this.props.progress
                                    ? this.props.reverseProgress
                                        ? 1 - this.props.progress
                                        : this.props.progress
                                    : undefined
                            }
                        />
                    </ProgressBarWrapper>
                )}
            </ListItemInsightContainer>
        );
    }
}

const ListItemInsightContainer = styled.div`
    height: 100%;
    color: #9e9e9e;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 18px;
    min-width: 170px;
    max-width: 250px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    ${media.mobile} {
        padding: 0;
        min-width: unset;
        max-width: unset;
        align-items: flex-end;
    }
    ${media.between("mobile", "tablet")} {
        padding: 0 5px;
        min-width: 160px;
    }
`;

const IconWrapper = styled.div`
    margin-right: 6px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.mobile} {
        width: 14px;
        height: 18px;
    }
`;

const InsightTitle = styled.div`
    color: #000000;
    font-weight: bold;
    ${media.mobile} {
        font-size: 13px;
    }
`;

const InsightOverview = styled.div`
    display: flex;
    align-items: center;
`;

const InsightSubtitle = styled.div`
    font-weight: normal;
    font-size: 14px;
    ${media.mobile} {
        font-size: 12px;
    }
`;

const InsightTitles = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
`;

const ProgressBarWrapper = styled.div`
    padding-top: 13px;
    width: 100%;
    ${media.mobile} {
        width: auto;
    }
`;

const Bubble = styled.div<{ colour?: string }>`
    width: 18px;
    height: 18px;
    background-color: ${(props): string => (props.colour ? props.colour : "#0099F8")};
    border-radius: 25px;
    justify-self: center;
    align-self: center;
    margin-right: 6px;
`;
