import { SmartSpaces } from "./SmartSpaces";
import { createRequest, errorCallback, maxContinuousWaitTime, continuousWaitTimeIncrease } from "./ServiceHandlers";

/**
 * Get customer data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getPlansWithRetry = async (
    waitTime: number,
    authToken: string,
    getPlanArguments: { spaceId?: number; planId?: number; includePlanImage?: boolean },
): Promise<{ [id: string]: SmartSpaces.WebApp.Plan }> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getPlans(authToken).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getPlansWithRetry(wait, authToken, getPlanArguments));
                },
            );
        }, waitTime * 1000),
    );
};

/** Gets the Floor Items for the specified building
 *
 * @param token the authorisation token.
 * @param spaceId? The id of the Space, filters to a plans of a specific space or leave undefiend to get an array of of all the customers plans.
 * @param planId? Specify a specific plan to get, filters to a specific plan leave undefined to get an array of all the spaces plans.
 * @param includePlanImage If the API request should also retrieve the images for each plan - recommended to set to true only when retrieving required/selected floorplan.
 */
const getPlans = async (
    token: string,
    params?: {
        spaceId?: number;
        planId?: number;
        includePlanImage?: boolean;
    },
): Promise<{ [id: string]: SmartSpaces.WebApp.Plan }> => {
    const { body } = await createRequest({
        apiPath: `/plans`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        ...(params && (params.spaceId || params.planId || params.includePlanImage)
            ? {
                  body: {
                      ...(params.spaceId
                          ? {
                                spaceId: params.spaceId,
                            }
                          : {}),
                      ...(params.planId
                          ? {
                                planId: params.planId,
                            }
                          : {}),
                      ...(params.includePlanImage
                          ? {
                                includePlanImage: params.includePlanImage,
                            }
                          : {}),
                  },
              }
            : {}),
    });

    const plans: Array<SmartSpaces.WebApp.Plan> = typeof body === "object" ? body : JSON.parse(body);
    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const plansHashTable = plans.reduce((a: any, e: any) => {
        a[e.id] = e;
        return a;
    }, {});
    return plansHashTable;
};
