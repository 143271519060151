import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";

/**
 * Enum to declare what side the button is on.
 * @enum {number}
 */
/**
 * Props
 */
export interface Props {
    elements: Array<string>;
    onCallback?: (selected: string, tabIndex: string) => void;
    selected?: string;
}

const MapListToggle: FC<Props> = (props: Props) => {
    const [selected, setSelected] = React.useState(props.selected ?? "0");
    /**
     * Toggles the Selected
     */
    // eslint-disable-next-line
    const onClick = (index: any): void => {
        setSelected(index.toString());
        // If there is a callback then send data.
        props.onCallback?.(props.elements[index], index);
    };
    /**
     * Checks if the button is selected or not.
     */
    const checkIfSelected = (id: string): boolean => {
        if (id === selected) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Generates the buttons in the ToggleSwitch
     */
    const processElement = (name: string, index: number): ReactNode => {
        return (
            <Button
                key={index}
                id={index.toString()}
                selected={checkIfSelected(index.toString())}
                onClick={(): void => onClick(index)}
            >
                {name === "Map" ? (
                    <MapIcon style={{ fontSize: 18 }}></MapIcon>
                ) : (
                    <ListIcon style={{ fontSize: 18 }}></ListIcon>
                )}
                {name}
            </Button>
        );
    };
    return <ToggleSwitchWrapper>{props.elements.map(processElement)}</ToggleSwitchWrapper>;
};

export default MapListToggle;

const ToggleSwitchWrapper = styled.div`
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-radius: 3px;
`;

const Button = styled.button<{ selected: boolean }>`
    min-width: 94px;
    height: 34px;
    font-weight: 200;
    line-height: 14px;
    margin: 0px;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
    color: "#333333";
    padding-right: 19px;
    border: 0px;
    border-radius: 2px;
    border: 1px solid ${(props): string => (props.selected ? "#B3E1FD" : "#FFFFFF")};
    background-color: ${(props): string => (props.selected ? "#B3E1FD" : "#FFFFFF")};
    z-index: 1;
    vertical-align: middle;

    user-select: none;

    &:focus {
        /*Prevent the default on click effects.*/
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
`;
