import { useEffect, useRef } from "react";

// Custom setInterval Hook
export const useInterval = (callback: () => void, delay: number | null): void => {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = (): void => {
            if (savedCallback.current) {
                savedCallback.current();
            }
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return (): void => clearInterval(id);
        }
        return undefined;
    }, [delay]);
};
