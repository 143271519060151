import * as React from "react";
import styled from "styled-components";
import { useOuterClickNotifier } from "./OuterClickNotifierHook";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { StylesProvider } from "@material-ui/core/styles";
import ToggleAssetModal from "../../Windows/ToggleAssetModal/ToggleAssetModal";
import CarparkUser, { UserModel } from "../../../views/Management/Assets/CarparkUser/CarparkUser";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import { useStore } from "../../../services";
import Throbber from "../../Common/Throbber/Throbber";
import { ModalText, ThrobberWrapper } from "../../../views/Management/Assets/DisplayAssetModal/DisplayAssetModal";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircle from "@material-ui/icons/CheckCircle";
/**
 * The available tabs that can be used for navigation
 */
export enum SelectedTab {
    Spaces,
    Reports,
}

/**
 * NavigationBar props
 */
export interface Props {
    firstName: string;
    lastName: string;
    primaryColor: string;
    onPerformLogout?: () => void;
    onClick?: () => void;
}

export const ProfileTab: React.FC<Props> = (props: Props) => {
    const [isShowingProfileDropDown, setIsShowingProfileDropDown] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [selectedList, setSelectedList] = React.useState<Array<Admin.CarParkPermission>>([]);
    const [failedUsersList, setFailedUsersList] = React.useState<Array<string>>([]);
    const [CSVData, setCSVData] = React.useState<File | null>();
    const [uploadStatus, setUploadStatus] = React.useState<string>();
    const [saveStatus, setSaveStatus] = React.useState<string>();

    const store = useStore();
    const innerRef = React.useRef<HTMLDivElement | null>(null);
    const onClickProfile = (): void => {
        setIsShowingProfileDropDown(!isShowingProfileDropDown);
    };

    const handleOuterClick = React.useCallback(
        // memoized callback for optimized performance
        (e: MouseEvent) => {
            if (isShowingProfileDropDown) {
                setIsShowingProfileDropDown(false);
            }
        },
        [isShowingProfileDropDown], // adjust deps to your needs
    );
    useOuterClickNotifier(handleOuterClick, innerRef);

    const getInitials = (): string => {
        const firstNameLetter = props.firstName?.length > 0 ? props.firstName.charAt(0) : "";
        const lastNameLetter = props.lastName?.length > 0 ? props.lastName.charAt(0) : "";
        return firstNameLetter + lastNameLetter;
    };

    const handlePermissions = (): void => {
        setShowModal(true);
    };

    const handleModal = (): void => {
        setShowModal(false);
        setIsLoading(false);
        setIsError(false);
        setSaveStatus("");
        setFailedUsersList([]);
    };

    const handleSelection = (data: Array<UserModel>): void => {
        const userData: Array<Admin.CarParkPermission> = [];
        data.forEach((d) => {
            const resultSet = {
                userId: d.userId,
                allowedCarPark: d.allowedCarPark ?? false,
            };
            userData.push(resultSet);
        });
        !!userData.length && setIsBtnDisabled(false);
        setSelectedList(userData);
    };

    const handleUpload = (data: File | null): void => {
        setCSVData(data);
        if (data) {
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
        }
    };

    const handleSave = (): void => {
        if (!selectedList.length && !CSVData) {
            return;
        }
        setIsLoading(true);
        if (CSVData) {
            store.subscription.management
                .uploadCarparkUser(CSVData)
                .then((res) => {
                    setIsLoading(false);
                    if (res.failedUsers.length) {
                        setUploadStatus(res.successMessage);

                        setFailedUsersList(res.failedUsers);
                    } else {
                        setSaveStatus("Car Park permissions assigned successfully");
                    }
                })
                .catch((e) => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsBtnDisabled(true);
                });
        } else {
            store.subscription.management
                .saveCarparkPermission(selectedList)
                .then((res) => {
                    setIsLoading(false);
                    setSaveStatus("Car Park permissions assigned successfully");
                })
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsBtnDisabled(true);
                });
        }
    };

    const title = isError ? "Sorry, an error has occurred!" : isLoading ? "Submitting details.." : "Car Park Access";
    const getUsers = (
        <UsersContainer>
            <IconWrapper>
                {" "}
                <ErrorIcon fontSize={"medium"} htmlColor="#a60014" />
                <Label>{uploadStatus}</Label>
            </IconWrapper>
            {failedUsersList.map((user) => (
                <p key={user}>{user}</p>
            ))}
        </UsersContainer>
    );

    const modal = (
        <ToggleAssetModal
            title={title}
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={"608px"}
            width="712px"
            contentWidth="632px"
            contentHeight={"446px"}
            onClickPrimary={handleSave}
            onClickSecondary={handleModal}
            onEscapeClicked={handleModal}
            isButtonDisabled={isBtnDisabled}
        >
            {isError ? (
                <ModalText>Please try again later.</ModalText>
            ) : isLoading ? (
                <ThrobberWrapper>
                    <Throbber />
                </ThrobberWrapper>
            ) : failedUsersList.length ? (
                getUsers
            ) : saveStatus ? (
                <StatusWrapper>
                    <CheckCircle htmlColor="#4B7900" fontSize="small" />
                    <StatusText>{saveStatus}</StatusText>
                </StatusWrapper>
            ) : (
                <CarparkUser handleSave={handleSelection} onUpload={handleUpload} />
            )}
        </ToggleAssetModal>
    );

    return (
        <React.Fragment>
            {showModal && modal}
            <StylesProvider injectFirst>
                <Wrapper onClick={onClickProfile} ref={innerRef}>
                    <ProfileInfoContainer onClick={props.onClick}>
                        <ProfileImage>{getInitials()}</ProfileImage>
                        <ProfileInfo>
                            <ProfileName primaryColor={props.primaryColor}>
                                {props.firstName + " " + props.lastName}
                            </ProfileName>
                        </ProfileInfo>
                        <ProfileDropDown $isShowingProfileDropDown={isShowingProfileDropDown} />

                        <LogOutMenuItemCell
                            $isShowingProfileDropDown={isShowingProfileDropDown}
                            onClick={props.onPerformLogout}
                        >
                            <LogOutMenuItemCellTitle primaryColor={props.primaryColor}>Log Out</LogOutMenuItemCellTitle>
                        </LogOutMenuItemCell>
                        <ManagePermissionCell
                            $isShowingProfileDropDown={isShowingProfileDropDown}
                            onClick={handlePermissions}
                        >
                            <LogOutMenuItemCellTitle primaryColor={props.primaryColor}>
                                Car Park Access
                            </LogOutMenuItemCellTitle>
                        </ManagePermissionCell>
                    </ProfileInfoContainer>
                </Wrapper>
            </StylesProvider>
        </React.Fragment>
    );
};

export default ProfileTab;

const Wrapper = styled.div`
    position: relative;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    :hover {
        cursor: pointer;
    }
`;

const ProfileInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProfileName = styled.div<{ primaryColor: string }>`
    color: ${(props): string => props.primaryColor};
    text-align: left;
    line-height: 36px;
    letter-spacing: 0.22px;
    margin-left: 10px;
    margin-right: 5px;
`;

const ProfileImage = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #c0e4e3;
    color: #025450;

    text-align: center;
    vertical-align: middle;
    line-height: 36px;
    letter-spacing: 1px;
`;

const ProfileInfo = styled.div`
    float: right;
`;

const ProfileDropDown = styled(KeyboardArrowDownIcon)<{ $isShowingProfileDropDown: boolean }>`
    height: 36px;
    transform: scaleY(${(props): string => (props.$isShowingProfileDropDown ? "-1" : "1")});
`;

const LogOutMenuItemCell = styled.div<{ $isShowingProfileDropDown: boolean }>`
    transition: opacity 0.2s;
    position: absolute;
    box-sizing: border-box;
    height: 48px;
    width: 90%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    bottom: -45px;
    left: 6px;
    display: flex;
    align-items: center;
    position: absolute;
    pointer-events: ${(props): string => (props.$isShowingProfileDropDown ? "default" : "none")};
    opacity: ${(props): string => (props.$isShowingProfileDropDown ? "1" : "0")};

    :hover {
        cursor: pointer;
    }
`;

const ManagePermissionCell = styled.div<{ $isShowingProfileDropDown: boolean }>`
    transition: opacity 0.2s;
    position: absolute;
    box-sizing: border-box;
    height: 48px;
    width: 90%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    bottom: -90px;
    left: 6px;
    display: flex;
    align-items: center;
    position: absolute;
    pointer-events: ${(props): string => (props.$isShowingProfileDropDown ? "default" : "none")};
    opacity: ${(props): string => (props.$isShowingProfileDropDown ? "1" : "0")};

    :hover {
        cursor: pointer;
    }
`;

const LogOutMenuItemCellTitle = styled.div<{ primaryColor: string }>`
    color: ${(props): string => props.primaryColor};
    font-size: 14px;
    text-align: center;
    margin-left: 33px;
`;

const UsersContainer = styled.div`
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    font-size: 14px;
    padding: 20px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.span`
    font-weight: bold;
    padding: 5px 5px 0;
`;

export const StatusWrapper = styled.div`
    display: flex;
`;

const StatusText = styled.div`
    width: 348px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: normal;
    padding-bottom: 12px;
    margin: 3px 5px;
`;
