// import BookingIcon, { WarningTypes } from '../../BookingIcon/BookingIcon';
import React, { Fragment, MouseEvent } from "react";
import styled, { css } from "styled-components";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import { SearchPrompt } from './SearchUserContent';
import { UserModel, SearchPrompt } from "./CarparkUser";
import BookingIcon from "../../../../components/BookingIcon/BookingIcon";

export interface Props {
    search: string;
    highlight: boolean;
    isUserAlreadySelected: boolean;
    initials?: string;
    userId?: UserModel["userId"];
    name?: UserModel["name"];
    email?: UserModel["email"];
    children?: React.ReactNode;
    groupName?: string;
    onClick?: (event: MouseEvent) => void;
}

/**
 * Common regular expressions for user search text input
 *
 * @member removable Cleanup pattern from impurities
 * @member repeating Repeating characters to replace
 * @member separator Name separators
 */
export const Filters = {
    removable: /[`~!#$%^&*()_=+[{\]}\\|;:"<>/?]/g,
    repeating: /(\s|,|-|'|\.|@)+/g,
    separator: /\s|,/,
    replaceWithSpace: /[,]/g,
};

export const SearchOutcomes: React.FC<Props> = (props: Props) => {
    const { isUserAlreadySelected } = props;
    // Highlight text
    const searchBolding = (needle: string, haystack: string, shouldHighlight: boolean) => {
        let highlightedText: React.ReactNode = <>{haystack}</>;
        // @reference https://stackoverflow.com/a/48950887
        if (needle && needle.length > 0 && shouldHighlight) {
            // Breakdown keywords in to an array
            const keywords = needle
                .replace(Filters.removable, "")
                .replace(Filters.repeating, "$1")
                .replace(Filters.replaceWithSpace, " ")
                .split(Filters.separator)
                .sort()
                .reverse()
                .filter((f) => f !== "" && f.length > 1);

            // Join them and create a RegEx. E.g.: /^(foo|bar|baz)/g
            const pattern = new RegExp(`\\b(${keywords.join("|")})`, "gi");

            // Match keywords in search string
            const rest = haystack.split(pattern).filter(Boolean);
            const match = haystack.match(pattern) || [];

            highlightedText = rest.map((subString: string, index: number) =>
                match.includes(subString) ? (
                    <Fragment key={index}>
                        <mark>{subString}</mark>
                    </Fragment>
                ) : (
                    <Fragment key={index}>{subString}</Fragment>
                ),
            );
        }

        return <NameTextField>{highlightedText}</NameTextField>;
    };
    const nameVal = props.name ?? "";
    return (
        <WrapperButton
            isUserAlreadySelected={isUserAlreadySelected}
            role="listitem"
            data-group-name={props.groupName}
            onClick={props.onClick}
        >
            <Test>
                <Wrapper>
                    {props.initials && (
                        <IconWrapper>
                            <BookingIcon
                                user={{
                                    userId: props.userId ?? 0,
                                    name: props.name ?? "",
                                    email: props.email ?? "",
                                }}
                            />
                        </IconWrapper>
                    )}
                    <TextContainer disabled={isUserAlreadySelected} aria-label={`${props.name} (${props.email})`}>
                        {props.name && searchBolding(props.search, props.name, props.highlight)}
                        {props.email && <EmailTextField>{props.email}</EmailTextField>}
                    </TextContainer>
                    {!Object.values<string>(SearchPrompt)?.includes(nameVal) && !isUserAlreadySelected && (
                        <IconContainer>
                            <AddIcon fontSize="medium" />
                        </IconContainer>
                    )}
                </Wrapper>

                {isUserAlreadySelected && (
                    <WarningContainer>
                        <UserAlreadySelectedWarning>Already part of this selection</UserAlreadySelectedWarning>
                    </WarningContainer>
                )}
            </Test>
        </WrapperButton>
    );
};

const WrapperButton = styled.button<{ isUserAlreadySelected: boolean }>`
    cursor: ${(props) => (props.isUserAlreadySelected ? "unset" : "pointer")};
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e5e5e5;
    width: 100%;
    min-height: 50px;
    padding: 6px 0;
`;

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 50px;
`;

const WarningContainer = styled.span`
    display: flex;

    flex: 1;
    justify-content: end;
    align-items: center;
`;

const TextContainer = styled.span<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: ${({ disabled }) => (disabled ? "#676666" : "#484848")};
    text-align: left;
    letter-spacing: 0.32px;
    flex-basis: 95%;
    min-width: 0;
`;

const BoldText = css`
    font-size: 14px;
    font-weight: bold;
    color: #0064d2;
    background-color: transparent;
`;

const NameTextField = styled.span`
    padding-left: 20px;
    font-size: 14px;
    margin: 0px 0px -2px 0px;
    padding-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    mark {
        ${BoldText};
    }
`;

const EmailTextField = styled.span`
    padding: 5px 0px 0px 20px;
    font-size: 12px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const WarningTextStyle = css`
    font-size: 12px;
    margin: 0px;
    font-weight: 700;
    letter-spacing: 0.32px;
`;

const UserAlreadySelectedWarning = styled.span`
    ${WarningTextStyle}
    color:#b00020;
    margin-right: 20px;
`;

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
`;

const IconContainer = styled.div`
    display: flex;
    padding-right: 23px;
`;

export const AddIcon = styled(AddCircleOutlineIcon)`
    color: #484848;
    height: 18px;
    width: 18px;
`;

const Test = styled.div`
    display: flex;
    flex-direction: row;
`;
