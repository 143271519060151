import React, { FC } from "react";
import styled from "styled-components";
import { media } from "../../config/Config";
import { SmartSpaces } from "../../services/WebService/SmartSpaces";
import { Link } from "react-router-dom";
/**
 * Props
 */
export interface Props {
    icon: JSX.Element;
    subtitleIcon?: JSX.Element;
    listItem: SmartSpaces.WebApp.Space;
}

const BuildingListItem: FC<Props> = (props: Props) => {
    const { listItem, subtitleIcon, icon } = props;
    const title = listItem.name;
    const subtitle = `${listItem.address.streetNumber} ${listItem.address.streetName}, ${listItem.address.city}`;
    return (
        <StyledLink to={`/spaces/buildings/${listItem.id}`}>
            <FloorListItemContainer>
                <TitleContainer>
                    <IconContainer>{icon}</IconContainer>
                    <TitleWrapper showingSubtitle={!!subtitle}>
                        <Title>{title}</Title>
                        {subtitle && (
                            <SubtitleContainer>
                                {subtitleIcon && <SubtitleIconContainer>{subtitleIcon}</SubtitleIconContainer>}
                                <Subtitle>{subtitle}</Subtitle>
                            </SubtitleContainer>
                        )}
                        <HoverPrompt>Click to see details</HoverPrompt>
                    </TitleWrapper>
                </TitleContainer>
            </FloorListItemContainer>
        </StyledLink>
    );
};

export default BuildingListItem;

const TitleContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 349px;
    min-width: max-content;
    display: flex;
    align-items: center;
    margin-left: 48px;
    float: left;
    flex-grow: 1;
    ${media.mobile} {
        margin: 0;
        padding: 0 18px;
        min-width: auto;
    }
`;

const Title = styled.div`
    font-weight: bold;
`;

const SubtitleContainer = styled.div`
    font-weight: normal;
    font-size: 12px;
    height: 15px;
    color: #3e3e3e;

    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SubtitleIconContainer = styled.div`
    height: 12px;
    width: 12px;
    // background-color: #007AF8;
`;

const Subtitle = styled.div`
    font-weight: normal;
    font-size: 12px;
    height: 15px;
    color: #3e3e3e;

    margin-left: 3px;
`;

const HoverPrompt = styled.div`
    height: 15px;
    width: 103px;
    color: #007af8;
    font-size: 12px;
    min-height: 15px;
    display: none;
`;

const TitleWrapper = styled.div<{ showingSubtitle: boolean }>`
    height: ${(props): string => (props.showingSubtitle ? "43px" : "18px")};
`;

const FloorListItemContainer = styled.div`
    background: #ffffff;
    color: #000000;
    width: 100%;
    height: 110px;
    border: 1px solid #eceef1;
    border-radius: 3px;
    display: flex;
    flex-flow: row nowrap;
    min-width: min-content;
    user-select: none;
    margin-bottom: 12px;
    &:hover {
        cursor: pointer;
        ${Title} {
            color: #0099f8;
        }
        ${HoverPrompt} {
            display: inline;
        }
        ${SubtitleContainer} {
            opacity: 0;
            margin-top: 4px;
            height: 0px;
        }
    }
`;
const IconContainer = styled.div`
    user-select: none;
    width: 25px;
    height: 24px;
    margin-right: 12px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;
