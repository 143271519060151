import { Modal } from "@material-ui/core";
import React, { ReactNode } from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PopupButton from "../../Button/PopupButton/PopupButton";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    title: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    primaryButtonBackgroundColour: string;
    primaryButtonTextColour: string;
    secondaryButtonTextColour: string;
    closeOnClickPrimary: boolean;
    closeOnClickSecondary: boolean;
    escapeClicked: boolean;
    renderCheckbox: boolean;
    checkboxText: string;
    height?: string;
    width?: string;
    contentWidth?: string;
    contentHeight?: string;
    primaryButtonwidth?: string;
    children: ReactNode;
    isButtonDisabled?: boolean;
    isErrorModal?: boolean;
    onClickPrimary: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClickSecondary: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onEscapeClicked: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChangeCheckbox?: (checked: boolean) => void;
    onClose?: () => void;
}

export interface State {
    isOpen: boolean;
}

export enum ModalTypes {
    Hidden,
    Error,
    Loading,
    Bookable,
    NonBookable,
    NonBookableWithBookings,
    ToggleStatusLoading,
    EditAsset,
    EditAssetLoading,
    FloorplanUpload,
    RemoveFloorplan,
    FloorplanSizeExceeded,
    FloorplanTypeMismatch,
    AddCarparkSpot,
    CloneAssetLoading,
}

export default class ToggleAssetModal extends React.Component<Props, State> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    private checked = true;

    static defaultProps = {
        title: "Default Title",
        primaryButtonText: "Confirm",
        secondaryButtonText: "Cancel",
        primaryButtonBackgroundColour: "#0064d2",
        primaryButtonTextColour: "#FFFFFF",
        secondaryButtonTextColour: "#001e82",
        closeOnClickPrimary: false,
        closeOnClickSecondary: false,
        escapeClicked: false,
        isButtonDisabled: false,
    };

    private handleClose = (): void => {
        this.setState({ isOpen: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    private handleClickButtonSecondary = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        this.props.onClickSecondary(e);
        if (this.props.closeOnClickSecondary) {
            this.handleClose();
        }
    };

    private handleClickButtonPrimary = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        !this.props.isButtonDisabled && this.props.onClickPrimary(e);
        if (this.props.closeOnClickPrimary) {
            this.handleClose();
        }
    };

    private onChangeCheckbox = (): void => {
        this.checked = !this.checked;
        if (this.props.onChangeCheckbox) {
            this.props.onChangeCheckbox(this.checked);
        }
    };

    //this function is used to close the modal on the escape key clicked.
    private escapeKeyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Escape") {
            this.props.onEscapeClicked(event);

            if (this.props.escapeClicked) {
                this.handleClose();
            }
        }
    };

    public render(): ReactNode {
        const {
            height,
            width,
            contentWidth,
            contentHeight,
            renderCheckbox,
            title,
            children,
            checkboxText,
            secondaryButtonText,
            secondaryButtonTextColour,
            primaryButtonText,
            primaryButtonBackgroundColour,
            primaryButtonTextColour,
            isButtonDisabled,
            primaryButtonwidth,
            isErrorModal,
        } = this.props;
        return (
            <Modal
                open={this.state.isOpen}
                hideBackdrop={true}
                onKeyDown={this.escapeKeyHandler}
                onClose={this.handleClose}
                aria-labelledby={title + " modal"}
                aria-describedby="modal-description"
            >
                <Wrapper>
                    <Background
                        onClick={(e): void => {
                            e.stopPropagation();
                        }}
                    />
                    <WindowWrapper height={height} width={width} exportCsv={renderCheckbox}>
                        <ColourStrip isErrorStrip={isErrorModal} />
                        <WindowHeader>
                            {isErrorModal && (
                                <ErrorOutlineIcon
                                    fontSize="large"
                                    htmlColor="#d0021b"
                                    style={{ margin: "0 6px 0 -3px" }}
                                />
                            )}
                            <Title>{title}</Title>
                        </WindowHeader>
                        <Content width={contentWidth} height={contentHeight}>
                            {children}
                        </Content>
                        {renderCheckbox && (
                            <CheckboxContainer>
                                <Checkbox type="checkbox" onChange={this.onChangeCheckbox} defaultChecked={true} />
                                <CheckboxText>{checkboxText}</CheckboxText>
                            </CheckboxContainer>
                        )}
                        <WindowFooter>
                            {secondaryButtonText !== "" ? (
                                <SecondaryButton
                                    onClick={this.handleClickButtonSecondary}
                                    secondaryButtonTextColour={secondaryButtonTextColour}
                                    tabIndex={0}
                                >
                                    {secondaryButtonText}
                                </SecondaryButton>
                            ) : null}
                            {primaryButtonText !== "" ? (
                                <PrimaryButtonContainer>
                                    <PopupButton
                                        onClick={this.handleClickButtonPrimary}
                                        label={primaryButtonText}
                                        primaryColour={primaryButtonBackgroundColour}
                                        textColour={primaryButtonTextColour}
                                        isDisabled={isButtonDisabled}
                                        buttonWidth={primaryButtonwidth}
                                    />
                                </PrimaryButtonContainer>
                            ) : null}
                        </WindowFooter>
                    </WindowWrapper>
                </Wrapper>
            </Modal>
        );
    }
}

/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const Wrapper = styled.div`
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    :focus {
        outline: none;
    }
`;

const WindowWrapper = styled.div<{ exportCsv?: boolean; height?: string; width?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    right: 0;
    width: ${(props): string => props.width || "400px"};
    height: ${(props): string => (props.height ? props.height : props.exportCsv ? "420px" : "290px")};

    overflow: hidden;
    margin: auto;
    background-color: #ffffff;
    transition: all 100ms ease-in;

    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
`;

const WindowHeader = styled.div`
    padding: 32px 37px 0px 37px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: 100%;
    box-sizing: border-box;
`;

const Title = styled.h1`
    margin: 0px;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    text-align: left;
    float: left;
`;

const PrimaryButtonContainer = styled.div`
    align-self: flex-end;
`;

const WindowFooter = styled.div`
    position: absolute;
    padding-bottom: 40px;
    bottom: 0;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;

    > * {
        margin: 0 28px 0 28px;
    }
`;

const Content = styled.div<{ width?: string; height?: string }>`
    margin: 23px 37px 41px 38px;
    width: ${(props): string => props.width || "325px"};
    height: ${(props): string => props.height || "auto"};
    font-size: 14px;
    overflow-y: auto;
    overflow-x: inherit;
`;

const SecondaryButton = styled.button<{ secondaryButtonTextColour: string }>`
    background: none;
    outline: none;
    border: none;

    color: ${(props): string => props.secondaryButtonTextColour};
    text-align: left;
    line-height: 24px;
    font-size: 16px;
    text-decoration: underline;
    margin-right: 110px;

    :focus {
        border: none;
        outline: none;
    }
    :focus-within {
        border: 2px solid #0064d2;
        border-radius: 3px;
        margin-left: 26px;
        margin-right: 108px;
    }

    :hover {
        cursor: pointer;
    }
`;

const Background = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
`;

const CheckboxContainer = styled.div`
    display: inline-flex;
    padding-left: 30px;
    position: absolute;
    bottom: 123px;
`;

const CheckboxText = styled.div`
    align-self: center;
    padding-left: 4px;
    font-size: 14px;
    color: #000000;
`;

const Checkbox = styled.input`
    width: 24px;
    height: 24px;
    margin: 5px;
    appearance: none;
    border: 1px solid #797979;
    border-radius: 2px;
    box-shadow: none;
    font-size: 2em;

    :hover {
        cursor: pointer;
    }
    :checked {
        background-color: #38822b;
    }
    :checked:before {
        content: "";
        height: 2px;
        background-color: #ffffff;
        position: relative;
        display: block;
        top: 60%;
        left: 75%;
        width: 8px;
        margin-left: -15px;
        transform: rotate(45deg);
    }
    :checked:after {
        content: "";
        height: 2px;
        background-color: #ffffff;
        position: relative;
        display: block;
        top: 60%;
        left: 75%;
        width: 15px;
        margin-left: -11px;
        margin-top: -6px;
        transform: rotate(-45deg);
    }
`;

const ColourStrip = styled.div<{ isErrorStrip: boolean | undefined }>`
    height: 4px;
    background: ${(props): string =>
        props.isErrorStrip ? "#d0021b" : "linear-gradient(90deg, #0064d2 0%, #5cd6e0 32%, #5e50bf 100%)"};
`;
