import React, { useState, useRef, useEffect, ReactNode } from "react";
import styled, { css } from "styled-components";
import FloorplanNavigationBar from "../../../../components/Navigation/FloorplanNavigationBar/FloorplanNavigationBar";
import { observer } from "mobx-react";
import { useStore } from "../../../../services";
import PublishIcon from "@material-ui/icons/Publish";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Throbber } from "../../../../components";
import { toJS } from "mobx";
import ToggleAssetModal, { ModalTypes } from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";

export const FloorPlan: React.FC = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const selectedBuilding = toJS(store.data.management.adminBuilding);
    const selectedFloor = toJS(store.data.management.adminFloor);
    const buildingIdFromParams = parseInt((useParams() as { buildingId: string }).buildingId);
    const floorIdFromParams = parseInt((useParams() as { floorId: string }).floorId);
    const selectedBuildingId =
        selectedBuilding && selectedBuilding.buildingId ? selectedBuilding.buildingId : buildingIdFromParams;
    const selectedFloorId = selectedFloor && selectedFloor.floorId ? selectedFloor.floorId : floorIdFromParams;
    const [showLoader, setShowLoader] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [floorplanImage, setFloorplanImage] = useState<File | null>(null);
    const [isFloorplanAvailable, setIsFloorplanAvailable] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setShowLoader(true);
        getFloorplan();
    }, []);

    const getFloorplan = () => {
        store.subscription.management
            .getStaticFloorplan(selectedFloorId, `${selectedFloorId}-${selectedBuildingId}`)
            .then((response: File | null) => {
                if (response) {
                    const imageURL = window.URL.createObjectURL(response);
                    setImageSrc(imageURL);
                    setIsFloorplanAvailable(true);
                } else {
                    setIsFloorplanAvailable(false);
                }
            })
            .catch(() => {
                setShowModal(true);
                setModalType(ModalTypes.Error);
            })
            .finally(() => {
                setShowLoader(false);
            });
    };

    const backHandler = () => {
        navigate(`/building-management/buildings/${selectedBuildingId}/floors/${selectedFloorId}`);
    };

    const removeFloorplanHandler = () => {
        setShowModal(true);
        setModalType(ModalTypes.RemoveFloorplan);
        store.subscription.management.removeFloorplan(selectedFloorId).then(() => {
            setIsFloorplanAvailable(false);
            setShowModal(false);
        });
    };

    const uploadFloorplanHandler = () => {
        hiddenFileInput.current?.click();
    };

    const modalCloseHandler = () => {
        setShowModal(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const fileUploaded = target?.files ? target?.files[0] : null;
        if (fileUploaded) {
            if (fileUploaded.type !== "image/jpeg" && fileUploaded.type !== "image/png") {
                //check for jpg and png images only
                setShowModal(true);
                setModalType(ModalTypes.FloorplanTypeMismatch);
            } else if (fileUploaded.size > 256000) {
                // check for file size
                setShowModal(true);
                setModalType(ModalTypes.FloorplanSizeExceeded);
            } else {
                setFloorplanImage(fileUploaded);
                setShowModal(true);
                setModalType(ModalTypes.FloorplanUpload);
                setShowLoader(true);
                store.subscription.management
                    .uploadFloorplan(selectedFloorId, fileUploaded)
                    .then(() => {
                        setShowModal(false);
                        getFloorplan();
                    })
                    .catch(() => {
                        setModalType(ModalTypes.Error);
                    });
            }
            target.value = ""; // To retrigger the change event on same file selection
        }
    };

    const displayModal = (modalType: number): ReactNode => {
        const toggleProps = {
            primaryButtonBackgroundColour: "#0064d2",
            secondaryButtonTextColour: "#001e82",
            primaryButtonText: "Confirm",
            secondaryButtonText: "Cancel",
            closeOnClickSecondary: true,
            primaryButtonTextColour: "#ffffff",
            closeOnClickPrimary: false,
        };
        let modal = (
            <ToggleAssetModal
                title="Loading..."
                renderCheckbox={false}
                checkboxText=""
                primaryButtonText=""
                secondaryButtonText="Cancel"
                height="328px"
                width="500px"
                contentWidth="100%"
                onClickPrimary={modalCloseHandler}
                onClickSecondary={modalCloseHandler}
                onEscapeClicked={modalCloseHandler}
            >
                <ModalContent>
                    <Throbber />
                </ModalContent>
            </ToggleAssetModal>
        );

        switch (modalType) {
            case ModalTypes.FloorplanUpload: {
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Uploading..."
                        renderCheckbox={false}
                        checkboxText=""
                        primaryButtonText=""
                        secondaryButtonText="Cancel"
                        height="328px"
                        width="500px"
                        contentWidth="410px"
                        onClickPrimary={modalCloseHandler}
                        onClickSecondary={modalCloseHandler}
                        onEscapeClicked={modalCloseHandler}
                    >
                        <ModalContent>
                            {floorplanImage && (
                                <FileNameWrapper>
                                    <FileLogo src={"/images/fileIcon.svg"} alt="" />
                                    <FileName>{floorplanImage?.name}</FileName>
                                </FileNameWrapper>
                            )}
                            <FileLoader>
                                <Throbber />
                            </FileLoader>
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;
            }
            case ModalTypes.RemoveFloorplan: {
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Removing floorplan..."
                        renderCheckbox={false}
                        checkboxText=""
                        primaryButtonText=""
                        secondaryButtonText="Cancel"
                        height="328px"
                        width="500px"
                        contentWidth="410px"
                        onClickPrimary={modalCloseHandler}
                        onClickSecondary={modalCloseHandler}
                        onEscapeClicked={modalCloseHandler}
                    >
                        <ModalContent>
                            <FileLoader>
                                <Throbber />
                            </FileLoader>
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;
            }
            case ModalTypes.FloorplanSizeExceeded: {
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="File Size Limit Exceeded"
                        renderCheckbox={false}
                        isErrorModal={true}
                        checkboxText=""
                        primaryButtonText="Select another file"
                        secondaryButtonText="Cancel"
                        height="264px"
                        width="560px"
                        contentWidth="410px"
                        primaryButtonwidth="180px"
                        onClickPrimary={uploadFloorplanHandler}
                        onClickSecondary={modalCloseHandler}
                        onEscapeClicked={modalCloseHandler}
                    >
                        <ModalContent>
                            <span>The maximum file size allowed is 256KB</span>
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;
            }
            case ModalTypes.FloorplanTypeMismatch: {
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Invalid File Type"
                        renderCheckbox={false}
                        isErrorModal={true}
                        checkboxText=""
                        primaryButtonText="Select another file"
                        secondaryButtonText="Cancel"
                        height="264px"
                        width="560px"
                        contentWidth="410px"
                        primaryButtonwidth="180px"
                        onClickPrimary={uploadFloorplanHandler}
                        onClickSecondary={modalCloseHandler}
                        onEscapeClicked={modalCloseHandler}
                    >
                        <ModalContent>
                            <span>Acceptable formats include jpg or png</span>
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;
            }
            case ModalTypes.Error: {
                modal = (
                    <ToggleAssetModal
                        {...toggleProps}
                        title="Sorry, an error has occurred"
                        renderCheckbox={false}
                        checkboxText=""
                        primaryButtonText="Close"
                        secondaryButtonText=""
                        onClickPrimary={modalCloseHandler}
                        onClickSecondary={modalCloseHandler}
                        onEscapeClicked={modalCloseHandler}
                    >
                        <ModalContent>
                            <span>Please try again later.</span>
                        </ModalContent>
                    </ToggleAssetModal>
                );
                break;
            }
            default:
                break;
        }
        return <ModalWrapper>{modal}</ModalWrapper>;
    };

    return (
        <>
            <FloorplanNavigationBar
                buildingName={`${selectedBuilding?.buildingName}, ${selectedBuilding?.address?.city}, ${selectedBuilding?.address?.country}`}
                floorName={selectedFloor?.floorName}
                onGoBack={backHandler}
                onRemoveFloorPlan={removeFloorplanHandler}
                onUploadFloorPlan={uploadFloorplanHandler}
                isFloorplanAvailable={isFloorplanAvailable}
            />
            {showModal && displayModal(modalType)}
            {showLoader && (
                <LoaderContainer>
                    <Throbber />
                </LoaderContainer>
            )}
            {isFloorplanAvailable ? (
                <ImageContainer>
                    <Image src={imageSrc} alt={`${selectedFloorId}-${selectedBuildingId}-floor-plan`} />
                </ImageContainer>
            ) : (
                <NoFloorPlanSection>
                    <NoFloorPlanText>
                        <h3>
                            <strong>No static floorplan available</strong>
                        </h3>
                        <FileInfoText>
                            <p>File must be below 256KB.</p>
                            <p>Acceptable formats include jpg or png.</p>
                        </FileInfoText>

                        <div>
                            <UploadButton onClick={uploadFloorplanHandler}>
                                <StyledPublishIcon htmlColor="#001e82" />
                                Upload floorplan
                            </UploadButton>
                        </div>
                    </NoFloorPlanText>
                </NoFloorPlanSection>
            )}
            <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
            />
        </>
    );
});

export const BackgroundStyle = css`
    display: flex;
    height: 85vh;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
`;

const ImageContainer = styled.div`
    ${BackgroundStyle}
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 100px;
    box-sizing: border-box;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
`;

const NoFloorPlanSection = styled.div`
    ${BackgroundStyle}
`;

const NoFloorPlanText = styled.div`
    * {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const FileInfoText = styled.div`
    margin: 20px 0 30px 0;
    display: block;
    text-align: center;
    * {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
    }
`;

export const UploadButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: #001e82;
    text-decoration: underline;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
`;

export const StyledPublishIcon = styled(PublishIcon)`
    padding-right: 10px;
`;

const ModalContent = styled.div`
    width: 100%;
    height: auto;
`;

const FileLogo = styled.img`
    margin-left: -8px;
`;

const FileNameWrapper = styled.div`
    display: flex;
`;

const FileName = styled.span`
    display: inline-flex;
    align-items: center;
`;

const FileLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;
`;

const ModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoaderContainer = styled.div`
    ${BackgroundStyle}
`;
