import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    label: string;
    primaryColour: string;
    secondaryColour: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default class ActionButton extends React.Component<Props> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
    }

    static defaultProps = {
        label: "Button",
        primaryColour: "#FFFFFF",
        secondaryColour: "#0064D2",
        onClick: (): void => undefined,
    };

    public render(): ReactNode {
        return (
            <ButtonDiv
                primaryColour={this.props.primaryColour}
                secondaryColour={this.props.secondaryColour}
                onClick={this.props.onClick}
            >
                <ButtonText secondaryColour={this.props.secondaryColour}>{this.props.label}</ButtonText>
            </ButtonDiv>
        );
    }
}

/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const ButtonText = styled.span<{ secondaryColour: string }>`
    color: ${(props): string => props.secondaryColour};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.41px;
    text-align: center;
`;

const ButtonDiv = styled.button<{ primaryColour: string; secondaryColour: string }>`
    height: 50px;
    width: 254px;
    background-color: ${(props): string => props.primaryColour};
    box-sizing: border-box;
    border: 2px solid ${(props): string => props.secondaryColour};

    :hover,
    :focus {
        transition: color 0.3s, background-color 0.3s, border-color 0.3s;
        background: ${(props): string => props.secondaryColour};
        cursor: pointer;

        ${ButtonText} {
            color: ${(props): string => props.primaryColour};
        }
    }

    :focus-visible {
        outline-color: ${(props): string => props.secondaryColour};
    }
`;
