import React, { ReactNode, Fragment } from "react";
import styled from "styled-components";

/**
 * ProgressBarProps
 */
export interface PopulationBarProps {
    population: number;
    capacity: number;
    showCapacity: boolean;
    iconSize: number;
    showPopulation?: boolean;
    width?: number;
    scaling?: boolean;
    scalingAmount?: number;
    label?: string;
    labelSize?: number;
    svgIcon?: React.ReactNode;
    barWeight?: number;
    barColour?: string;
    barOffset?: number;
    iconMaxOffset?: number;
}

export default class PopulationBar extends React.Component<PopulationBarProps> {
    public render(): ReactNode {
        const barFill = (showCapacity: boolean, scaling?: boolean, progress?: number, offset?: number): number => {
            let barFill = 0;
            const progressScaling = showCapacity || scaling ? this.props.scalingAmount : 100;
            if (progress && this.props.capacity && progressScaling) {
                barFill = (progress * progressScaling) / this.props.capacity;
            }
            if (progress && offset && this.props.capacity && progressScaling) {
                const progressPercentage = (progress * progressScaling) / this.props.capacity;
                const offsetPercentage = (offset * progressScaling) / this.props.capacity;
                const total = progressPercentage + offsetPercentage;
                barFill = total > 100 ? 100 - offsetPercentage : progressPercentage;
            }
            const result = barFill > 100 ? 100 : barFill;
            return result;
        };
        const offset = (showCapacity: boolean, scaling?: boolean, offset?: number): number => {
            let barFill = 0;
            const progressScaling = showCapacity || scaling ? this.props.scalingAmount : 100;
            if (offset && this.props.capacity && progressScaling) {
                barFill = (offset * progressScaling) / this.props.capacity;
            }
            return barFill > 100 ? 100 : barFill;
        };

        /**
         * Render
         */
        return (
            <Container width={this.props.width}>
                <IconLabelContainer
                    barOffset={barFill(this.props.showCapacity, this.props.scaling, this.props.barOffset)}
                    iconMaxOfsset={this.props.iconMaxOffset}
                >
                    <IconContainer svgIcon={this.props.svgIcon} iconSize={this.props.iconSize}>
                        {this.props.svgIcon}
                    </IconContainer>
                    <PopulationContainer iconSize={this.props.iconSize} labelSize={this.props.labelSize}>
                        {this.props.showPopulation && <PopulationCount>{this.props.population}</PopulationCount>}
                        <LabelContainer>{this.props.label}</LabelContainer>
                    </PopulationContainer>
                </IconLabelContainer>
                <PopulationBarContainer>
                    <PopulationBarBackground barWeight={this.props.barWeight}>
                        <BarCapacityContainer>
                            <OverCapacity offset={this.props.scalingAmount} height={this.props.barWeight} />
                            {this.props.showCapacity && (
                                <Fragment>
                                    <CapacityContainer scalingAmount={this.props.scalingAmount}>
                                        <CapacityText>{this.props.capacity} Capacity</CapacityText>
                                    </CapacityContainer>
                                    <LineContainer scalingAmount={this.props.scalingAmount}>
                                        <CapacityLine />
                                    </LineContainer>
                                </Fragment>
                            )}
                            <PopulationBarFill
                                barColour={this.props.barColour}
                                barWeight={this.props.barWeight}
                                barOffset={offset(this.props.showCapacity, this.props.scaling, this.props.barOffset)}
                                progressPercentage={barFill(
                                    this.props.showCapacity,
                                    this.props.scaling,
                                    this.props.population,
                                    this.props.barOffset,
                                )}
                            />
                        </BarCapacityContainer>
                    </PopulationBarBackground>
                </PopulationBarContainer>
            </Container>
        );
    }
}

const OverCapacity = styled.div<{ offset?: number; height?: number }>`
    background-color: #f2f2f2;
    width: ${(props): string => (props.offset ? 100 - props.offset + "%" : "0%")};
    left: ${(props): string => (props.offset ? props.offset + "%" : "0%")};
    height: ${(props): string => (props.height ? props.height + "px" : "4px")};
    position: absolute;
    z-index: 1;
`;

const CapacityContainer = styled.div<{ scalingAmount?: number }>`
    display: flex;
    padding-right: 4px;
    height: 35px;
    position: absolute;
    bottom: 26px;
    left: ${(props): string => (props.scalingAmount ? props.scalingAmount - 5 + "%" : "100%")};
    font-size: 10px;
    width: auto;
`;

const CapacityText = styled.div`
    height: 35px;
    position: relative;
    font-size: 10px;
    top: 5px;
`;

const BarCapacityContainer = styled.div`
    display: flex;
    position: relative;
    height: 30px;
`;

const LineContainer = styled.div<{ scalingAmount?: number }>`
    display: flex;
    height: 35px;
    position: absolute;
    bottom: 26px;
    left: ${(props): string => (props.scalingAmount ? props.scalingAmount + "%" : "100%")};
    font-size: 12px;
    z-index: 1;
`;

const CapacityLine = styled.div`
    border-left: 3px dotted Grey;
    border-inline-width: 3px;
    height: 34px;
    position: absolute;
    top: 17px;
    left: 85%;
`;

const PopulationCount = styled.div`
    display: inline-block;
    padding-top: 7px;
    padding-right: 4px;
    font-weight: bold;
`;

const PopulationContainer = styled.div<{ iconSize?: number; labelSize?: number }>`
    left: ${(props): string => (props.iconSize ? props.iconSize + 5 + "px" : "")};
    position: absolute;
    bottom: 4px;
    font-size: ${(props): string => (props.labelSize ? props.labelSize + "px" : "16px")};
`;

const IconContainer = styled.div<{ svgIcon?: React.ReactNode; iconSize?: number }>`
    display: inline-block;
    margin-right: ${(props): string => (props.iconSize ? "6px" : "")};
    width: ${(props): string => (props.iconSize ? props.iconSize + "px" : "20px")};
    padding: ${(props): string => (props.iconSize ? "0px 6px 0px 0px" : "")};
`;

const LabelContainer = styled.div`
    display: inline-block;
    padding-top: 7px;
`;

const IconLabelContainer = styled.div<{
    barOffset: number;
    iconMaxOfsset?: number;
}>`
    display: flex;
    position: relative;
    width: 100%;
    left: ${(props): string =>
        `calc(${
            props.barOffset > (props.iconMaxOfsset || 75)
                ? props.iconMaxOfsset && props.iconMaxOfsset < 75
                    ? props.iconMaxOfsset
                    : 75
                : props.barOffset
        }%)`};
`;

const Container = styled.div<{ width?: number; iconSize?: number }>`
    padding-left: 5px;
    height: ${(props): string => (props.iconSize ? "45px" : "20px")};
    height: 25px;
    width: ${(props): string => (props.width ? props.width + "px" : "auto")};
    top: 5px;
`;

const PopulationBarContainer = styled.div`
    height: 6px;
    width: auto;
    min-width: 121px;
    position: relative;
`;

const PopulationBarBackground = styled.div<{ barWeight?: number }>`
    height: ${(props): string => (props.barWeight ? props.barWeight + "px" : "8px")};
    background-color: #d5d8dc;
`;

const PopulationBarFill = styled.div<{
    progressPercentage: number;
    barColour?: string;
    barWeight?: number;
    barOffset?: number;
}>`
    height: ${(props): string => (props.barWeight ? props.barWeight + "px" : "8px")};
    width: ${(props): string => `calc(${props.progressPercentage}%)`};
    background-color: ${(props): string => (props.barColour ? props.barColour : "#0099F8")};
    position: absolute;
    left: ${(props): string => `calc(${props.barOffset}%)`};
    z-index: 2;
`;
