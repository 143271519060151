import * as React from "react";
import styled from "styled-components";
import SVGIconDesk from "../Icon/Desk";
import ListItemInsight from "../Tiles/ListItemInsight";

export interface Occupancy {
    floorOccupancyCount: number;
    floorOccupancyCapacity: number;
    deskOccupancyCount: number;
    deskRecentOccupancyCount: number;
    deskOccupancyCapacity: number;
    roomOccupancyCount: number;
    roomOccupancyCapacity: number;
}

export interface Props {
    occupancyMode: "Vacant" | "Occupied";
    occupancy: Occupancy;
    shouldShowDeskRecentOccupancyInsights: boolean;
}

export const DeskTab: React.FC<Props> = (props: Props) => {
    const { occupancy, occupancyMode, shouldShowDeskRecentOccupancyInsights } = props;
    const { deskOccupancyCapacity, deskOccupancyCount, deskRecentOccupancyCount } = occupancy;

    const deskTitle = (): string => {
        // prettier-ignore
        return occupancyMode !== "Occupied"
            ? `${
            deskOccupancyCapacity - deskOccupancyCount - deskRecentOccupancyCount
            } / ${deskOccupancyCapacity} Desks`
            : `${deskOccupancyCount + deskRecentOccupancyCount} / ${deskOccupancyCapacity} Desks`;
    };

    const deskVacantTitle = (): string => {
        const vacantPercent =
            ((deskOccupancyCapacity - deskOccupancyCount - deskRecentOccupancyCount) / deskOccupancyCapacity) * 100;
        return `${Math.min(vacantPercent > 0 ? vacantPercent : 0, 100).toFixed(0)}% Vacant`;
    };

    const deskOccupiedTitle = (): string => {
        const occupiedPercent = (deskOccupancyCount / deskOccupancyCapacity) * 100;
        return `${Math.min(occupiedPercent > 0 ? occupiedPercent : 0, 100).toFixed(0)}% Occupied`;
    };

    const recentOccupiedTitle = (): string => {
        const occupiedPercent = (deskRecentOccupancyCount / deskOccupancyCapacity) * 100;
        return `${Math.min(occupiedPercent > 0 ? occupiedPercent : 0, 100).toFixed(0)}% Occupied`;
    };

    return (
        <DeskWrapperInner>
            {deskOccupancyCapacity ? (
                <ListItemInsight
                    reverseProgress={occupancyMode !== "Occupied"}
                    title={deskTitle()}
                    subtitle={occupancyMode !== "Occupied" ? "Desks Vacant" : "Desk Occupancy"}
                    svgComponent={<SVGIconDesk fill="#0099F8" />}
                />
            ) : null}
            {occupancyMode !== "Occupied" && deskOccupancyCapacity ? (
                <ListItemInsight
                    reverseProgress={true}
                    title={deskVacantTitle()}
                    subtitle={"Desks Vacant"}
                    progress={(deskOccupancyCount + deskRecentOccupancyCount) / deskOccupancyCapacity}
                    colour={"#777B8F"}
                />
            ) : occupancyMode === "Occupied" && deskOccupancyCapacity ? (
                <ListItemInsight
                    title={deskOccupiedTitle()}
                    subtitle={"Desk Occupied"}
                    progress={deskOccupancyCount / deskOccupancyCapacity}
                    colour={"#0099F8"}
                />
            ) : null}
            {shouldShowDeskRecentOccupancyInsights && occupancyMode === "Occupied" ? (
                <ListItemInsight
                    title={recentOccupiedTitle()}
                    subtitle={"Recently Occupied"}
                    progress={deskRecentOccupancyCount / deskOccupancyCapacity}
                    svgComponent={deskOccupancyCapacity ? undefined : <SVGIconDesk fill="#9E9E9E" />}
                    colour={"#A624C0"}
                />
            ) : null}
        </DeskWrapperInner>
    );
};

const DeskWrapperInner = styled.div`
    display: flex;
    margin-right: 12px;
`;
