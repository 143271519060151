import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class RightArrow extends React.Component<Props> {
    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <Wrapper
                {...this.props}
                width="100%"
                height="100%"
                viewBox="0 0 12 13"
                fillColor={this.props.fill || "#000000"}
            >
                <g
                    id="3936:-I-want-to-know-if-my-internet-browser-will-not-be-able-to-provide-an-optimal-experience"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                >
                    <g
                        className={"RightArrow-content"}
                        id="Group"
                        transform="translate(-6.000000, -6.000000)"
                        stroke={this.props.fill || "#000000"}
                        strokeWidth="1.5"
                    >
                        <path
                            d="M16.9497,12.3639 L11.9997,7.4139 M16.9497,12.3639 L11.9997,17.3139 M17,12.3639 L7,12.3639 L17,12.3639 Z"
                            id="Icon-RightArrow"
                        />
                    </g>
                </g>
            </Wrapper>
        );
    }
}

const Wrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
