import * as React from "react";
import styled from "styled-components";
import SVGIconDesk from "../Icon/Desk";
import Floor from "../Icon/Floor";
import SVGIconPeopleBust from "../Icon/PeopleBust";
import SVGIconVideoConference from "../Icon/VideoConference";
import ListItemInsight from "../Tiles/ListItemInsight";

export interface Occupancy {
    floorOccupancyCount: number;
    floorOccupancyCapacity: number;
    deskOccupancyCount: number;
    deskRecentOccupancyCount: number;
    deskOccupancyCapacity: number;
    roomOccupancyCount: number;
    roomOccupancyCapacity: number;
}

export interface Props {
    occupancyMode: "Vacant" | "Occupied";
    occupancy: Occupancy;
    shouldShowFloorOccupancyInsights: boolean;
}

export const FloorTab: React.FC<Props> = (props: Props) => {
    const { occupancy, occupancyMode, shouldShowFloorOccupancyInsights } = props;
    const {
        floorOccupancyCapacity,
        floorOccupancyCount,
        deskOccupancyCapacity,
        deskOccupancyCount,
        deskRecentOccupancyCount,
        roomOccupancyCapacity,
        roomOccupancyCount,
    } = occupancy;

    const floorOccupancyTitle = (capacity: number, count: number): string => {
        const floorCount = count < 0 ? 0 : count;
        const vacantPercent = ((capacity - floorCount) / capacity) * 100;
        const occupiedPercent = (floorCount / capacity) * 100;
        return occupancyMode !== "Occupied"
            ? `${Math.max(vacantPercent, 0).toFixed(0)}% Vacant`
            : `${Math.max(occupiedPercent, 0).toFixed(0)}% Occupied`;
    };

    const roomOccupancyTitle = (capacity: number, count: number): string => {
        const vacantPercent = ((capacity - count) / capacity) * 100;
        const occupiedPercent = (count / capacity) * 100;
        return occupancyMode !== "Occupied"
            ? `${Math.min(vacantPercent > 0 ? vacantPercent : 0, 100).toFixed(0)}% Vacant`
            : `${Math.min(occupiedPercent > 0 ? occupiedPercent : 0, 100).toFixed(0)}% Occupied`;
    };

    const deskOccupancyTitle = (capacity: number, count: number, recentOccupancyCount: number): string => {
        const vacantPercent = ((capacity - count - recentOccupancyCount) / capacity) * 100;
        const occupiedPercent = ((count + recentOccupancyCount) / capacity) * 100;
        return occupancyMode !== "Occupied"
            ? `${Math.min(vacantPercent > 0 ? vacantPercent : 0, 100).toFixed(0)}% Vacant`
            : `${Math.min(occupiedPercent > 0 ? occupiedPercent : 0, 100).toFixed(0)}% Occupied`;
    };

    return (
        <InsightWrapperInner>
            <ListItemInsight
                reverseProgress={occupancyMode !== "Occupied"}
                title={`${Math.max(floorOccupancyCount, 0)} People`}
                subtitle={"People Count"}
                svgComponent={<SVGIconPeopleBust fill="#0099F8" />}
            />
            {shouldShowFloorOccupancyInsights ? (
                <ListItemInsight
                    reverseProgress={occupancyMode !== "Occupied"}
                    title={floorOccupancyTitle(floorOccupancyCapacity, floorOccupancyCount)}
                    progress={floorOccupancyCount / floorOccupancyCapacity}
                    svgComponent={<Floor fill="#0099F8" />}
                />
            ) : null}
            {deskOccupancyCapacity ? (
                <ListItemInsight
                    reverseProgress={occupancyMode !== "Occupied"}
                    title={deskOccupancyTitle(deskOccupancyCapacity, deskOccupancyCount, deskRecentOccupancyCount)}
                    progress={(deskOccupancyCount + deskRecentOccupancyCount) / deskOccupancyCapacity}
                    svgComponent={<SVGIconDesk fill="#0099F8" />}
                />
            ) : null}
            {roomOccupancyCapacity ? (
                <ListItemInsight
                    reverseProgress={occupancyMode !== "Occupied"}
                    title={roomOccupancyTitle(roomOccupancyCapacity, roomOccupancyCount)}
                    progress={roomOccupancyCount / roomOccupancyCapacity}
                    svgComponent={<SVGIconVideoConference fill="#0099F8" />}
                />
            ) : null}
        </InsightWrapperInner>
    );
};

const InsightWrapperInner = styled.div`
    display: flex;
    margin-right: 12px;
`;
