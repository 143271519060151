import { SmartSpaces } from "./SmartSpaces";
import { createRequest } from "./ServiceHandlers";
/**
 * Gets the Floor Items for the specified building
 *
 * @param token the authorization token.
 * @param includePlanImage If the API request should also retrieve the images for each plan - recommended to set to true only when retrieving required/selected floorplan.
 * @param spaceId? The id of the Space, filters to a plans of a specific space or leave undefined to get an array of of all the customers plans.
 * @param planId? Specify a specific plan to get, filters to a specific plan leave undefined to get an array of all the spaces plans.
 */
export const getTelemetry = async (
    token: string,
    planId?: number,
    groupId?: number,
): Promise<Array<SmartSpaces.WebApp.Telemetry>> => {
    const requestBody = Object.assign({}, planId && { planId }, groupId && { groupId });
    const { body } = await createRequest({
        apiPath: `/telemetry`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        body: requestBody,
    });

    const telemetry: Array<SmartSpaces.WebApp.Telemetry> = typeof body === "object" ? body : JSON.parse(body);
    return telemetry;
};
