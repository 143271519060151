import React, { FC } from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { config } from "../../../../config/Config";

export interface Props {
    headerElement: React.ReactElement;
    infoElement: React.ReactElement;
}

const ErrorBanner: FC<Props> = (props: Props) => {
    const { headerElement, infoElement } = props;

    return (
        <FlexContainer
            tabIndex={0}
            maxWidth={config.breakpoints.tablet}
            minWidth={config.breakpoints.mobile}
            aria-label=""
        >
            <Content>
                <HeaderContainer>
                    <ErrorOutlineIcon
                        data-testid="ErrorIcon"
                        style={{ color: "#d0021b", fontSize: "36px" }}
                    ></ErrorOutlineIcon>
                    <Header>{headerElement}</Header>
                </HeaderContainer>
                <Info>{infoElement}</Info>
            </Content>
        </FlexContainer>
    );
};

export default ErrorBanner;

const Info = styled.p`
    font-size: 13px;
    color: #000000;
`;

const Header = styled.p`
    padding-left: 9px;
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    margin: 0;
`;

const Content = styled.div`
    padding: 21px 81px 39px 25px;
`;

const FlexContainer = styled.div<{ maxWidth: number; minWidth: number }>`
    display: flex;
    height: 134px;
    background: #ffffff;
    min-width: ${(props): string => props.minWidth + "px"};
    max-width: ${(props): string => props.maxWidth + "px"};
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 50%);
    border-top: 4px solid #d0021b;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
