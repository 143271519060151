import { toJS, makeAutoObservable } from "mobx";
import { Admin } from "../../WebService/Admin/ApiDefinition";

type AdminBuilding = Admin.Building;
type AdminFloor = Admin.Floor;
type AdminBuildings = Admin.Buildings;
type AdminFloors = Admin.Floors;
type CustomerLocationStates = Array<string>;
type CustomerResources = Admin.Resources;
type CustomerAssetFeatureGroups = Array<Admin.AssetFeatureGroups>;
type CustomerMetaData = Array<Admin.MetaData>;
type UserData = Admin.CarparkUser;
type TimeZoneData = Admin.TimeZoneData;

class Management {
    adminBuilding: AdminBuilding;
    adminBuildings: AdminBuildings;
    adminFloor: AdminFloor;
    adminFloors: AdminFloors;
    customerMetaData: CustomerMetaData;
    latestMetaDataTimeStamp: number;
    customerLocationStates: CustomerLocationStates;
    customerResources: CustomerResources;
    customerDeskFeatureGroups: CustomerAssetFeatureGroups;
    customerRoomFeatureGroups: CustomerAssetFeatureGroups;
    userData: UserData;
    timeZoneData: TimeZoneData;

    constructor(data = {} as Management) {
        const {
            adminBuilding = {} as AdminBuilding,
            adminBuildings = {} as AdminBuildings,
            adminFloor = {} as AdminFloor,
            adminFloors = {} as AdminFloors,
            customerMetaData = {} as CustomerMetaData,
            latestMetaDataTimeStamp = 0,
            customerLocationStates = {} as CustomerLocationStates,
            customerResources = {} as CustomerResources,
            customerDeskFeatureGroups = {} as CustomerAssetFeatureGroups,
            customerRoomFeatureGroups = {} as CustomerAssetFeatureGroups,
            userData = {} as UserData,
            timeZoneData = {} as TimeZoneData,
        } = data ?? {};

        this.adminBuilding = adminBuilding;
        this.adminBuildings = adminBuildings;
        this.adminFloor = adminFloor;
        this.adminFloors = adminFloors;
        this.customerMetaData = customerMetaData;
        this.latestMetaDataTimeStamp = latestMetaDataTimeStamp;
        this.customerLocationStates = customerLocationStates;
        this.customerResources = customerResources;
        this.customerDeskFeatureGroups = customerDeskFeatureGroups;
        this.customerRoomFeatureGroups = customerRoomFeatureGroups;
        this.userData = userData;
        this.timeZoneData = timeZoneData;
        makeAutoObservable(this);
    }

    public setCustomerResources(customerResources: CustomerResources): void {
        this.customerResources = customerResources;
    }

    public updateCustomerResources(customerResource: Admin.Resource): void {
        const { resourceId, features, name, peopleCapacity } = customerResource;
        const resourceIndex = this.customerResources.results.findIndex((result) => result.resourceId === resourceId);

        this.customerResources.results[resourceIndex].features = features;
        this.customerResources.results[resourceIndex].name = name;
        this.customerResources.results[resourceIndex].peopleCapacity = peopleCapacity;
    }

    public setCustomerDeskFeatureGroups(deskFeatureGroups: CustomerAssetFeatureGroups): void {
        this.customerDeskFeatureGroups = deskFeatureGroups;
    }

    public setCustomerRoomFeatureGroups(roomFeatureGroups: CustomerAssetFeatureGroups): void {
        this.customerRoomFeatureGroups = roomFeatureGroups;
    }

    public setCustomerLocationStates(customerLocationStates: CustomerLocationStates): void {
        this.customerLocationStates = customerLocationStates;
    }

    public setAdminBuildingData(adminBuilding: AdminBuilding): void {
        this.adminBuilding = adminBuilding;
    }

    public setAdminBuildingsData(adminBuildings: AdminBuildings): void {
        this.adminBuildings = adminBuildings;
    }

    public setAdminFloorData(adminFloor: AdminFloor): void {
        this.adminFloor = adminFloor;
    }

    public setAdminFloorsData(adminFloors: AdminFloors): void {
        this.adminFloors = adminFloors;
    }

    public setCustomerMetaDataData(customerMetaData: CustomerMetaData, timeStamp: number): void {
        if (this.latestMetaDataTimeStamp > timeStamp) {
            return;
        }

        this.customerMetaData = customerMetaData;
        this.latestMetaDataTimeStamp = timeStamp;
    }

    public setUserData(userData: UserData): void {
        this.userData = userData;
    }

    public setTimeZoneData(timeZoneData: TimeZoneData): void {
        this.timeZoneData = timeZoneData;
    }

    public toJSON(): {} {
        return {
            adminBuilding: toJS(this.adminBuilding),
            adminBuildings: toJS(this.adminBuildings),
            adminFloor: toJS(this.adminFloor),
            adminFloors: toJS(this.adminFloors),
            customerMetaData: toJS(this.customerMetaData),
            customerLocationStates: toJS(this.customerLocationStates),
            customerResources: toJS(this.customerResources),
            timeZoneData: toJS(this.timeZoneData),
        };
    }
}

export default Management;
