import { SmartSpaces } from "./SmartSpaces";
import {
    Request,
    createRequest,
    errorCallback,
    maxContinuousWaitTime,
    continuousWaitTimeIncrease,
} from "./ServiceHandlers";

/**
 * Generate request with minimal parameters
 */
const request = (
    path: string,
    token: string,
    body: Request["body"] = undefined,
    method: Request["method"] = "GET",
    contentType: Request["contentType"] = "application/json",
): Request => ({
    apiPath: `/bookings${path}`,
    method,
    authorization: `Bearer ${token}`,
    contentType,
    body,
});

/**
 * Generic function to extract data
 *
 * @param body - content body
 */
const getContent = <ContentType,>(body: string | null): ContentType =>
    typeof body === "object" ? body : JSON.parse(body);

/**
 * Generic function to make API calls recursively with retry
 *
 * @param fn - function signature to call recursively
 * @param waitFor - initial wait time in milliseconds before retrying
 * @param args - optional parameters to pass to `fn` depending on its signature
 *
 * @example
 * // Recursively call getDates every 1000ms until it returns success
 * callFnWithWaitTime(getDates, 1000, "token_string")
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const callFnWithWaitTime = <CallbackType, ParamType extends Array<any>>(
    fn: (...args: ParamType) => Promise<CallbackType>,
    waitFor: number,
    ...args: ParamType
): Promise<CallbackType> =>
    new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await fn(...args).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitFor + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(callFnWithWaitTime(fn, wait, ...args));
                },
            );
        }, waitFor * 1000),
    );

/**
 * Get Admin Console list of dates to display on date selection drop-down
 */
export const getDates = async (token: string): Promise<Array<SmartSpaces.WebApp.Bookings.Date>> => {
    const { body } = await createRequest(request("/dates", token));
    return getContent(body);
};

/**
 * Get Admin Console buildings
 */
export const getBuildings = async (token: string): Promise<Array<SmartSpaces.WebApp.Bookings.Building>> => {
    const { body } = await createRequest(request("/buildings", token));
    return getContent(body);
};

/**
 * Get Admin Console floors
 */
export const getFloors = async (token: string): Promise<Array<SmartSpaces.WebApp.Bookings.Floor>> => {
    const { body } = await createRequest(request("/floors", token));
    return getContent(body);
};

/**
 * Get Admin Console building updates
 */
export const getBuildingUpdates = async (
    token: string,
    buildingId?: number,
    floorId?: number,
): Promise<Array<SmartSpaces.WebApp.Bookings.Insight>> => {
    const { body } = await createRequest(
        request(
            "/insights/buildings",
            token,
            (buildingId || floorId) && Object.assign({}, buildingId && { buildingId }, floorId && { floorId }),
        ),
    );
    return getContent(body);
};
