import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * ProgressBarProps
 */
export interface ProgressBarProps {
    progress?: number | 0; // Range is [0, 1]
    reverseProgress?: boolean;
    colour?: string;
    barHeight?: string;
    angled?: boolean;
}

export default class ProgressBar extends React.Component<ProgressBarProps> {
    /**
     * Render
     */
    public render(): ReactNode {
        let progressPercent: number = Math.min(this.props.progress ? this.props.progress * 100 : 0, 100);
        if (progressPercent === 0 && this.props.reverseProgress === true) {
            progressPercent = 100;
        }
        return (
            <Container>
                <ProgressBarContainer role="figure" reverseProgress={this.props.reverseProgress}>
                    <ProgressBarBackground barHeight={this.props.barHeight} angled={this.props.angled}>
                        <ProgressBarFill
                            colour={this.props.colour}
                            progressPercentage={progressPercent}
                            barHeight={this.props.barHeight}
                            angled={this.props.angled}
                        />
                    </ProgressBarBackground>
                </ProgressBarContainer>
            </Container>
        );
    }
}

const Container = styled.div`
    height: 10px;
    width: auto;
`;

const ProgressBarContainer = styled.div<{ reverseProgress?: boolean }>`
    height: 6px;
    width: auto;
    min-width: 121px;
    position: relative;
    transform: rotate(${(props): string => (props.reverseProgress === true ? "180deg" : "0deg")});
`;

const ProgressBarBackground = styled.div<{ barHeight?: string; angled?: boolean }>`
    height: ${(props): string => (props.barHeight ? props.barHeight : "6px")};
    border-radius: ${(props): string => (props.angled ? "0px" : "5px")};
    background-color: #d5d8dc;
`;

const ProgressBarFill = styled.div<{
    progressPercentage: number;
    colour?: string;
    barHeight?: string;
    angled?: boolean;
}>`
    height: ${(props): string => (props.barHeight ? props.barHeight : "6px")};
    width: ${(props): string => `calc(${props.progressPercentage}%)`};
    background-color: ${(props): string => (props.colour ? props.colour : "#0099F8")};
    float: left;
    &:first-of-type {
        border-top-left-radius: ${(props): string => (props.angled ? "0px" : "5px")};
        border-bottom-left-radius: ${(props): string => (props.angled ? "0px" : "5px")};
    }
    &:last-of-type {
        border-top-right-radius: ${(props): string => (props.angled ? "0px" : "5px")};
        border-bottom-right-radius: ${(props): string => (props.angled ? "0px" : "5px")};
    }
`;
