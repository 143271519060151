import React, { FC } from "react";
import { Dashboard } from "../../../layouts";
import { observer } from "mobx-react";
import styled from "styled-components";

const Organisations: FC = observer(() => {
    return (
        <Dashboard>
            <Container>Device Management / Organisations view</Container>
        </Dashboard>
    );
});

export default Organisations;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;
