import { SmartSpaces } from "./SmartSpaces";
import { createRequest, errorCallback, maxContinuousWaitTime, continuousWaitTimeIncrease } from "./ServiceHandlers";

/**
 * Get customer data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getSpacesWithRetry = async (
    waitTime: number,
    authToken: string,
): Promise<{ [id: string]: SmartSpaces.WebApp.Space }> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getSpaces(authToken).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getSpacesWithRetry(wait, authToken));
                },
            );
        }, waitTime * 1000),
    );
};

/**
 * Gets the customer's spaces
 *
 * @param token - the auth token
 * @param spaceId - space of the plans to be fetched
 */
const getSpaces = async (
    token: string,
    params?: { spaceId?: number },
): Promise<{ [id: string]: SmartSpaces.WebApp.Space }> => {
    const { body } = await createRequest({
        apiPath: `/spaces`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
        ...(params && params.spaceId
            ? {
                  body: {
                      spaceId: params.spaceId,
                  },
              }
            : {}),
    });

    const spaces: Array<SmartSpaces.WebApp.Space> = typeof body === "object" ? body : JSON.parse(body);
    // Should come up with better solution to avoid disable eslint line
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const spacesHashTable = spaces.reduce((a: any, e: any) => {
        a[e.id] = e;
        return a;
    }, {});
    return spacesHashTable;
};
