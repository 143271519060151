import React, { FC, useEffect } from "react";
import { Dashboard } from "../../../layouts";
import styled from "styled-components";
import { observer } from "mobx-react";
import { models, Report, Embed, IEmbedConfiguration, service } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useStore, UIView } from "../../../services";
import { browserName } from "react-device-detect";
import { ExportButton } from "../../../components/index";
import { media, config } from "../../../config/Config";

/**
 * Initial embed configuration
 */
const defaultEmbedConfig: IEmbedConfiguration = {
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    permissions: models.Permissions.Read,
    tokenType: models.TokenType.Embed,
    type: "report",
    settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
    },
};
const Reports: FC = observer(() => {
    /**
     * Set embed configuration (`embedConfig`) for the report
     *
     * @type {Object} embedReportConfig - State variable to manage `embedConfig`.
     * @type {Function} ,pdfExport - Set state variable.
     */
    const [embedReportConfig, setEmbedReportConfig] = React.useState({
        ...defaultEmbedConfig,
    });
    const store = useStore();
    /**
     * Set a state variable to flag report loaded
     *
     * @type {Object} isReportLoaded - Set to true after report loaded
     * @type {Function} setEmbedReportConfig - Set state variable.
     */
    const [isReportLoaded, setIsReportLoaded] = React.useState(false);
    const [isExportReady, setExportReady] = React.useState(false);

    let report: Report;
    /**
     * Update report config after retrieving new values from api
     *
     * @param {({}) => void} callback - Function to call after retrieving data
     */

    /**Pdf Export functionality */
    const pdfExport = (): void => {
        // Prevents "Uncaught Error: target window is not provided." exception.
        if (report.iframe.contentWindow) {
            if (browserName === "Safari") {
                setTimeout(() => {
                    window.print();
                }, 1000);
            } else {
                report.print();
            }
        }
    };
    useEffect(() => {
        // Prevents a stale state update on an unmounted component
        let isMounted = true;
        store.ui.setCurrentView(UIView.Report);
        /**
         * Callback function to receive updated PowerBI report parameters.
         * @param {Object} pbiReport - Updated report parameters.
         */
        const handleUpdateEmbedReportConfig = async (): Promise<void> => {
            if (!isMounted || isReportLoaded) return;

            const pbiReport = await store.subscription.reports.getPbiReport();
            setEmbedReportConfig((embedReportConfig) => ({
                ...embedReportConfig,
                accessToken: pbiReport.token,
                embedUrl: pbiReport.url,
                id: pbiReport.reportId,
            }));
        };
        const updateEmbedReportConfig = (callback: () => void): void => {
            callback();
        };
        updateEmbedReportConfig(handleUpdateEmbedReportConfig);

        return (): void => {
            isMounted = false;
        };
    }, [isReportLoaded, store.ui, store.subscription]);
    const onClickExportButton = (): void => {
        const event = new Event("onPdfExport");
        window.dispatchEvent(event);
    };

    const exportReady = (): void => {
        setExportReady(true);
    };

    React.useEffect(() => {
        window.addEventListener("onExportsReady", exportReady);

        return (): void => {
            window.removeEventListener("onExportsReady", exportReady);
        };
    }, [isExportReady]);
    // Register global events after reports are rendered
    const handleOnReportRendered = (): void => {
        const event = new Event("onExportsReady");
        window.addEventListener("onPdfExport", pdfExport);
        window.dispatchEvent(event);
    };
    // Save report object for later use with report.print()
    const handleGetEmbeddedComponent = (embedObject: Embed): void => {
        report = embedObject as Report;
    };
    // Map of event handlers to be applied to the embedded report
    const eventHandlersMap = new Map([
        [
            "loaded",
            (): void => {
                setIsReportLoaded(true);
            },
        ],
        [
            "rendered",
            (): void => {
                handleOnReportRendered();
            },
        ],
        [
            "error",
            (event?: service.ICustomEvent<CustomEvent>): void => {
                if (event) {
                    // tslint:disable-next-line: no-console
                    console.error("Error loading PowerBI.", event.detail);
                    setIsReportLoaded(false);
                }
            },
        ],
    ]);
    return (
        <Dashboard>
            <ReportContainer id="reportContainer" data-cy="reports-page">
                {store.ui.currentView === UIView.Report && isExportReady && (
                    <RowContainer>
                        <Container maxWidth={config.breakpoints.tablet} minWidth={config.breakpoints.mobile}>
                            <ExportButton
                                label="Export PDF"
                                primaryColour="#001e82"
                                hoverColour="#0064D2"
                                onClick={onClickExportButton}
                            />
                        </Container>
                    </RowContainer>
                )}
                <PowerBIEmbed
                    embedConfig={embedReportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={"ReportsPage__ReportContainer__Embedder"}
                    getEmbeddedComponent={handleGetEmbeddedComponent}
                />
            </ReportContainer>
        </Dashboard>
    );
});
const RowContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: #f1f5f9;
`;
const Container = styled.div<{ maxWidth: number; minWidth: number }>`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-width: ${(props): string => props.minWidth + "px"};
    max-width: ${(props): string => props.maxWidth + "px"};
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    ${media.mobile} {
        min-width: auto;
    }
`;
const ReportContainer = styled.div`
    width: 100%;
    height: calc(100vh - 70px);
    flex-grow: 1;
    border: auto 0 0 0;
    border-top: 1px solid #ececf1;
    background: transparent;

    .ReportsPage__ReportContainer__Embedder {
        height: 100%;
        width: 100%;
    }

    iframe {
        border: none;
    }

    @media print {
        padding-left: 4%;
        background-color: #ffffff;
        height: 100vh;
        width: 100vw;
    }
`;

export default Reports;
