import * as React from "react";
import styled from "styled-components";
import { media } from "../../config/Config";
import { FloorTab } from "../Tab/FloorTab";
import { RoomTab } from "../Tab/RoomTab";
import { DeskTab } from "../Tab/DeskTab";
import { Link } from "react-router-dom";
import Level from "../Icon/Level";
import { OccupancyMode } from "./../../services/Store/UIStore";
/**
 * FloorOccupancy
 */
export interface Occupancy {
    floorOccupancyCount?: number;
    floorOccupancyCapacity?: number;
    deskOccupancyCount?: number;
    deskRecentOccupancyCount?: number;
    deskOccupancyCapacity?: number;
    roomOccupancyCount?: number;
    roomOccupancyCapacity?: number;
}

/**
 * Props
 */
export interface Props {
    floorName: string;
    occupancyMode: OccupancyMode;
    tabType: "Floors" | "Desks" | "Rooms";
    occupancy: Occupancy;
    shouldShowDeskRecentOccupancyInsights?: boolean;
    shouldShowFloorOccupancyInsights: boolean;
    floorId: number;
    buildingId: number;
    wrldId: string | null | undefined;
}

export const FloorListItem: React.FC<Props> = (props: Props) => {
    const occupancy = {
        floorOccupancyCount: props.occupancy.floorOccupancyCount || 0,
        floorOccupancyCapacity: props.occupancy.floorOccupancyCapacity || 0,
        deskOccupancyCount: props.occupancy.deskOccupancyCount || 0,
        deskRecentOccupancyCount: props.occupancy.deskRecentOccupancyCount || 0,
        deskOccupancyCapacity: props.occupancy.deskOccupancyCapacity || 0,
        roomOccupancyCount: props.occupancy.roomOccupancyCount || 0,
        roomOccupancyCapacity: props.occupancy.roomOccupancyCapacity || 0,
    };

    type ConditonalWrapperProps = {
        children: React.ReactElement;
        wrapper: (children: React.ReactElement) => JSX.Element;
    };

    const hasRealtimeView = props.wrldId !== "" && props.wrldId !== undefined && props.wrldId !== null;
    const ConditionalLinker: React.FC<ConditonalWrapperProps> = ({ wrapper, children }) =>
        hasRealtimeView ? wrapper(children) : children;

    return (
        <React.Fragment>
            <ConditionalLinker
                wrapper={(children: JSX.Element): JSX.Element => (
                    <StyledLink to={`/spaces/buildings/${props.buildingId}/floors/${props.floorId}`}>
                        {children}
                    </StyledLink>
                )}
            >
                <FloorListItemContainer linked={hasRealtimeView}>
                    <Title>
                        <FloorIcon>
                            <LevelIcon />
                        </FloorIcon>
                        <FloorNameWrapper>
                            <FloorName> {props.floorName} </FloorName>
                            <HoverPrompt>Click to see details</HoverPrompt>
                        </FloorNameWrapper>
                    </Title>
                    <InsightsWrapper>
                        {props.tabType === "Floors" ? (
                            <FloorTab
                                occupancyMode={props.occupancyMode}
                                occupancy={occupancy}
                                shouldShowFloorOccupancyInsights={props.shouldShowFloorOccupancyInsights}
                            ></FloorTab>
                        ) : props.tabType === "Desks" ? (
                            <DeskTab
                                occupancyMode={props.occupancyMode}
                                occupancy={occupancy}
                                shouldShowDeskRecentOccupancyInsights={
                                    props.shouldShowDeskRecentOccupancyInsights
                                        ? props.shouldShowDeskRecentOccupancyInsights
                                        : false
                                }
                            ></DeskTab>
                        ) : props.tabType === "Rooms" ? (
                            <RoomTab occupancyMode={props.occupancyMode} occupancy={occupancy}></RoomTab>
                        ) : null}
                    </InsightsWrapper>
                </FloorListItemContainer>
            </ConditionalLinker>
        </React.Fragment>
    );
};

const StyledLink = styled(Link)`
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;
const Title = styled.div`
    height: 100%;
    width: 100%;
    max-width: 349px;
    min-width: max-content;
    display: flex;
    align-items: center;
    margin-left: 48px;
    float: left;
    flex-grow: 1;
    ${media.mobile} {
        padding-left: 18px;
        width: 50%;
        max-width: 50%;
        margin: 0;
    }
    ${media.between("mobile", "tablet")} {
        margin-left: 25px;
    }
`;

const FloorName = styled.div`
    font-weight: bold;
    font-size: 22px;
    ${media.mobile} {
        font-size: 18px;
    }
`;

const HoverPrompt = styled.div`
    height: 15px;
    width: 103px;
    color: #007af8;
    font-size: 12px;
    min-height: 15px;
    visibility: hidden;

    height: 100%;
    width: 50%;
    position: absolute;
    ${media.desktop} {
        width: 103px;
    }
`;

const FloorNameWrapper = styled.div`
    overflow: visible;
    position: relative;
    width: 100%;
`;

const FloorListItemContainer = styled.div<{ linked: boolean }>`
    background: #ffffff;
    color: #000000;
    width: 100%;
    height: 110px;
    border: 1px solid #eceef1;
    border-radius: 3px;
    display: flex;
    flex-flow: row nowrap;
    min-width: min-content;
    user-select: none;
    margin-bottom: 12px;
    &:hover {
        cursor: ${(props): string => (props.linked ? "pointer" : "auto")};
        ${Title} {
            color: ${(props): string => (props.linked ? "#0099f8" : "#000000")};
        }
        ${HoverPrompt} {
            visibility: ${(props): string => (props.linked ? "visible" : "hidden")};
            ${media.mobile} {
                visibility: hidden;
            }
        }
    }
    ${media.mobile} {
        min-width: auto;
    }
`;

const LevelIcon = styled(Level).attrs((props) => ({ height: "150%", width: "150%" }))``;

const FloorIcon = styled.div`
    user-select: none;
    width: 25px;
    height: 24px;
    margin-right: 15px;
`;
const InsightsWrapper = styled.div`
    display: flex;
    height: 100%;
    margin-left: auto;
    ${media.mobile} {
        display: none;
    }
`;
