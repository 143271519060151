import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { media, config } from "../../../config/Config";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import { EditedAssetDetails } from "../../../views/Management/Assets/Assets";

// -- component imports
import AssetListItem from "../AssetListItem/AssetListItem";

/**
 * Definition of the data required for each list item.
 *
 * @interface ListData
 */
export interface ListData {
    resourceId: number;
    name: string;
    resourceType: string;
    bookable: boolean;
    features?: Array<Admin.ResourceFeature>;
    peopleCapacity?: number;
}

/**
 * Props
 */
export interface Props {
    listHeader: string;
    buildingId: number;
    floorId: number;
    listData: Array<ListData>;
    onChangeBookable: (deskName: string, id: number, bookable: boolean, resourceType: string) => void;
    onEditAsset: (selectedAssetForEdit: EditedAssetDetails) => void;
    onCloneAsset?: (resourceId: number) => void;
}

const AssetList: FC<Props> = (props: Props) => {
    const { listHeader, listData, onChangeBookable, onEditAsset, onCloneAsset } = props;

    const onClickBookable = (deskName: string, id: number, bookable: boolean, resourceType: string) => {
        onChangeBookable(deskName, id, bookable, resourceType);
    };

    const generateCapacityFeature = (features?: Array<Admin.ResourceFeature>, peopleCapacity?: number) => {
        const peopleCapacityFeature = {
            optionId: 999,
            optionName: `${peopleCapacity} person`,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/></svg>`,
            name: "People Capacity",
        };

        if (features) {
            const newFeatures = [peopleCapacityFeature, ...features];
            return newFeatures;
        }
    };

    const generateListItem = (list: ListData): ReactNode => {
        const { resourceId, name, bookable, resourceType, peopleCapacity } = list;
        let features = list?.features;
        const isAssetEditable: boolean = resourceType === "Desk" || resourceType === "Room";

        features =
            resourceType === "Room" && peopleCapacity ? generateCapacityFeature(features, peopleCapacity) : features;

        return (
            <Content role="listitem" key={resourceId} tabIndex={0} isAssetEditable={isAssetEditable}>
                <AssetListItem
                    assetName={name}
                    bookable={bookable}
                    onChangeBookable={(isBookable) => onClickBookable(name, resourceId, isBookable, resourceType)}
                    onEditAsset={() =>
                        onEditAsset({
                            assetName: name,
                            resourceId,
                            features,
                            resourceType,
                            peopleCapacity: peopleCapacity,
                        })
                    }
                    onCloneAsset={() => onCloneAsset && onCloneAsset(resourceId)}
                    assetFeatures={features}
                    resourceType={resourceType}
                />
            </Content>
        );
    };

    /**
     * Generates a list from buildingsData.
     */
    const listItems = listData.map(generateListItem);
    const generateList = (): ReactNode => (
        <Item role="list" maxWidth={config.breakpoints.desktop} minWidth={config.breakpoints.tablet}>
            {listItems}
        </Item>
    );

    return <Container aria-label={listHeader}>{generateList()}</Container>;
};

export default AssetList;

const Container = styled.div``;

const Content = styled.div<{ isAssetEditable: boolean }>`
    float: left;
    height: ${(props) => (props.isAssetEditable ? "72px" : "48px")};
    width: 527px;
    margin: 0 7px 7px 0;

    ${media.tablet} {
        width: 444px;
    }
`;

const Item = styled.div<{ maxWidth: number; minWidth: number }>`
    min-width: ${(props): string => props.minWidth + "px"};
    max-width: ${(props): string => props.maxWidth + "px"};
`;
