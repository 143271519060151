import React, { ReactNode } from "react";
import Wrld, { Map, Options } from "wrld.js";
import styled from "styled-components";
import ZoomControls from "../ZoomControls";
import { media } from "../../../config/Config";
import { SmartSpaces } from "../../../services/WebService/SmartSpaces";

export interface Props {
    building: { buildingId: string; latLng: Array<number> };
    planId: string | number;
    groups: Array<SmartSpaces.WebApp.Group>;
    telemetry: Array<SmartSpaces.WebApp.Telemetry>;
}

export interface State {
    isShown: boolean;
    tiltAngle: number;
    isConfigure: boolean;
    selectedMeetingRoom: string;
    selectedDesks: Array<string>;
}

class WrldMap extends React.PureComponent<Props, State> {
    private wrldMap!: Map;
    private zoomLevel = 19;
    private maxZoomLevel = 20;
    private minZoomLevel = 18.5;
    private zoomSpeed = 0.3;
    private camTiltDegrees = 45;

    private options: Options = {
        coverageTreeManifest: process.env.REACT_APP_WRLD_MANIFEST_TREE || "",
        indoorsEnabled: true,
        displayEntranceMarkers: false,
        indoorMapBackgroundColor: "#E9EEF4",
        drawClearColor: "#E9EEF4",
        showIndoorWrldWatermark: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = { isShown: true, tiltAngle: 0, isConfigure: false, selectedMeetingRoom: "", selectedDesks: [] };
    }

    static defaultProps: Props = {
        building: { buildingId: "", latLng: [0, 0] },
        planId: 0,
        groups: [],
        telemetry: [],
    };

    public componentDidMount = (): void => {
        this.wrldMap = this.startMap();
        this.setLowerBound(this.minZoomLevel);
        this.setUpperBound(this.maxZoomLevel);
        this.onTiltEnd();
    };

    public componentDidUpdate = (prevProps: Props): void => {
        const indoorMap = this.wrldMap?.indoors.getActiveIndoorMap();
        // Update backgroundColor Prop
        if (this.props.building.buildingId !== prevProps.building.buildingId) {
            this.goToLocation();

            // Check if we are starting off outside.
            if (!indoorMap) {
                this.wrldMap.on("update", this.update);
            }
        }

        if (this.props.planId !== prevProps.planId) {
            this.wrldMap?.indoors.setFloor(this.props.planId);
        }

        if (indoorMap) {
            this.updateEntities();
        }
    };

    public componentWillUnmount = (): void => {
        if (this.wrldMap) {
            if (this.wrldMap.indoors) {
                this.wrldMap.indoors.off("indoormapenter", this.onBuildingEnter);
                this.wrldMap.indoors.off("indoormapexit", this.onBuildingExit);
            }
            this.wrldMap.off("initialstreamingcomplete", this.onLoad);
            this.wrldMap.off("update", this.update);
        }
    };

    private startMap = (): Map => {
        // Initialise core wrld map object.
        const map: Map = Wrld.map("map", process.env.REACT_APP_WRLD_API_KEY || "", this.options);
        this.setState({ isShown: false });
        if (this.props.building?.buildingId) {
            map.setView(this.props.building.latLng, this.zoomLevel, { tiltDegrees: 0 });
        }

        // Set core settings for the map.
        map.attributionControl.remove();

        // Event Listeners.
        map.on("initialstreamingcomplete", this.onLoad);
        map.indoors.on("indoormapenter", this.onBuildingEnter);
        map.indoors.on("indoormapexit", this.onBuildingExit);
        map.indoors.on("indoormapload", this.onMapLoaded);
        map.indoorMapEntities.on("indoormapentityinformationchanged", this.onIndoorMapEntityInformationChanged);
        map.indoors.on("indoorentityclick", this.onIndoorEntityClicked);

        return map;
    };

    private onLoad = (): void => {
        // On start enter building.
        this.wrldMap.on("update", this.update);
    };

    // All the entity select functionalities should happen here
    private onIndoorEntityClicked = (e: { ids: Array<string> }): void => {
        if (this.state.isConfigure) {
            const selectedEntity = e.ids?.filter((v: string, i: number, a: Array<string>) => a.indexOf(v) === i);

            if (selectedEntity && selectedEntity[0].startsWith("W")) {
                this.selectDeselectDesks(selectedEntity, e.ids);
            } else if (selectedEntity && selectedEntity[0].startsWith("M")) {
                this.selectDeselectMeetingRoom(selectedEntity, e.ids);
            }
        }
    };

    public selectDeselectDesks = (selectedEntity: Array<string>, id: Array<string>): void => {
        const desks = this.state.selectedDesks;
        const index = desks.indexOf(selectedEntity[0]);
        if (id[0] && index < 0) {
            desks.push(selectedEntity[0]);
            this.wrldMap?.indoors?.setEntityHighlights(id[0], [0, 255, 0, 128]);
        } else {
            desks.splice(index, 1);
            this.wrldMap?.indoors?.clearEntityHighlights(id[0]);
        }
        this.setState({ selectedDesks: desks });
    };

    public selectDeselectMeetingRoom = (selectedEntity: Array<string>, id: Array<string>): void => {
        this.wrldMap?.indoors?.clearEntityHighlights(this.state.selectedMeetingRoom);
        this.setState({ selectedMeetingRoom: selectedEntity[0] });
        if (id) {
            this.wrldMap?.indoors?.setEntityHighlights(id, [0, 255, 0, 128]);
        }
    };

    private onBuildingEnter = (): void => {
        this.setState({ isShown: true });
        this.wrldMap.indoors.setFloor(this.props.planId);
        Wrld.indoorMapEntities.indoorMapEntityInformation(this.props.building.buildingId).addTo(this.wrldMap);
        if (this.props.groups && this.props.telemetry) {
            this.updateEntities();
        }
    };

    private onIndoorMapEntityInformationChanged = (): void => {
        this.updateEntities();
    };

    private onBuildingExit = (): void => {
        // Hide Outdoor map and go to location.
        this.setState({ isShown: false });
        this.goToLocation();

        // If we have a valid building then continue to try to enter building.
        if (this.props.building.buildingId !== "") {
            this.wrldMap.on("update", this.update);
        }
    };

    private onMapLoaded = (): void => {
        if (this.props.groups && this.props.telemetry) {
            this.updateEntities();
        }
    };

    private update = (): void => {
        // Update loop - Only place code in here that you want to run each frame.
        // Currently this updateloop is only fired when we are outside a building.

        // If we dont have any buildingid set then cancel the loop.
        if (this.props.building.buildingId === "") {
            this.wrldMap.off("update", this.update);
            return;
        }

        const enter = this.enterBuilding();
        if (enter) {
            this.wrldMap.off("update", this.update);
        }
    };

    private goToLocation = (latLng: Array<number> = this.props.building.latLng): void => {
        //Check if we are already at the location.
        if (this.wrldMap?.getCenter() !== this.props.building.latLng) {
            // If we are indoors exit before we move.
            const indoorMap = this.wrldMap?.indoors.getActiveIndoorMap();
            if (indoorMap) {
                indoorMap.exit();
            }
            // Go to new location. (allow no user interuption or animation).
            this.wrldMap?.setView(this.props.building.latLng, this.zoomLevel, {
                animate: false,
                allowInterruption: false,
            });
        }
    };

    private enterBuilding = (buildingId: string = this.props.building.buildingId): boolean => {
        return this.wrldMap.indoors.enter(buildingId);
    };

    private setCameraZoomLevel = (zoomAmount: number): void => {
        if (this.wrldMap) this.zoomLevel = this.wrldMap?.getZoom() + zoomAmount;
        if (this.zoomLevel < this.minZoomLevel) this.zoomLevel = this.minZoomLevel;
        if (this.zoomLevel > this.maxZoomLevel) this.zoomLevel = this.maxZoomLevel;
        this.wrldMap?.setView(0, this.zoomLevel, {
            durationSeconds: this.zoomSpeed,
            tiltDegrees: this.wrldMap.getCameraTiltDegrees(),
            allowInterruption: false,
        });
    };

    private setLowerBound = (limit: number): void => {
        this.wrldMap.on("zoom", () => {
            if (this.wrldMap.getZoom() <= limit) {
                this.zoomLevel = limit;
                setTimeout(() => this.wrldMap?.setView(0, this.zoomLevel), 50);
            }
        });
    };

    private setUpperBound = (limit: number): void => {
        this.wrldMap.on("zoom", () => {
            if (this.wrldMap.getZoom() >= limit) {
                this.zoomLevel = limit;
                setTimeout(() => this.wrldMap?.setView(0, this.zoomLevel), 50);
            }
        });
    };

    private processTelemetry = (): { [groupId: string]: SmartSpaces.WebApp.Telemetry } => {
        return this.props.telemetry.reduce((output, telemetryEntry) => {
            output[telemetryEntry.groupId] = telemetryEntry;
            return output;
        }, {} as { [groupId: string]: SmartSpaces.WebApp.Telemetry });
    };

    private updateEntities = (): void => {
        if (!this.state.isConfigure) {
            this.wrldMap?.indoors?.clearEntityHighlights();
            const groups = this.props.groups.filter((area) => area.type === "DESK" || area.type === "MEETING_ROOM");
            const telemetry = this.processTelemetry();
            groups.forEach((group) => {
                const t = telemetry[group.id];
                if (group.name && t && t.count > 0) {
                    this.wrldMap?.indoors?.setEntityHighlights(group.name, [0, 153, 248, 255]);
                }
            });
        }
    };

    public toggleCamera3D = (degreesWhen3D = this.camTiltDegrees): number => {
        let cameraTilt = -1;
        if (this.wrldMap.getCameraTiltDegrees() === 0) {
            this.wrldMap.setCameraTiltDegrees(degreesWhen3D);
            cameraTilt = degreesWhen3D;
        } else {
            this.wrldMap.setCameraTiltDegrees(0);
            cameraTilt = 0;
        }
        this.setState({ tiltAngle: cameraTilt });
        return cameraTilt;
    };

    private onTiltEnd = (): void => {
        this.wrldMap.on("tiltend", () => {
            this.setState({ tiltAngle: this.wrldMap.getCameraTiltDegrees() });
        });
    };

    public render(): ReactNode {
        const isShown = this.state.isShown;
        return (
            <Background backgroundColour={this.options.indoorMapBackgroundColor}>
                <MapContainer id="map" role="map" isShown={isShown}>
                    <ZoomControlContainer>
                        <ZoomControls
                            cameraTilt={this.state.tiltAngle}
                            setCameraZoomLevel={this.setCameraZoomLevel}
                            toggleCamera3D={this.toggleCamera3D}
                        />
                    </ZoomControlContainer>
                </MapContainer>
            </Background>
        );
    }
}

const ZoomControlContainer = styled.div`
    position: absolute;
    display: flex;
    right: 14px;
    bottom: 0px;
    z-index: 12;
    ${media.mobile} {
        display: none;
    }
`;

const Background = styled.div<{ backgroundColour: string | undefined }>`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${(props): string => (props.backgroundColour ? props.backgroundColour : "")};
`;

const MapContainer = styled.div<{ isShown: boolean }>`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    touch-action: none;
    opacity: ${(props): number => (props.isShown ? 1 : 0)};
    transition: all 1s ease-in-out;
`;

export default WrldMap;
