import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconDesk extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconDesk
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 15 18" fillColor={this.fillColor()}>
                <g
                    id="3308---I-want-to-see-the-overall-building-efficiency-metrics-with-key-stats"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Building-View---Occupied"
                        transform="translate(-942.000000, -317.000000)"
                        fill={this.fillColor()}
                        fillRule="nonzero"
                    >
                        <g id="Group-3" transform="translate(24.000000, 279.000000)">
                            <g id="Building-List-Floor-List/Default">
                                <g
                                    id="Desk-Occupancy-Building-List-Stat/No-Label/Default"
                                    transform="translate(893.000000, 0.000000)"
                                >
                                    <g id="Occupancy-Tile">
                                        <g id="Content" transform="translate(19.418605, 33.000000)">
                                            <g id="Stat-Icon-Stat-Icon/Desk/Default">
                                                <path
                                                    d="M20.3895351,13.2653061 L15.3090861,13.2653061 L15.3090861,12.5306122 L17.8493106,12.5306122 L17.8493106,5 L8.36580591,5 L8.36580591,12.5306122 L10.9060304,12.5306122 L10.9060304,13.2653061 L5.82558144,13.2653061 L5.82558144,16.2040816 L6.67232293,16.2040816 L6.67232293,23 L7.68841272,23 L7.68841272,16.2040816 L18.5267038,16.2040816 L18.5267038,23 L19.5427936,23 L19.5427936,16.2040816 L20.3895351,16.2040816 L20.3895351,13.2653061 Z M9.38189577,6.10204082 L16.8332207,6.10204082 L16.8332207,11.4285714 L9.38189577,11.4285714 L9.38189577,6.10204082 Z M11.9221203,12.5306122 L14.2929964,12.5306122 L14.2929964,13.2653061 L11.9221203,13.2653061 L11.9221203,12.5306122 Z M6.84167125,14.3673469 L19.3734452,14.3673469 L19.3734452,15.1020408 L6.84167125,15.1020408 L6.84167125,14.3673469 Z"
                                                    id="Icon-Desk"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
