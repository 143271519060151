import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import React, { FC, useState } from "react";
import styled from "styled-components";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    label: string;
    primaryColour: string;
    secondaryColour: string;
    width: string;
    onCallback: (checked: boolean) => void;
}

/**
 *The State for the component are defined here with their types.
 */
export interface State {
    checked: boolean;
}
const CheckBox: FC<Props> = (props: Props) => {
    const [checked, setChecked] = useState<boolean>(true);

    const onClick = (): void => {
        setChecked(!checked);
        props.onCallback(!checked);
    };
    return (
        <ButtonDiv
            primaryColour={props.primaryColour}
            secondaryColour={props.secondaryColour}
            width={props.width}
            onClick={onClick}
            role="checkbox"
            aria-label="checkedbox"
        >
            <ButtonText secondaryColour={props.secondaryColour}>{props.label}</ButtonText>
            <CheckedBoxIcon>
                {checked ? (
                    <CheckCircleIcon htmlColor={props.secondaryColour} />
                ) : (
                    <Brightness1Icon htmlColor={props.secondaryColour} />
                )}
            </CheckedBoxIcon>
        </ButtonDiv>
    );
};
export default CheckBox;
/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const ButtonText = styled.div<{ secondaryColour: string }>`
    color: ${(props): string => props.secondaryColour};
    padding-top: 8px;
    margin-left: 8px;
    float: left;
`;

const ButtonDiv = styled.div<{ primaryColour: string; secondaryColour: string; width: string }>`
    height: 35px;
    width: ${(props): string => props.width};
    background-color: ${(props): string => props.primaryColour};
    box-sizing: border-box;
    border-radius: 25px;
    border: none;
    :hover {
        cursor: pointer;
    }
`;

const CheckedBoxIcon = styled.span`
    text-align: center;
    margin-right: 5px;
    margin-top: 5px;
    float: right;
`;
