import { Link } from "react-router-dom";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { Breadcrumb } from "./../../../services/Store/UIStore";

/**
 * Props
 */
export interface Props {
    breadcrumbs: Array<Breadcrumb>;
    textColor?: string;
    highlightTextColor?: string;
}

export default class BreadCrumbNavigationBar extends React.Component<Props> {
    /**
     * Count Render
     */
    public render(): ReactNode {
        const length = this.props.breadcrumbs?.length;
        const breadcrumbs = !length ? (
            <></>
        ) : (
            this.props.breadcrumbs.map((item, index): ReactNode => {
                return (
                    <BreadcrumbItem role="listitem" key={index} underline={index === length - 1 ? false : true}>
                        <Divider isVisible={index > 0} />
                        {index === length - 1 ? (
                            <BreadcrumbText aria-current="page" color={this.props.textColor}>
                                {item.label}
                            </BreadcrumbText>
                        ) : (
                            <BreadcrumbLink to={item.to} color={this.props.highlightTextColor}>
                                {item.label}
                            </BreadcrumbLink>
                        )}
                    </BreadcrumbItem>
                );
            })
        );

        return (
            <nav aria-label="Breadcrumb">
                <Container role="list">{breadcrumbs}</Container>
            </nav>
        );
    }
}

const BreadcrumbItem = styled.div<{ underline?: boolean }>`
    height: 21px;
    text-decoration: none;
    font-size: 14px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: ${(props): string => (props.underline ? "underline" : "none")};
`;

const Container = styled.div`
    height: 21px;
    font-size: 14px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 12px;
`;

const BreadcrumbLink = styled(Link)<{ color?: string }>`
    height: 21px;
    color: ${(props): string => (props.color ? props.color : "#000000")};
    text-decoration: none;
    font-size: 14px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BreadcrumbText = styled.div<{ color?: string }>`
    height: 21px;
    color: ${(props): string => (props.color ? props.color : "#000000")};
    text-decoration: none;
    font-size: 14px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Divider = styled.div<{ isVisible: boolean }>`
    display: ${(props): string => (props.isVisible ? "block" : "none")};
    position: relative;
    height: 6px;
    width: 3px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0px;
    transform: rotate(270deg);
    :before {
        margin-left: -2px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: #333333;
        transform: skew(30deg, 0deg);
    }
    :after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background: #333333;
        transform: skew(-30deg, 0deg);
    }
    ${media.mobile} {
        display: ${(props): string => (props.isVisible ? "block" : "none")};
    }
`;
