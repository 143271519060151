import React, { useState, FC, ReactNode } from "react";
import styled from "styled-components";
import useDropdownMenu from "./../../../services/Hooks/accessibleDropDown";

export interface Props {
    listItems: Array<string>;
    onClick: (selected: string) => void;
    disable?: boolean;
}

const DropDown: FC<Props> = (props: Props) => {
    const { listItems, onClick, disable } = props;
    const { buttonProps, itemProps, isOpen } = useDropdownMenu(listItems.length);
    const [menuText, setMenuText] = useState(listItems[0]);

    const selectElement = (item: string): void => {
        setMenuText(item);
        onClick(item);
    };

    const generateDropdownList = (listItems: Array<string>): ReactNode => {
        const list = listItems.map((item, index) => {
            return (
                <MenuElement
                    {...buttonProps}
                    key={index}
                    {...itemProps[index]}
                    onClick={() => selectElement(item)}
                    role="cell"
                >
                    {item}
                </MenuElement>
            );
        });

        return list;
    };

    return (
        <Container>
            <ButtonContainer disabled={disable}>
                <MenuButton {...buttonProps} role="button" disabled={disable}>
                    <ButtonText>{menuText}</ButtonText>
                    <Chevron chevronUp={isOpen} $disabled={disable} />
                </MenuButton>
            </ButtonContainer>
            <Menu className={isOpen ? "visible" : ""} visible={isOpen}>
                {generateDropdownList(listItems)}
            </Menu>
        </Container>
    );
};

export default DropDown;

const ButtonContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    border-bottom: 2px solid ${(props): string => (props.disabled ? "rgba(0,0,0,0.28)" : "#333333")};
    margin-bottom: 5px;
    width: 317px;
`;

const Menu = styled.div<{ visible: boolean }>`
    visibility: ${(props): string => (props.visible ? "visible" : "hidden")};
    padding-bottom: 21px;
    padding-top: 9px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
`;

const MenuElement = styled.a`
    font-size: 13px;
    display: block;
    background-color: #ffffff;
    padding-left: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    :hover {
        text-decoration: 2px underline;
        cursor: pointer;
    }
`;

const MenuButton = styled.button<{ disabled?: boolean }>`
    width: 335px;
    height: 25px;
    font-size: 14px;
    background: transparent;
    text-align: left;
    margin-left: -4px;
    display: flex;
    border: none;
    :hover {
        cursor: ${(props): string => (props.disabled ? "" : "pointer")};
    }
    display: inline-block;
`;

const ButtonText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    max-width: 280px;
    float: left;
`;

const Container = styled.div`
    width: 50%;
`;

const Chevron = styled.div<{ chevronUp: boolean; $disabled?: boolean }>`
    padding-left: 12px;
    padding-top: 3px;
    background-color: transparent;
    border: none;
    float: right;
    margin-right: -1px;
    :before {
        border-style: solid;
        border-width: 0.12em 0.12em 0 0;
        content: "";
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        vertical-align: top;
        width: 0.45em;
        color: ${(props): string => (props.$disabled ? "rgba(0,0,0,0.28)" : "#333333")};
        transform: ${(props): string => (props.chevronUp ? "rotate(-45deg)" : "rotate(135deg)")};
        top: ${(props): string => (props.chevronUp ? "0.15em" : "0")};
    }
    :hover {
        cursor: ${(props): string => (props.$disabled ? "" : "pointer")};
    }
`;
