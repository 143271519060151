import { getCustomerWithRetry } from "../../WebService/CustomerInfo";

class Reports {
    private _accessToken: string;

    constructor(accessToken: string) {
        this._accessToken = accessToken;
    }

    public getPbiReport = async (): Promise<{
        reportId: string;
        token: string | undefined;
        url: string | undefined;
    }> => {
        const customer = await getCustomerWithRetry(0, this._accessToken);
        const pbiReport = customer.pbiReports[0];
        return pbiReport;
    };
}

export default Reports;
