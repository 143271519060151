import {
    getAdminBuilding,
    getAdminBuildings,
    getAdminFloor,
    getAssetFeatures,
    getCsvBookings,
    getCustomerBookings,
    getCustomerBookingsCount,
    getCustomerFloors,
    getCustomerLocationStates,
    getCustomerMetaData,
    getCustomerResources,
    getStaticFloorplan,
    getUsers,
    getUserPermission,
    uploadFloorplan,
    removeFloorplan,
    RootStore,
    setAssetBookable,
    setFloorBookable,
    submitEditedAssetDetails,
    submitCarparkSpot,
    saveCarparkPermission,
    uploadCarparkUsers,
    saveDeskDetails,
    cloneDesk,
    uploadDeskCSV,
    addFloor,
    updateBuilding,
    getTimeZone,
} from "../..";
import { DeskDetails } from "../../../views/Management/Assets/AddDesk/AddDesk";
import { CarSpotDetails, EditedAssetDetails } from "../../../views/Management/Assets/Assets";
import { Admin } from "../../WebService/Admin/ApiDefinition";
import { callFnWithWaitTime } from "../../WebService/Bookings";

class Management {
    private _accessToken: string;
    private _rootStore: RootStore | undefined;

    constructor(accessToken: string, rootStore: RootStore) {
        this._accessToken = accessToken;
        this._rootStore = rootStore ?? undefined;
    }

    public async getCustomerBookings(
        buildingId: number,
        floorId?: number,
        assetId?: number,
        page?: number,
        pageSize?: number,
    ): Promise<Admin.Bookings> {
        const customerBookings = await callFnWithWaitTime(
            getCustomerBookings,
            1,
            this._accessToken,
            buildingId,
            floorId,
            assetId,
            page,
            pageSize,
        );
        return customerBookings;
    }

    public async getCustomerBookingsCount(buildingId: number, floorId?: number, assetId?: number): Promise<number> {
        const customerBookingsCount = await callFnWithWaitTime(
            getCustomerBookingsCount,
            1,
            this._accessToken,
            buildingId,
            floorId,
            assetId,
        );
        return customerBookingsCount;
    }

    public async getCsvBookings(
        buildingId: number,
        floorId?: number,
        resourceId?: number,
    ): Promise<Array<Admin.UserBooking>> {
        const userBookings = await callFnWithWaitTime(
            getCsvBookings,
            1,
            this._accessToken,
            buildingId,
            floorId,
            resourceId,
        );
        return userBookings;
    }

    public async getCustomersResources(
        buildingId: number,
        floorId?: number,
        features?: boolean,
        iconType?: string,
        resourceType?: string,
        page?: number,
        pageSize?: number,
    ): Promise<void> {
        const maxPageSize = pageSize ?? Math.pow(2, 16) - 1;
        const resources: Admin.Resources = await callFnWithWaitTime(
            getCustomerResources,
            1,
            this._accessToken,
            buildingId,
            page,
            maxPageSize,
            floorId,
            resourceType ? resourceType : "Unspecified",
            features,
            iconType,
        );
        this._rootStore?.data.management.setCustomerResources(resources);
    }

    public async getCustomerDeskFeatureGroups(iconType?: string): Promise<void> {
        const features: Array<Admin.AssetFeatureGroups> = await callFnWithWaitTime(
            getAssetFeatures,
            1,
            this._accessToken,
            iconType ? iconType : "None",
            "Desk",
        );
        this._rootStore?.data.management.setCustomerDeskFeatureGroups(features);
    }

    public async getCustomerRoomFeatureGroups(iconType?: string): Promise<void> {
        const features: Array<Admin.AssetFeatureGroups> = await callFnWithWaitTime(
            getAssetFeatures,
            1,
            this._accessToken,
            iconType ? iconType : "None",
            "Room",
        );
        this._rootStore?.data.management.setCustomerRoomFeatureGroups(features);
    }

    public async getCustomerLocationStates(): Promise<void> {
        const customerLocationStates = await callFnWithWaitTime(getCustomerLocationStates, 1, this._accessToken);
        this._rootStore?.data.management.setCustomerLocationStates(customerLocationStates);
    }

    public async getCustomerFloorData(buildingId: number | undefined): Promise<void> {
        const customerFloorData = await callFnWithWaitTime(
            getCustomerFloors,
            1,
            this._accessToken,
            buildingId,
            1,
            undefined,
            true,
        );

        this._rootStore?.data.management.setAdminFloorsData(customerFloorData);
    }

    public async getAllCustomerMetaData(state = "", buildingId?: number, floorId?: number): Promise<void> {
        const timeStamp = Date.now();
        const customerMetaData = await callFnWithWaitTime(
            getCustomerMetaData,
            1,
            this._accessToken,
            state,
            buildingId,
            floorId,
        );
        this._rootStore?.data.management.setCustomerMetaDataData(customerMetaData, timeStamp);
    }

    public async getAdminBuilding(buildingId: number): Promise<void> {
        const buildingDataFromApi = await callFnWithWaitTime(getAdminBuilding, 1, this._accessToken, buildingId);
        this._rootStore?.data.management.setAdminBuildingData(buildingDataFromApi);
    }

    public async getAdminFloor(buildingId: number, floorId: number): Promise<void> {
        const floorDataFromApi = await callFnWithWaitTime(getAdminFloor, 1, this._accessToken, buildingId, floorId);
        this._rootStore?.data.management.setAdminFloorData(floorDataFromApi);
    }

    public async getAllAdminBuildings(state = ""): Promise<void> {
        const buildingsAdmin = await callFnWithWaitTime(
            getAdminBuildings,
            1,
            this._accessToken,
            state,
            "",
            1,
            undefined,
            true,
        );

        this._rootStore?.data.management.setAdminBuildingsData(buildingsAdmin);
    }

    public async getStaticFloorplan(floorId: number, imageName: string): Promise<File | null> {
        const floorplanImage: File | null = await callFnWithWaitTime(
            getStaticFloorplan,
            1,
            this._accessToken,
            floorId,
            imageName,
        );
        return floorplanImage;
    }

    public async uploadFloorplan(floorId: number, floorPlan: File): Promise<Admin.FloorplanUpload> {
        const floorplanImage: Admin.FloorplanUpload = await callFnWithWaitTime(
            uploadFloorplan,
            1,
            this._accessToken,
            floorId,
            floorPlan,
        );
        return floorplanImage;
    }

    public async removeFloorplan(floorId: number): Promise<null> {
        const removeFloorplanResponse: null = await callFnWithWaitTime(removeFloorplan, 1, this._accessToken, floorId);
        return removeFloorplanResponse;
    }

    public async setAssetBookable(
        resourceType: string,
        buildingId: number,
        floorId: number,
        assetId: number,
        status: boolean,
    ): Promise<boolean> {
        const booked = await setAssetBookable(this._accessToken, resourceType, buildingId, floorId, assetId, status);
        return booked;
    }

    public async setFloorBookable(floorId: number, status: boolean): Promise<boolean> {
        const booked = await setFloorBookable(this._accessToken, floorId, status);
        return booked;
    }

    public async submitEditedAssetDetails(editedAssetDetails: EditedAssetDetails): Promise<Admin.Resource> {
        const updatedAssetDetails = await submitEditedAssetDetails(this._accessToken, editedAssetDetails);
        return updatedAssetDetails;
    }

    public async saveCarparkSpot(carSpotDetails: CarSpotDetails): Promise<Admin.Resource> {
        const updatedAssetDetails = await submitCarparkSpot(this._accessToken, carSpotDetails);
        return updatedAssetDetails;
    }

    public async getUsers(filter: string): Promise<Admin.CarparkUser> {
        const userData = await getUsers(this._accessToken, filter);
        this._rootStore?.data.management.setUserData(userData);
        return userData;
    }

    public async getUserPermission(userId: number): Promise<Array<Admin.CarParkPermission>> {
        const userData = await getUserPermission(this._accessToken, userId);
        return userData;
    }

    public async saveCarparkPermission(
        userList: Array<Admin.CarParkPermission>,
    ): Promise<Array<Admin.CarParkPermission>> {
        const response = await saveCarparkPermission(this._accessToken, userList);
        return response;
    }

    public async uploadCarparkUser(usersCSV: File): Promise<Admin.CarparkUsersUploadStatus> {
        const usersExcelData: Admin.CarparkUsersUploadStatus = await uploadCarparkUsers(this._accessToken, usersCSV);
        return usersExcelData;
    }

    public async saveDeskDetails(editedAssetDetails: DeskDetails): Promise<Admin.Resource> {
        const updatedAssetDetails = await saveDeskDetails(this._accessToken, editedAssetDetails);
        return updatedAssetDetails;
    }

    public async cloneDesk(resourceId: number) {
        const updatedAssetDetails = await cloneDesk(this._accessToken, resourceId);
        return updatedAssetDetails;
    }

    public async uploadDeskCSV(deskCSV: File): Promise<Admin.UploadDeskStatus> {
        const usersExcelData: Admin.UploadDeskStatus = await uploadDeskCSV(this._accessToken, deskCSV);
        return usersExcelData;
    }

    public async addFloor(floorDetails: Admin.FloorType): Promise<Admin.Resource> {
        const updatedAssetDetails = await addFloor(this._accessToken, floorDetails);
        return updatedAssetDetails;
    }

    public async updateBuilding(buildingReq: Admin.BuildingReqParam): Promise<Admin.Resource> {
        const updatedAssetDetails = await updateBuilding(this._accessToken, buildingReq);
        return updatedAssetDetails;
    }

    public async getTimeZone(): Promise<Admin.TimeZoneData> {
        const timeZone = await getTimeZone(this._accessToken);
        this._rootStore?.data.management.setTimeZoneData(timeZone);
        return timeZone;
    }
}

export default Management;
