import React, { FC, ReactNode } from "react";
import styled from "styled-components";

export interface Props {
    display: boolean;
    children: ReactNode;
}

const Tooltip: FC<Props> = (props: Props) => {
    return (
        <ToolTipWrapper hidden={!props.display} role="tooltip">
            <Strip />
            <Content>{props.children}</Content>
        </ToolTipWrapper>
    );
};

const ToolTipWrapper = styled.div`
    position: absolute;
    display: flex;
    height: fit-content;
    width: 270px;
    min-height: 60px;
    font-size: 13px;
    align-items: stretch;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;

    &[hidden] {
        display: none;
    }
`;

const Content = styled.div`
    width: 100%;
    padding: 14px 16px 14px 0px;

    p:last-child {
        margin-bottom: 0px;
    }
`;

const Strip = styled.div`
    width: 4px;
    margin: 0px 16px 0px 0px;
    background-image: linear-gradient(180deg, #0064d2 0%, #5cd6e0 32%, #5e50bf 100%);
`;

export default Tooltip;
