class EnvConfig {
    private static allowExperimentalFeaturesIn = {
        dev: "https://ss2devv1apim.azure-api.net/smartspaces-dev-webapp-api",
        local: "http://localhost:3000",
    };

    public static enableExperimentalFeatures = (): boolean =>
        [...Object.values(EnvConfig.allowExperimentalFeaturesIn)].includes(process.env.REACT_APP_SPACES_API as string);
}

export default EnvConfig;
