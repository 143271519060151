import React, { ReactNode } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";

/**
 * BreadCrumb values
 */
export interface Breadcrumb {
    to: string;
    label: string;
    view: number;
}

/**
 * Props
 */
export interface Props {
    label?: string | undefined;
    logoIcon?: string | JSX.Element | null;
    headerTxtColor?: string;
}

class Breadcrumbs extends React.Component<Props> {
    /**
     * Checks if a logo exists and if it doesn't then use default.
     *
     * @memberof BuildingViewHeader
     */
    private getCompanyLogo = (): ReactNode => {
        if (typeof this.props.logoIcon !== "string") {
            return <SVGSizer>{this.props.logoIcon}</SVGSizer>;
        } else {
            return <CustomerInfoLogo alt="" draggable={false} src={this.props.logoIcon} />;
        }
    };

    public render(): ReactNode {
        return (
            <HeaderContainer>
                <CustomerInfoContainer>
                    <CustomerLogoContainer>{this.getCompanyLogo()}</CustomerLogoContainer>
                    <CustomerInfoTextContainer>
                        <CustomerTextName textColor={this.props.headerTxtColor}>{this.props.label}</CustomerTextName>
                    </CustomerInfoTextContainer>
                </CustomerInfoContainer>
            </HeaderContainer>
        );
    }
}

export default Breadcrumbs;

const HeaderContainer = styled.div`
    height: 60px;
    padding: 0px 35px 11px 11px;
    ${media.mobile} {
        padding: 0px;
    }

    width: 100%;
`;

const CustomerInfoContainer = styled.div`
    height: 57px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
`;

const CustomerLogoContainer = styled.div<{ padding?: boolean }>`
    width: 57px;
    height: 57px;
    min-width: 57px;
    min-height: 57px;
    overflow: hidden;
    ${(props): string => (props.padding ? "padding: 9px;" : "")}
    box-sizing: border-box;

    border-radius: 4px;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
`;

const CustomerInfoLogo = styled.img`
    max-height: 100%;
    max-width: 100%;
    user-select: none;
`;

const CustomerInfoTextContainer = styled.div`
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    ${media.mobile} {
        white-space: nowrap;
        overflow: hidden;
    }
`;

const CustomerTextName = styled.div<{ textColor?: string }>`
    height: 39px;
    color: ${(props): string => (props.textColor ? props.textColor : "#000000")};
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    flex-basis: 100%;
    ${media.mobile} {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const SVGSizer = styled.div`
    width: 32px;
    height: 32px;
`;
