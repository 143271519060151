import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ToggleAssetModal from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import AssetName from "../AssetName/AssetName";
import { InputTextWrapper } from "../DisplayAssetModal/DisplayAssetModal";

export interface Props {
    onSave: (carparkName: string) => void;
}

const CarparkSpot: FC<Props> = observer((props: Props) => {
    const { onSave } = props;
    const [showModal, setShowModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [carParkName, setCarParkName] = useState("");

    const addCarparkSpot = (): void => {
        setShowModal(true);
        setCarParkName("");
    };

    const modalCancel = () => {
        setShowModal(false);
    };

    const handleChange = (carSpot: string) => {
        setCarParkName(carSpot);
        if (carSpot === "" || carSpot.length > 35) setIsSubmitDisabled(true);
        else setIsSubmitDisabled(false);
    };

    const saveCarpark = () => {
        setShowModal(false);
        onSave(carParkName);
    };

    const modal = (
        <ToggleAssetModal
            title="Add Carpark Spot"
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={"318px"}
            width="520px"
            contentWidth="420px"
            contentHeight={"326px"}
            onClickPrimary={saveCarpark}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
            isButtonDisabled={isSubmitDisabled}
        >
            <InputTextWrapper>
                <AssetName
                    label="Carpark Spot"
                    value={carParkName}
                    onNameChange={(carSpot) => handleChange(carSpot.toString())}
                    assetTypeLabel={""}
                />
            </InputTextWrapper>
        </ToggleAssetModal>
    );

    return (
        <>
            {showModal && modal}

            <ActionButton onClick={addCarparkSpot}>
                <span>
                    <AddCircleOutlineIcon />
                    &nbsp;
                </span>
                Add Carpark Spot
            </ActionButton>
        </>
    );
});

export default CarparkSpot;

const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: none;
    color: #001e82;
    margin-right: 15px;
`;
