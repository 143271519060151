import React, { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore, useConfig } from "./../../services";
import { Loading, BlankPage } from "./../../views/Common";

interface Props {
    children: ReactNode;
    exact?: boolean;
    experimental?: boolean;
}

interface AuthProps {
    children: ReactNode;
    redirectTo: string;
}

const SecureRoute: FC<Props> = observer((props: Props) => {
    const { children } = props;
    const store = useStore();
    const config = useConfig();
    const location = useLocation();

    const isAuthenticated = store.user.isAuthenticated();
    const isRouteAllowed = config.isRouteAllowed(location.pathname);
    const isOnHomePath = location.pathname === config.route.homePath;
    const shouldShowLoading = isAuthenticated && store.ui.loadingProgress < 1;

    const baseRoute = location.pathname.split("/").slice(0, 2).join("/");
    const isCurrentRouteEnabled = store.ui.getTabByPath(baseRoute)?.enabled;
    const homeTab = store.ui.getHomeTabPath();
    const navigation = store.features.getFeature("Navigation");
    const isNavigationAvailable = !!navigation?.length;

    const handleCallback = (): void => {
        store.ui.init();
    };

    const RequireAuth = (props: AuthProps): JSX.Element => {
        const { children, redirectTo } = props;

        return isAuthenticated ? (
            // If user is authenticated and;
            !isNavigationAvailable ? (
                // If and the API returns an empty array,
                // then render the blank page.
                <BlankPage />
            ) : isOnHomePath ? (
                // Otherwise if this route is the home path,
                // then navigate to "home page".
                <Navigate to={homeTab} replace />
            ) : isCurrentRouteEnabled ? (
                // If route is not home path but it is enabled,
                // then this is an acceptable route: render children.
                <>{children}</>
            ) : (
                // If route is not home path and not enabled,
                // then user is using a bookmark or URL has been manually entered.
                // This is unacceptable: render the black page.
                <BlankPage />
            )
        ) : (
            // User isn't authenticated; therefore navigate to `redirectTo` prop.
            // This is usually the `/login` path.
            <Navigate to={redirectTo} />
        );
    };

    return shouldShowLoading ? (
        <Loading progress={store.ui.loadingProgress} callback={handleCallback} />
    ) : isRouteAllowed ? (
        <RequireAuth redirectTo="/login">{children}</RequireAuth>
    ) : (
        <Navigate to="/not-found" replace state={location.pathname} />
    );
});

export default SecureRoute;
