import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconPeopleBust extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconPeopleBust
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 18 18" fillColor={this.fillColor()}>
                <g
                    id="Building-View---Occupied"
                    transform="translate(-432.000000, -326.000000)"
                    fill={this.fillColor()}
                    fillRule="nonzero"
                >
                    <g id="Group-3" transform="translate(24.000000, 279.000000)">
                        <g id="Building-List-Floor-List/Default">
                            <g
                                id="Number-of-Floors-Building-List-Stat/No-Progress"
                                transform="translate(388.000000, 0.000000)"
                            >
                                <g id="Occupancy-Tile">
                                    <g id="Content" transform="translate(17.441860, 40.000000)">
                                        <g
                                            id="Stat-Icon-Stat-Icon/People/Default"
                                            transform="translate(0.000000, 2.000000)"
                                        >
                                            <path
                                                d="M20.0581387,21.4812669 C19.3139377,18.6753225 17.6765493,16.2940353 15.4719624,14.8114971 C17.363901,12.5033893 17.2999964,8.88194147 15.328185,6.6636892 C13.3563735,4.44543693 10.2011617,4.44543693 8.2293502,6.6636892 C6.25753874,8.88194147 6.19363411,12.5033893 8.08557272,14.8114971 C5.87899091,16.294492 4.23820905,18.6749921 3.48837196,21.4812669 L3.48837196,23 L20.0581387,23 L20.0581387,21.4812669 Z M11.7787674,6.54705759 C13.8489058,6.54705759 15.5270845,8.47361089 15.5270845,10.8501348 C15.5270845,13.2266588 13.8489058,15.1532121 11.7787674,15.1532121 C9.70862903,15.1532121 8.0304503,13.2266588 8.0304503,10.8501348 C8.0304503,8.47361089 9.70862903,6.54705759 11.7787674,6.54705759 Z M9.24314134,15.9125787 C10.8024781,16.993364 12.7550573,16.993364 14.3143941,15.9125787 C16.3602001,17.044712 17.9277341,19.067478 18.6690567,21.5318913 L4.88847866,21.5318913 C5.62980133,19.067478 7.19733529,17.044712 9.24314134,15.9125787 Z"
                                                id="Icon-PersonBust"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
