import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
    fill?: string;
    hoverFill?: string;
    count?: number;
}

export default class SVGIconBell extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconBell
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        const countVal = this.props.count || 0;
        return (
            <SVGWrapper
                width="100%"
                height="100%"
                viewBox="-19 -19 60 60"
                fillColor={this.fillColor()}
                hoverFillColor={this.props.hoverFill ? this.props.hoverFill : "#000000"}
            >
                <g id="Bell_Icon">
                    <g>
                        <path
                            d="M13.86,3.06h0.1c0-1.4-1.13-2.53-2.53-2.53c-1.4,0-2.53,1.14-2.53,2.53h0.11c-3.57,1.12-4.93,4.66-4.93,7.19
                            c0,2.63-1.32,7.02-3.44,9.52l0.55,1.19h7.72c0.02,1.38,1.14,2.5,2.53,2.5c1.39,0,2.51-1.12,2.53-2.5h7.72l0.55-1.19
                            c-2.12-2.5-3.44-6.89-3.44-9.52C18.8,7.73,17.43,4.18,13.86,3.06z M11.44,1.98c0.49,0,0.89,0.33,1.02,0.78
                            c-0.33-0.04-0.67-0.06-1.02-0.06c-0.36,0-0.7,0.02-1.02,0.06C10.55,2.31,10.95,1.98,11.44,1.98z M5.52,10.26
                            c0-2.11,1.24-6.11,5.91-6.11c4.68,0,5.92,4,5.92,6.11c0,1.44,0.37,3.35,1.01,5.25H4.51C5.16,13.6,5.52,11.69,5.52,10.26z
                            M11.44,22.02c-0.59,0-1.06-0.47-1.08-1.06h2.16C12.5,21.55,12.03,22.02,11.44,22.02z M20.25,19.52H2.62
                            c0.51-0.79,0.95-1.66,1.34-2.57h14.95C19.3,17.86,19.74,18.74,20.25,19.52z"
                        />
                    </g>
                    {countVal > 0 && (
                        <g id="Notification_Counter">
                            <svg height="100" width="100" overflow="visible">
                                <circle
                                    cx="26"
                                    cy="3"
                                    r={countVal < 100 ? "10" : "12"}
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="#F04C63"
                                />
                            </svg>
                            <text
                                fontSize="11px"
                                x={countVal < 10 ? "23px" : countVal < 100 ? "20px" : "17px"}
                                y="8px"
                                fill="white"
                            >
                                {countVal > 99 ? "99+" : countVal}
                            </text>
                        </g>
                    )}
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string; hoverFillColor: string }>`
    fill: ${(props): string => props.fillColor};
    :hover {
        fill: ${(props): string => props.hoverFillColor};
    }
`;
