import dayjs from "dayjs";
import React, { ReactNode } from "react";
import styled from "styled-components";

import RealTimeInsightTile from "../Tiles/RealTimeInsightTile";

import SVGIconUpdateFrequency from "../Icon/UpdateFrequency";

export interface Props {
    insightTileValues: Array<{
        title: string;
        progressBarTitle: string;
        progressBarColour?: string;
        fractionLabelColour?: string;
        currentValueTitle: string;
        currentValue: number;
        maxValueTitle: string;
        maxValue: number;
    }> | null;
    lastUpdated: Date;
}

export default class SidePanel extends React.Component<Props> {
    public render(): ReactNode {
        const { insightTileValues, lastUpdated } = this.props;

        const insightTileList = insightTileValues
            ?.filter((insightTileValues) => {
                return insightTileValues.maxValue !== 0;
            })
            .map((e, pos) => (
                <RealTimeInsightTile
                    key={pos.toString()}
                    title={e.title ?? "Occupancy"}
                    progressBarTitle={e.progressBarTitle ?? "Population"}
                    progressBarColour={e.progressBarColour ?? "#0099F8"}
                    fractionLabelColour={e.fractionLabelColour ?? "#0099F8"}
                    currentValueTitle={e.currentValueTitle ?? "NO. OF PEOPLE"}
                    currentValue={e.currentValue ?? 0}
                    maxValueTitle={e.maxValueTitle ?? "MAX OCCUPANCY"}
                    maxValue={e.maxValue ?? 1}
                />
            ));

        return (
            <Container>
                <BackgroundContainer>
                    <Background noTiles={insightTileList?.length || 1}>
                        <UpdateIndicatorContainer>
                            <UpdateIndicatorIcon>
                                <SVGIconUpdateFrequency fill="#9E9E9E" />
                            </UpdateIndicatorIcon>
                            <UpdateIndicatorLabel>Last Updated:</UpdateIndicatorLabel>
                            <UpdateIndicatorValue>{dayjs(lastUpdated).format("D MMM h:mm a")}</UpdateIndicatorValue>
                        </UpdateIndicatorContainer>
                        <InsightsContainer>{insightTileList}</InsightsContainer>
                    </Background>
                </BackgroundContainer>
            </Container>
        );
    }
}

const Container = styled.div`
    width: 377px;
    margin-left: 18.5px;
    transition: 0.5s;
`;

const BackgroundContainer = styled.div`
    position: absolute;
    width: 377px;
    top: 0px;
    bottom: 0px;
`;

const Background = styled.div<{ noTiles: number }>`
    width: 377px;
    min-height: ${(props): string => `calc(104px + 20px + ((187px + 19px) * ${props.noTiles}) + 19px + 20px - 2px)`};
    border: 1px solid #ececf1;
    background-color: #e9eef4;
    box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    transition: 0.5s;
    pointer-events: auto;
`;

const InsightsContainer = styled.div`
    /* This applies CSS to the element's children. */
    /* See: https://stackoverflow.com/a/6507081/699963 */
    > * {
        margin: 18px 0px 18px 0px;
    }
    margin: 20px 24px 20px 24px;
    overflow: hidden;
`;

const UpdateIndicatorContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 5rem 0px 0px 9rem;
`;

const UpdateIndicatorIcon = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 2px;
`;

const UpdateIndicatorLabel = styled.div`
    align-items: center;
    height: 16px;
    color: #9e9e9e;
    font-size: 13px;
    line-height: 16px;
    margin-right: 5px;
`;

const UpdateIndicatorValue = styled.div`
    height: 16px;
    color: #3e3e3e;
    font-size: 13px;
    line-height: 16px;
`;
