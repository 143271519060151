import React, { KeyboardEvent, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { useLocation } from "react-router-dom";
import { NavigationTab, ProfileTab } from "../../index";
import { StyledButton } from "./../NavigationTab/NavigationTab";
import { observer } from "mobx-react";
import { useStore } from "./../../../services";
import { TelstraLogo } from "./../../Common/TelstraLogo/Telstralogo";
import { useOuterClickNotifier } from "./../ProfileTab/OuterClickNotifierHook";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LockIcon from "@material-ui/icons/Lock";
import { Tooltip } from "../../../components";
import { SmartSpaces } from "../../../services/WebService/SmartSpaces";

/**
 * Tab paths to highlight
 */
type TabData = Array<SmartSpaces.WebApp.Features.Navigation>;

/**
 * NavigationBar props
 */
export interface Props {
    labelBold?: string;
    label?: string;
    onPerformLogout?: () => void;
    onClick?: () => void;
    tabData: TabData;
}

/**
 * Tooltip properties
 */
export interface TooltipData {
    heading: string;
    description: string;
}

/**
 * Tooltip open coordinates
 */
export interface TooltipCoordinates {
    top: number | undefined;
    right: number | undefined;
}

/**
 * Returns tab to highlight based on path
 */
const getTabToHighlight = (path: string, pathNames: TabData): number => {
    const basePath = `/${path.split("/")[1]}`;
    let tab = -1;

    pathNames.forEach((pathName, index) => {
        if (basePath === pathName.path) {
            tab = index;
        }
    });

    return tab;
};

const NavigationBar: React.FC<Props> = observer((props: Props) => {
    const store = useStore();
    const location = useLocation();

    const { tabData } = props;
    const availablePaths = tabData.filter((tab) => tab.enabled);
    const [selectedTab] = useState(getTabToHighlight(location.pathname, availablePaths));
    const [overflowMenuVisible, setOverflowMenuVisible] = useState(false);
    const [hoverItemIndex, setHoverItemIndex] = useState<number | undefined>();
    const [tooltipData, setTooltipData] = useState<string | undefined>();

    // used to track the position to which tooltip should open
    const [tooltipCoordinates, setTooltipCoordinates] = useState<TooltipCoordinates | undefined>();

    // React reference to handle outer clicks
    const innerRef = useRef<HTMLDivElement | null>(null);
    const overflowMenuItemRef = useRef<Array<HTMLDivElement | null>>([]);

    const handleOnPerformLogout = (): void => {
        store.user.signout();
    };

    // Get parts of the user name from UserStore
    const firstName = store.user.firstName;
    const lastName = store.user.lastName;

    // All enabled tabs
    const navigationTabs = tabData
        .filter((tab) => tab.enabled)
        .map((tab, index) => (
            <NavigationTab
                key={index}
                to={tab.path}
                textDisplay={tab.label}
                selected={selectedTab === index}
                textColor="#414141"
            />
        ));

    // Disabled tabs
    const overflowTabs = tabData
        .filter((tab) => !tab.enabled)
        .map((tab, index) => (
            <OverflowMenuItem
                key={index}
                onMouseEnter={() => {
                    handleMouseHover(index, tab.message);
                }}
                ref={(el) => (overflowMenuItemRef.current[index] = el)}
            >
                <OverflowMenuItemText>{tab.label}</OverflowMenuItemText>
                <LockIcon />
            </OverflowMenuItem>
        ));

    // tooltip display on disabled menu items
    const handleMouseHover = (index?: number, tooltipDetails?: string) => {
        setHoverItemIndex(index);
        setTooltipData(tooltipDetails);
        const updatedTooltipCoordinates: TooltipCoordinates = {
            top: undefined,
            right: undefined,
        };
        if (index !== undefined && overflowMenuItemRef.current[index]) {
            updatedTooltipCoordinates.top = overflowMenuItemRef.current[index]?.getBoundingClientRect().top;
            updatedTooltipCoordinates.right = overflowMenuItemRef.current[index]?.getBoundingClientRect().width;
        }
        setTooltipCoordinates(updatedTooltipCoordinates);
    };

    // Shorthand to show/hide the overflow menu
    const overflowMenuEnabled = !!overflowTabs.length;

    // Hide menu on Escape key and toggle it on Space key
    const handleOnKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            innerRef.current?.blur();
            setOverflowMenuVisible(false);
        } else if (event.key === " ") {
            event.preventDefault();
            event.stopPropagation();
            setOverflowMenuVisible(!overflowMenuVisible);
        }
    };

    const handleOverflowMenuButtonClick = () => {
        innerRef.current?.focus();
        setOverflowMenuVisible(!overflowMenuVisible);
    };

    // Handles clicks outside of this component
    // Memoized callback for optimized performance
    const handleOuterClick = useCallback(
        (event: MouseEvent) => {
            if (overflowMenuVisible) {
                setOverflowMenuVisible(false);
            }
        },
        [overflowMenuVisible],
    );

    // Register callback with hook
    useOuterClickNotifier(handleOuterClick, innerRef);

    const tooltipHeadingLabel = "This page is locked!";

    return (
        <>
            <ColourStrip />
            <BarBackground data-cy="navigation-bar" role="navigation">
                <LogoBackground>
                    <LogoImage>
                        <TelstraLogo />
                    </LogoImage>
                    <LogoText>
                        <b>{props.labelBold}</b>
                        {props.label}
                    </LogoText>
                </LogoBackground>
                <MainViewNavigationTabContainer>
                    <>{navigationTabs}</>
                </MainViewNavigationTabContainer>
                {overflowMenuEnabled && (
                    <OverflowMenuContainer tabIndex={0} onKeyDown={handleOnKey}>
                        <OverflowMenuButton onClick={handleOverflowMenuButtonClick}>
                            <MoreHorizIcon fontSize={"large"} />
                        </OverflowMenuButton>
                        <OverflowMenu
                            ref={innerRef}
                            isVisible={overflowMenuVisible}
                            onMouseLeave={() => handleMouseHover()}
                        >
                            {overflowTabs}
                        </OverflowMenu>
                        {tooltipCoordinates?.top && (
                            <TooltipContainer coordinates={tooltipCoordinates}>
                                <Tooltip display={hoverItemIndex === undefined ? false : true}>
                                    <b>{tooltipHeadingLabel}</b>
                                    <p>{tooltipData}</p>
                                </Tooltip>
                            </TooltipContainer>
                        )}
                    </OverflowMenuContainer>
                )}
                <ProfileTab
                    firstName={firstName}
                    lastName={lastName}
                    onPerformLogout={handleOnPerformLogout}
                    primaryColor="#282828"
                />
            </BarBackground>
        </>
    );
});

export default NavigationBar;

/**
 * Styling
 */
const ColourStrip = styled.div`
    height: 2px;
    background: linear-gradient(90deg, #0064d2 0%, #5cd6e0 31.99999999999998%, #5e50bf 99.99999999999999%);
    padding-left: 24px;
    align-items: center;
    ${media.mobile} {
        width: auto;
        min-height: 2px;
    }
`;

const BarBackground = styled.div`
    height: 56px;
    border: 1px solid #ececf1;
    background-color: #ffffff;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: visible;
    user-select: none;
    ${media.mobile} {
        display: none;
    }
    ${media.print} {
        display: none;
    }
    /* Ensure dropdown is shown above all other elements. */
    z-index: 100;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
`;

const LogoBackground = styled.div`
    height: 56px;
    width: 195px;
    background-color: #ffffff;
    padding-left: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 190px;
`;

const LogoImage = styled.div`
    padding-top: 12px;
`;

const LogoText = styled.div`
    height: 24px;
    width: 150px;
    color: #282828;
    font-size: 16px;
    line-height: 24px;
`;

const OverflowMenuContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const OverflowMenuButton = styled.div`
    ${StyledButton};
    padding-left: 16px;
    padding-right: 16px;
`;

const OverflowMenu = styled.div<{ isVisible: boolean }>`
    display: ${(props): string => (props.isVisible ? "block" : "none")};
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100px;
    min-height: 48px;
    overflow: auto;
    z-index: 1;
    top: 53px;
    right: 0;
    background-color: white;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    opacity: ${(props): number => (props.isVisible ? 1 : 0)};
    transition: opacity 0.2s;
`;

const OverflowMenuItem = styled.div`
    display: flex;
    padding: 12px 22px;
    cursor: pointer;
    color: #aaa;

    &:last-child:not(:first-child) {
        margin-bottom: 8px;
    }
`;

const OverflowMenuItemText = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-right: 16px;
    white-space: nowrap;
    font-size: 14px;
`;

const MainViewNavigationTabContainer = styled.div`
    display: flex;
    flex-grow: 1;
    a {
        color: black;
        text-decoration: none;
    }
`;

/**
 * position coordinates:
 * top: Hovered menu item's top position
 * right: Hovered menu item's width + tooltip's width - offset value to adjust tooltip
 */
const TooltipContainer = styled.div<{ coordinates: TooltipCoordinates }>`
    position: relative;
    top: ${(props): string => (props?.coordinates?.top ? props.coordinates.top + "px" : "unset")};
    right: ${(props): string => (props?.coordinates?.right ? props.coordinates.right + 270 - 10 + "px" : "unset")};
    z-index: 2;
`;
