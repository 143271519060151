import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

/**
 * NavigationTab props
 */
export interface Props {
    textDisplay?: string;
    textColor?: string;
    svgIcon?: React.ReactNode;
    to: string;
    selected?: boolean;
}

class NavigationTab extends React.Component<Props> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactNode {
        const Icon: React.ReactNode = this.props.svgIcon;

        const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): boolean => {
            let propagateEvent = true;

            if (this.props.selected) {
                event.preventDefault();
                propagateEvent = false;
            }
            return propagateEvent;
        };

        return (
            <StyledLink
                onClick={handleOnClick}
                to={this.props.to}
                selected={this.props.selected}
                textcolor={this.props.textColor}
            >
                {Icon && <TabIcon>{Icon}</TabIcon>}
                <TabText>{this.props.textDisplay}</TabText>
            </StyledLink>
        );
    }
}

export default NavigationTab;

/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const TabIcon = styled.div`
    margin-right: 6px;
    width: 15px;
    height: 18px;
    padding: 5px 6px 4px 6px;
`;

const TabText = styled.div`
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    text-align: center;
`;

export const StyledButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    user-select: none;

    text-decoration: none;
    cursor: pointer;
    :visited {
        text-decoration: none;
        color: none;
    }
    :hover {
        cursor: pointer;
        text-decoration: none;
        color: #0099f8;
    }
`;

const StyledLink = styled(Link)<{ selected?: boolean; textcolor?: string }>`
    ${StyledButton};
    color: ${(props): string | undefined => props.textcolor};
    border-bottom: ${(props): number => (props.selected ? 2 : 0)}px solid #0064d2;
`;
