import { makeAutoObservable } from "mobx";
import { msalInstance, login, logout } from "./../../services/Login/Msal";

class UserStore {
    private _name?: string;
    private _firstName?: string;
    private _lastName?: string;
    private _fullName?: string;
    private _initials?: string;
    private _email?: string;
    private _accountIdentifier?: string;

    constructor(name?: string, email?: string, token?: string) {
        this.setName(name);

        makeAutoObservable(this);
    }

    get name(): string {
        return this._name ?? "";
    }

    get firstName(): string {
        return this._firstName ?? "";
    }

    get lastName(): string {
        return this._lastName ?? "";
    }

    get fullName(): string {
        return this._fullName ?? "";
    }

    get initials(): string {
        return this._initials ?? "";
    }

    get email(): string {
        return this._email ?? "";
    }

    get token(): string {
        return this._accountIdentifier ?? "";
    }

    private setName(name = ""): void {
        const fullName = name.replace(/  +/g, " ").trim();
        const isNameInReverse = fullName.includes(",");
        const nameParts = (
            isNameInReverse
                ? fullName
                      .split(",")
                      .filter((t) => t)
                      .reverse()
                : fullName.split(" ").filter((t) => t)
        ).map((namePart) => namePart.trim());
        const [firstName, ...otherNames] = nameParts;

        this._name = fullName;
        this._firstName = firstName;
        this._lastName = otherNames[otherNames.length - 1];
        this._fullName = nameParts.join(" ");
        this._initials = `${firstName ? firstName[0] : ""}${
            otherNames && otherNames.length ? otherNames[otherNames.length - 1][0] : ""
        }`;
    }

    private updateUserDetails(): void {
        const account = msalInstance.getAccount();

        if (account) {
            const { accountIdentifier, idToken } = account;

            if (this._accountIdentifier !== accountIdentifier) {
                this.setName(idToken.name);
                this._email = Array.isArray(idToken.emails) ? idToken.emails[0] : idToken.emails;
                this._accountIdentifier = accountIdentifier;
            }
        }
    }

    public signin(domainHint: string, loginHint: string, callback?: () => void): void {
        login(domainHint, loginHint).then(() => {
            callback?.();
        });
    }

    public signout(callback?: () => void): void {
        logout().then(() => {
            callback?.();
        });
    }

    public isAuthenticated(): boolean {
        this.updateUserDetails();
        return msalInstance.getAccount() !== null;
    }

    static getAccessToken(): string {
        return sessionStorage?.getItem("msal.idtoken") || "";
    }
}

export default UserStore;
