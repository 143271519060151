import React, { FC, useState } from "react";
import styled from "styled-components";
import { media } from "../../../../config/Config";
import { validateEmail, ValidationResult } from "../../../../services/Login/Validation/EmailValidation";
import TextInput from "../../../Input/TextInput/TextInput";
import { ActionButton as LoginButton } from "../../../../components";

const helperTexts = {
    none: "", // blank message
    default: "Select to enter email",
    update: "Select to edit email",
    empty: "Please enter a valid email address",
    invalid: "This is not a valid email address",
    unauthorised: "This email is not authorised to access myWorkplace",
};

export type InputState = keyof typeof helperTexts;

export interface Props {
    initialValue: string;
    initialState: InputState;
    onSubmit: (email: string) => void;
}

const LoginForm: FC<Props> = (props: Props) => {
    const { initialValue, initialState, onSubmit } = props;

    const [email, setEmail] = useState(initialValue ?? "");
    const [inputState, setInputState] = useState<InputState>(initialState);

    const isError = (): boolean => ["empty", "invalid", "unauthorised"].includes(inputState);

    const handleOnChange = (value: string): void => {
        setInputState("none");
        setEmail(value);
    };

    const handleOnFocusChange = (focused: boolean): void => {
        // Don't change the helper text if there was an error
        // Wait until the user starts typing again...
        if (isError()) {
            return;
        }

        if (focused) {
            setInputState("none");
        } else if (email === "") {
            setInputState("default");
        } else {
            setInputState("update");
        }
    };

    const handleOnEnterKey = (): void => {
        handleOnSubmit();
    };

    const handleOnClick = (): void => {
        handleOnSubmit();
    };

    const handleOnSubmit = (): void => {
        const validationResult: ValidationResult = validateEmail(email);

        if (email === "") {
            setInputState("empty");
        } else if (validationResult.isValid === false) {
            setInputState("invalid");
        } else {
            setInputState("none");
            onSubmit(email);
        }
    };

    return (
        <Wrapper>
            <InputContainer>
                <TextInput
                    required
                    label="Your email"
                    value={email}
                    type="email"
                    helperText={helperTexts[inputState]}
                    isError={isError()}
                    onChange={handleOnChange}
                    onEnterKey={handleOnEnterKey}
                    onFocusChange={handleOnFocusChange}
                />
            </InputContainer>
            <ButtonContainer>
                <LoginButton label="Sign In" onClick={handleOnClick} />
            </ButtonContainer>
        </Wrapper>
    );
};

LoginForm.displayName = "LoginForm";

LoginForm.defaultProps = {
    initialValue: "",
    initialState: "default",
};

export default LoginForm;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${media.mobile} {
        align-items: center;
    }
`;

const InputContainer = styled.div``;

const ButtonContainer = styled.div`
    margin-top: 40px;
    ${media.mobile} {
        margin: 60px 5px 5px 5px;
    }
`;
