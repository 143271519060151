import * as React from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import Building from "../../Icon/Building";

/**
 * Props
 */
export interface Props {
    headerText?: string;
    headerLogo?: string;
    gradientColorLeft: string;
    gradientColorRight: string;
}

class BrandedHeader extends React.Component<Props> {
    /**
     * Checks if a logo exists and if it doesnt then use default.
     *
     * @memberof BrandedHeader
     */
    public getCompanyLogo = (): React.ReactNode => {
        if (!this.props.headerLogo) {
            return (
                <BuildingSVGSizer>
                    <Building />
                </BuildingSVGSizer>
            );
        } else if (typeof this.props.headerLogo !== "string") {
            return <SVGSizer>{this.props.headerLogo}</SVGSizer>;
        } else {
            return <CustomerInfoLogo draggable={false} src={this.props.headerLogo} />;
        }
    };

    public render(): React.ReactNode {
        return (
            <HeaderBackground
                leftGrad={this.props.gradientColorLeft}
                rightGrad={this.props.gradientColorRight}
                role="brandedHeader"
            >
                <CustomerInfoContentContainer>
                    <CustomerInfoLogoContainer data-cy="buildings-overview-page-customer-logo">
                        {this.getCompanyLogo()}
                    </CustomerInfoLogoContainer>
                    <CustomerInfoTextContentContainer>
                        <CustomerInfoTextName data-cy="buildings-overview-page-customer-name">
                            {this.props.headerText || ""}
                        </CustomerInfoTextName>
                    </CustomerInfoTextContentContainer>
                </CustomerInfoContentContainer>
            </HeaderBackground>
        );
    }
}

export default BrandedHeader;

const HeaderBackground = styled.div<{ leftGrad?: string; rightGrad?: string }>`
    height: 112px;
    background: linear-gradient(
        90deg,
        ${(props): string | undefined => props.leftGrad} 0%,
        ${(props): string | undefined => props.rightGrad} 100%
    );
    margin-bottom: 24px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    ${media.mobile} {
        width: auto;
        min-height: 112px;
    }
`;

const CustomerInfoContentContainer = styled.div`
    height: 57px;

    display: flex;
    flex-direction: row;
`;

const CustomerInfoLogoContainer = styled.div<{ padding?: number }>`
    width: 57px;
    height: 57px;
    min-width: 57px;
    min-height: 57px;
    overflow: hidden;
    ${(props): string => (props.padding ? "padding: 9px;" : "")}
    box-sizing: border-box;

    border-radius: 4px;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
`;

const CustomerInfoLogo = styled.img`
    max-height: 100%;
    max-width: 100%;
    user-select: none;
`;

const CustomerInfoTextContentContainer = styled.div`
    margin-left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const CustomerInfoTextName = styled.div`
    height: 39px;
    color: white;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    flex-basis: 100%;
    ${media.mobile} {
        font-size: 24px;
    }
`;

const SVGSizer = styled.div`
    width: 31.67px;
    height: 38px;
`;

const BuildingSVGSizer = styled.div`
    width: 31.67px;
    height: 38px;
`;
