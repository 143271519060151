import React, { FC } from "react";
import styled from "styled-components";
import ProgressBar from "../../Tiles/ProgressBar/ProgressBar";
export interface Props {
    label: string;
    capacity: number | undefined;
    population: number | undefined;
    bookable?: boolean;
}
const PopulationCard: FC<Props> = (props: Props) => {
    const { label, capacity, population, bookable } = props;
    const bookablePercent = (population: number | undefined, capacity: number | undefined): number => {
        if (typeof population === "number" && typeof capacity === "number") {
            const bookable = Math.round((population / capacity) * 100);
            return bookable < 100 ? bookable : 100;
        } else return 0;
    };
    return (
        <Container>
            {capacity && (
                <PopulationContainer labelSize={12}>
                    <ProgressBarLabel>{label}</ProgressBarLabel>
                    <ProgressBar
                        progress={bookablePercent(population, capacity) / 100}
                        colour={bookable === false ? "#d5d8dc" : "#38822A"}
                        barHeight="8px"
                        angled={true}
                    />
                    <LeftText>
                        <PopulationCount>{bookablePercent(population, capacity)}</PopulationCount>
                        <LabelContainer>% bookable</LabelContainer>
                    </LeftText>
                    <RightText>
                        <PopulationCapacityContainer>
                            {population} of {capacity}
                        </PopulationCapacityContainer>
                    </RightText>
                </PopulationContainer>
            )}
        </Container>
    );
};
export default PopulationCard;

const ProgressBarLabel = styled.div`
    font-weight: 700;
    color: #333333;
    text-align: left;
    line-height: normal;
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 6px;
`;

const LabelContainer = styled.div`
    display: inline-block;
    padding-top: 3px;
`;

const PopulationCount = styled.div`
    display: inline-block;
    padding-top: 3px;
`;

const PopulationContainer = styled.div<{ labelSize?: number }>`
    bottom: 4px;
    font-size: ${(props): string => (props.labelSize ? props.labelSize + "px" : "16px")};
    min-width: 214px;
    padding-bottom: 6px;
    padding-left: 20px;
`;

const PopulationCapacityContainer = styled.div`
    display: inline-block;
    padding-top: 3px;
`;

const LeftText = styled.div`
    display: inline-block;
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
`;

const RightText = styled.div`
    float: right;
    max-width: 85px;
    overflow: hidden;
    white-space: nowrap;
`;

const Container = styled.div`
    display: flex;
    width: 200px;
    height: 72px;
    align-items: center;
    justify-content: center;
    top: 20px;
    flex-grow: 1;
`;
