import React, { FC } from "react";
import styled, { css } from "styled-components";
import { media } from "../../../config/Config";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Toggle from "../../Button/ToggleButton/Toggle";
export interface Props {
    assetName: string;
    bookable: boolean;
    onChangeBookable: (bookable: boolean) => void;
    onEditAsset: () => void;
    onCloneAsset?: () => void;
    assetFeatures?: Array<Admin.ResourceFeature>;
    resourceType: string;
}

const AssetListItem: FC<Props> = (props: Props) => {
    const { assetName, bookable, onChangeBookable, onEditAsset, onCloneAsset, assetFeatures, resourceType } = props;
    // currently only edit desk is in scope
    const isAssetEditable: boolean = resourceType === "Desk" || resourceType === "Room";
    const isClone = resourceType === "Desk";
    const isFeaturesAvailable = !!assetFeatures?.length;
    const featuresLength = assetFeatures?.length;

    const features = assetFeatures?.map((feature, index) => {
        const { optionName, icon: featureIcon } = feature;

        const element =
            index > 1 && featuresLength && index + 1 !== featuresLength ? null : index > 1 &&
              featuresLength &&
              index + 1 === featuresLength ? (
                // Display count of additional features in case of more than 2 features
                <AdditionalFeatureCount key={index}>+{featuresLength - 2}</AdditionalFeatureCount>
            ) : (
                <AssetFeatureElement key={index}>
                    {featureIcon && (
                        <FeatureIconContainer dangerouslySetInnerHTML={{ __html: featureIcon }}></FeatureIconContainer>
                    )}
                    {optionName}
                </AssetFeatureElement>
            );
        return element;
    });

    return (
        <FlexContainer aria-label={assetName} isAssetEditable={isAssetEditable}>
            <div>
                {isAssetEditable && (
                    <EditAssetContainer>
                        <EditIconButton onClick={onEditAsset}>
                            <EditIcon fontSize="small" aria-label="edit asset" />
                        </EditIconButton>
                    </EditAssetContainer>
                )}
                {isClone && (
                    <CloneAssetContainer style={{ paddingTop: 0 }}>
                        <EditIconButton onClick={onCloneAsset}>
                            <FileCopyOutlinedIcon fontSize="small" aria-label="clone asset" />
                        </EditIconButton>
                    </CloneAssetContainer>
                )}
            </div>
            <EditTileContainer isAssetEditable={isAssetEditable}>
                <AssetName isAssetEditable={isAssetEditable}>{assetName}</AssetName>
                {isFeaturesAvailable && <AssetFeaturesContainer>{features}</AssetFeaturesContainer>}
            </EditTileContainer>

            <ToggleContainer>
                <Toggle
                    id={assetName}
                    startEnabled={bookable}
                    readonly={resourceType === "Room"}
                    toggleOnText="Bookable"
                    toggleOffText="Not Bookable"
                    enabledStateColour="#38822b"
                    offStateColour="#a4393e"
                    onChange={onChangeBookable}
                />
                {resourceType === "Room" && <RoomLabel>{"Office 365 status"}</RoomLabel>}
            </ToggleContainer>
        </FlexContainer>
    );
};

export default AssetListItem;

const AssetName = styled.div<{ isAssetEditable: boolean }>`
    padding-left: ${(props) => (props.isAssetEditable ? "10px" : "46px")};
    padding-top: 17px;
    font-size: 12px;
    font-weight: 700;
`;

export const EditAssetContainer = styled.div`
    padding: 16px 0 0 25px;
    ${media.tablet} {
        padding: 16px 0 0 15px;
    }
`;

export const EditIconButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
`;

const FeatureIconContainer = styled.span`
    vertical-align: middle;
    margin-right: 6px;
    svg {
        width: 12px;
        height: 12px;
    }
`;

const AssetFeaturesContainer = styled.div`
    width: 370px;
    padding-left: 10px;
    margin-top: 6px;
    font-size: 12px;
    white-space: nowrap;
`;

const StyledAssetFeature = css`
    margin-right: 10px;
    float: left;
    background-color: #f1f5f9;
    padding: 6px;
    border: none;
`;

const AssetFeatureElement = styled.div`
    ${StyledAssetFeature}
    border-radius: 4px;
`;

const AdditionalFeatureCount = styled.div`
    ${StyledAssetFeature}
    border-radius: 16px;
    font-weight: 700;
    font-size: 11px;
`;

const FlexContainer = styled.div<{ isAssetEditable: boolean }>`
    display: flex;
    height: ${(props) => (props.isAssetEditable ? "72px" : "48px")};
    background: #ffffff;
    max-width: 527px;
    min-width: 250px;
`;

const EditTileContainer = styled.div<{ isAssetEditable: boolean }>`
    width: 460px;
    max-width: 460px;
    min-width: 100px;
    height: ${(props) => (props.isAssetEditable ? "72px" : "48px")};
    white-space: nowrap;
    overflow: hidden;
`;

const ToggleContainer = styled.div`
    max-width: 100px;
    align-items: center;
    min-width: 110px;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-grow: 1;
    ${media.tablet} {
        width: 40px;
        min-width: 90px;
    }
`;
const RoomLabel = styled.div`
    color: #9e9e9e;
    font-size: 12px;
    margin-top: -15px;
    padding-left: 15px;
`;

const CloneAssetContainer = styled(EditAssetContainer)`
    padding-top: 0;
`;
