import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    label: string;
    primaryColour: string;
    textColour: string;
    isDisabled?: boolean;
    buttonWidth?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default class PopupButton extends React.Component<Props> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
    }

    static defaultProps = {
        label: "Button",
        primaryColour: "#d9001b",
        textColour: "#ffffff",
        isDisabled: false,
    };

    public render(): ReactNode {
        const { primaryColour, onClick, isDisabled, buttonWidth } = this.props;
        return (
            <ButtonContainer>
                <ButtonDiv
                    primaryColour={primaryColour}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    buttonWidth={buttonWidth}
                >
                    <ButtonText textColour={this.props.textColour}>{this.props.label}</ButtonText>
                </ButtonDiv>
            </ButtonContainer>
        );
    }
}

/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */
const ButtonText = styled.span<{ textColour: string }>`
    color: ${(props): string => props.textColour};
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    text-align: center;
    box-sizing: border-box;
`;

const ButtonDiv = styled.button<{ primaryColour: string; isDisabled?: boolean; buttonWidth?: string }>`
    width: ${(props): string => (props.buttonWidth ? props.buttonWidth : "140px")};
    height: 40px;
    border-radius: 2px;
    background-color: ${(props): string => (props.isDisabled ? "#0064d233" : props.primaryColour)};
    box-sizing: border-box;
    border: none;
    &:hover {
        cursor: ${(props): string => (props.isDisabled ? "not-allowed" : "pointer")};
    }

    :focus {
        border: none;
        outline: none;
    }
`;

const ButtonContainer = styled.div`
    padding: 2px;
    :focus-within {
        border: 2px solid #0064d2;
        border-radius: 3px;
        margin-bottom: -2px;
        margin-left: -2px;
    }
`;
