import * as React from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class Floor extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconFloor
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): React.ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 25 24" fillColor={this.fillColor()}>
                <g
                    id="3308---I-want-to-see-the-overall-building-efficiency-metrics-with-key-stats"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Building-View---Occupied"
                        transform="translate(-78.000000, -566.000000)"
                        fill={this.fillColor()}
                        fillRule="nonzero"
                    >
                        <g id="Group-3" transform="translate(24.000000, 279.000000)">
                            <g id="Building-List-Floor-List/Default" transform="translate(0.000000, 244.000000)">
                                <g
                                    id="Building-Name-&amp;-Address-Floor-Name"
                                    transform="translate(48.000000, 0.000000)"
                                >
                                    <g id="Building-Name-&amp;-Address">
                                        <g id="Icon" transform="translate(0.000000, 37.000000)">
                                            <path
                                                d="M18.7194907,6.05694234 L30.7565277,12.7263988 C31.0811574,12.906269 31.0811574,13.3854235 30.7565277,13.5652937 L27.214,15.527 L30.7565225,17.4902934 C31.0811568,17.6701606 31.0811598,18.1493197 30.7565277,18.3291912 L27.052,20.381 L30.7565679,22.4347286 C31.0811622,22.6146216 31.0811393,23.0937411 30.7565277,23.2736012 L18.7194907,29.9430577 C18.5824646,30.0189808 18.4175354,30.0189808 18.2805093,29.9430577 L6.2434723,23.2736012 C5.91883108,23.0937246 5.91884559,22.614548 6.2434977,22.4346923 L9.948,20.382 L6.2434723,18.3291912 C5.9188402,18.1493197 5.91884319,17.6701606 6.24347754,17.4902934 L9.785,15.527 L6.2434723,13.5652937 C5.91884257,13.3854235 5.91884257,12.906269 6.2434723,12.7263988 L18.2805093,6.05694234 C18.4175354,5.98101922 18.5824646,5.98101922 18.7194907,6.05694234 Z M26.075,20.923 L18.7195233,24.9986295 L18.6344026,25.0350815 L18.5452375,25.0533123 L18.4547814,25.0533142 C18.3946094,25.0472425 18.3353299,25.0290215 18.2805153,24.9986509 L10.9249875,20.9232577 L7.43952893,22.8541851 L18.5,28.9825479 L29.5606169,22.8541042 L26.075,20.923 Z M26.238,16.068 L20.233,19.395 L18.7194936,20.2347485 L18.634375,20.2711927 L18.634375,20.2711927 L18.5452375,20.2894148 L18.5452375,20.2894148 L18.4547619,20.2894147 C18.3945932,20.2833407 18.3353175,20.2651186 18.2805064,20.2347485 L16.766,19.395 L10.761,16.068 L7.43948407,17.9097502 L11.1435171,19.9622212 L11.1444847,19.9627558 L17.119,23.273 L18.5,24.0381379 L25.8554767,19.9618245 L25.862,19.958 L29.5605159,17.9097502 L26.238,16.068 Z M18.5,7.01745209 L7.43947242,13.1458462 L10.9815306,15.1083492 L10.9815306,15.1083492 L18.5,19.2742404 L29.5605276,13.1458462 L18.5,7.01745209 Z"
                                                id="Icon-Floor"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
