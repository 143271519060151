import React, { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Filters } from "./SearchOutcomes";
import { UserSearchBar } from "./UserSearchBar";
import { useStore } from "../../../../services";
import UserChipContainer from "../../../../components/UserChipContainer/UserChipContainer";
import UserSelectionWarning from "./UserSelectionWarning";
import Throbber from "../../../../components/Common/Throbber/Throbber";
import { StyledPublishIcon, UploadButton } from "../../Floors/FloorPlan/FloorPlan";
import { Link } from "react-router-dom";

export interface UserModel {
    userId: number;
    name: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    allowedCarPark?: boolean;
}
export enum SearchPrompt {
    empty = "",
    searchPrompt = "Search by name",
    searchProgress = "Searching...",
    searchNoMatch = `We didn't find any matches`,
    searchFailed = `Sorry, an error has occurred`,
}

interface Props {
    handleSave: (value: Array<UserModel>) => void;
    onUpload: (csv: File | null) => void;
}

const CarparkUser: FC<Props> = observer(({ handleSave, onUpload }) => {
    const sampleTemplate = "/assets/SampleTemplates/CarparkUsers/template.csv";

    const store = useStore();
    const selectionLimit = 10;
    const messageText = `No more people can be added as you have reached the maximum of ${selectionLimit}`;
    const minCharsToStartSearch = 2;
    const [selectedUsersList, setSelectedUsersList] = useState<Array<UserModel>>([]);
    const [searchResults, setSearchResults] = useState<Array<UserModel>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [clonedList, setClonedList] = useState<Array<UserModel>>([]);
    const [searchString, setSearchString] = useState<string>();
    const [invalidMessage, setInvalidMessage] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const [prompt, setPrompt] = useState(SearchPrompt.empty);
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setSearchResults([]);
        if (searchString === undefined) {
            return;
        }

        if (searchString.length < minCharsToStartSearch) {
            setPrompt(SearchPrompt.searchPrompt);
        } else {
            setPrompt(SearchPrompt.searchProgress);

            store.subscription.management
                .getUsers(searchString)
                .then((res) => {
                    if (res.users.length) {
                        setPrompt(SearchPrompt.empty);
                        setSearchResults(res.users);
                    } else {
                        setPrompt(SearchPrompt.searchNoMatch);
                        setSearchResults([]);
                    }
                })
                .catch(() => {
                    setPrompt(SearchPrompt.searchFailed);
                });
        }
    }, [searchString]);

    const onChange = (userInput: string): void => {
        // Trim spaces and leading commas
        // then remove repeating allowable special characters
        // then remove all other special characters
        // and finally replace comma with space character.
        const newSearchString = userInput
            .replace(/(^[,\s]+)|([,\s]+$)/g, "")
            .replace(Filters.repeating, "$1")
            .replace(Filters.removable, "")
            .replace(Filters.replaceWithSpace, " ")
            .split(" ")
            .filter((f) => f.length > 1)
            .join(" ");

        if (newSearchString !== searchString) {
            setSearchString(newSearchString);
        }
    };
    const onSearchFocus = (): void => {
        if (!searchResults.length) {
            setPrompt(SearchPrompt.searchPrompt);
        }
    };

    const onSearchReset = (): void => {
        setSearchString("");
    };

    const onSelect = (userInput: UserModel): void => {
        setIsLoading(true);
        //Clear upload details
        setFileName("");
        setInvalidMessage("");
        onUpload(null);

        //  Clear search input and search results
        onSearchReset();
        store.subscription.management
            .getUserPermission(userInput.userId)
            .then((res) => {
                userInput.allowedCarPark = res[0].allowedCarPark;
                setSelectedUsersList((prevUsersState) => [...prevUsersState, userInput]);
            })
            .catch(() => {
                console.log("error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSelection = (userList: Array<UserModel>): void => {
        handleSave(userList);
        setClonedList(userList);
        setSelectedUsersList(userList);
    };

    // Remove selected user
    const onRemove = (removedUser: string | number): void => {
        const filteredList = selectedUsersList.filter(({ userId }) => userId !== removedUser);
        const filteredSelList = clonedList.filter(({ userId }) => userId !== removedUser);
        setSelectedUsersList(filteredList);
        handleSave(filteredSelList);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const fileUploaded = target?.files ? target?.files[0] : null;
        setInvalidMessage("");
        setFileName("");
        if (fileUploaded) {
            if (fileUploaded.type !== "text/csv") {
                setInvalidMessage("Invalid file type");
                onUpload(null);
            } else if (fileUploaded.size > 500000) {
                setInvalidMessage("File must be below 500 KB");
                onUpload(null);
            } else {
                setFileName(fileUploaded.name);
                setSelectedUsersList([]);
                onUpload(fileUploaded);
            }
        }
        target.value = "";
    };

    const uploadCSVHandler = () => {
        hiddenFileInput.current?.click();
    };

    return (
        <>
            <UploadWrapper>
                <UploadButton onClick={uploadCSVHandler}>
                    <ExtendedPublishIcon htmlColor="#001e82" />
                    <UploadText>Upload CSV</UploadText>
                </UploadButton>
                <WarningText>{invalidMessage}</WarningText>
                <span>{fileName}</span>
            </UploadWrapper>
            <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
            />
            <DownloadTemplate>
                <Link to={sampleTemplate} target="_blank" download>
                    Download CSV template
                </Link>
            </DownloadTemplate>

            {selectedUsersList.length >= selectionLimit ? (
                <UserSelectionWarning message={messageText} />
            ) : (
                <>
                    <HintText>Search by names</HintText>
                    <UserSearchBar
                        prompt={prompt}
                        debounce={500}
                        results={searchResults}
                        selectedUsersList={selectedUsersList}
                        onChange={onChange}
                        onFocus={onSearchFocus}
                        onReset={onSearchReset}
                        onSelect={onSelect}
                        resetInput={searchString ? false : true}
                    />
                </>
            )}
            {selectedUsersList.length > 0 && (
                <UserChipContainer
                    userData={selectedUsersList}
                    onRemove={(user) => onRemove(user)}
                    onSelect={handleSelection}
                />
            )}

            {isLoading && (
                <ThrobberWrapper>
                    <Throbber width={45} height={45} />
                </ThrobberWrapper>
            )}
        </>
    );
});

export default CarparkUser;

export const HintText = styled.span`
    color: #484848;
    margin-top: 10px;
    font-size: 14px;
`;

export const UserChipHolder = styled.div`
    @media (max-width: 600px) {
        padding-top: 10px;
    }

    @media (min-width: 601px) {
        padding-top: 17px;
    }
`;

export const ThrobberWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const UploadText = styled.span`
    margin-top: 5px;
`;
export const UploadWrapper = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    border: 1px solid #dddd;
    justify-content: space-between;
    padding: 20px 20px 20px 10px;
`;

export const ExtendedPublishIcon = styled(StyledPublishIcon)`
    margin-bottom: -5px;
`;

export const WarningText = styled.span`
    color: #b00020;
`;

export const DownloadTemplate = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 20px;
`;
