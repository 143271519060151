import React, { useEffect, useState } from "react";
import { Checkbox } from "../Checkbox/Checkbox";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
export type OptionType = {
    value: number;
    label: string;
    checked?: boolean;
};

export interface Props {
    options: Array<OptionType>;
    onChange: (value: Array<OptionType>) => void;
    onRemove: (e: number | string) => void;
}

export const CheckboxGroup = ({ options, onChange, onRemove }: Props) => {
    const [items, setItems] = useState<Array<OptionType>>(options);
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setItems(options);
    }, [options]);

    const handleChange = (e: string) => {
        items.map((item) => {
            if (JSON.stringify(item.value) === e) {
                item.checked = !item.checked;
            }
            return item;
        });
        const multiChecked = items.filter((d) => d.checked).length === items.length;
        setIsChecked(multiChecked);
        onChange(items);
    };

    const handleMultiCheck = () => {
        items.map((item) => (item.checked = !isChecked));
        setIsChecked(!isChecked);
        onChange(items);
    };
    return (
        <ItemsWrapper>
            <CheckBoxWrapper>
                <Checkbox value={"All"} label="Select All" id={0} onChange={handleMultiCheck} checked={isChecked} />
            </CheckBoxWrapper>

            {items.map(({ label, value, checked }, index) => (
                <CheckBoxWrapper key={index}>
                    <Checkbox value={value} label={label} id={index} onChange={handleChange} checked={checked} />
                    <CloseButton aria-label={"Remove " + label} onClick={() => onRemove(value)}>
                        <Close fontSize="small" />
                    </CloseButton>
                </CheckBoxWrapper>
            ))}
        </ItemsWrapper>
    );
};

const CloseButton = styled.button`
    display: inline-flex;
    align-items: center;
    color: #0064d2;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-right: 21px;
    align-self: center;
    padding: 0px;
    background: transparent;
    border: 0;
    cursor: pointer;
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #dddd;
    padding: 10px 20px;
`;

const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
`;
