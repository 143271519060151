import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

export interface Option {
    label: string;
    value: string;
}

export interface Props {
    name: string;
    options: Array<Option>;
    width?: string;
    defaultSelection?: Array<string>;
    onChange: (values: Array<string>) => void;
}

const CheckboxGroup: React.FC<Props> = (props: Props) => {
    const { name, onChange, options, width, defaultSelection } = props;

    const [selectedOptions, setSelectedOptions] = useState<Array<string>>(defaultSelection || []);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { checked, value },
        } = e;
        const selectedOptionsCopy = [...selectedOptions];
        if (checked) {
            selectedOptionsCopy.push(value);
        } else {
            const indexToRemove = selectedOptionsCopy.findIndex((selectedOption) => selectedOption === value);
            selectedOptionsCopy.splice(indexToRemove, 1);
        }
        setSelectedOptions(selectedOptionsCopy);
        onChange(selectedOptionsCopy);
    };

    return (
        <FieldSet>
            {options &&
                options.map((option, index) => {
                    const { label, value } = option;
                    return (
                        <CheckboxWrapper key={index} width={width}>
                            <Label>
                                <LabelText>{label}</LabelText>
                                <Input
                                    name={name}
                                    type="checkbox"
                                    checked={selectedOptions?.includes(value)}
                                    value={value}
                                    onChange={onChangeHandler}
                                />
                                <StyledCheckbox />
                            </Label>
                        </CheckboxWrapper>
                    );
                })}
        </FieldSet>
    );
};

export default CheckboxGroup;

const FieldSet = styled.fieldset`
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
`;

const Label = styled.label`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin: 14px 16px 0 0;
    width: fit-content;
    cursor: pointer;

    &:hover {
        outline: none;
        border-color: #0064d2;
    }

    &:focus-within:focus-visible {
        outline: 2px #0064d2 solid;
        outline-offset: 6px;
        border-radius: 4px;
    }
`;

const Input = styled.input.attrs({ type: "checkbox" })`
    opacity: 0;
`;

const LabelText = styled.span`
    &:hover {
        color: #0064d2;
    }
`;

const StyledCheckbox = styled.span`
    background: #ffffff;
    border-radius: 3px;
    border: 2px solid #333333;
    width: 1.1rem;
    height: 1.1rem;
    transition: all 250ms ease;

    ${Input}:checked ~ & {
        background-color: #0064d2;
        border-color: #0064d2;
    }

    &:after {
        content: "";
        position: relative;
        display: none;
    }

    ${Input}:checked ~ &:after {
        display: block;
    }

    &:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    ${Input}:focus + & {
        outline: none;
    }

    ${Input}:hover + & {
        outline: none;
        border-color: #0064d2;
    }
`;

const CheckboxWrapper = styled.div<{ width?: string }>`
    width: ${(props): string => (props.width ? props.width : "unset")};
`;
