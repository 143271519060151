import React, { useEffect, useState, FC } from "react";
import styled from "styled-components";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    label: string;
    primaryColour: string;
    hoverColour: string;
    onClick: () => void;
}

const ExportButton: FC<Props> = (props: Props) => {
    const [isExportReady, setExportReady] = useState(false);

    useEffect(() => {
        window.addEventListener("onExportsReady", exportReady);

        return (): void => {
            window.removeEventListener("onExportsReady", exportReady);
        };
    }, [isExportReady]);

    const exportReady = (): void => {
        setExportReady(true);
    };
    return (
        <ButtonDiv primaryColour={props.primaryColour} hoverColour={props.hoverColour} onClick={props.onClick}>
            <ButtonText>{props.label}</ButtonText>
            <PictureAsPdfIcon fontSize="medium" />
        </ButtonDiv>
    );
};

export default ExportButton;

ExportButton.defaultProps = {
    label: "Export PDF",
    primaryColour: "#001e82",
    hoverColour: "#0064D2",
    onClick: (): void => undefined,
};

/**
 * Styled components at the bottom of the file, this is the definition of the css stylings.
 * Note that styled components are able to pass in props to define styling.
 */

const ButtonText = styled.span`
    font-size: 14px;
    letter-spacing: -0.41px;
    text-align: center;
    padding-right: 10px;
`;

const ButtonDiv = styled.button<{ primaryColour: string; hoverColour: string }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: 6px 14px;
    box-sizing: border-box;
    line-height: 24px;

    border: none;
    cursor: pointer;

    color: ${(props): string => props.primaryColour};
    background-color: transparent;

    animation: fadeIn ease 0.5s;
    &:hover,
    &:focus {
        color: ${(props): string => props.hoverColour};
        fill: ${(props): string => props.hoverColour};
        border-color: ${(props): string => props.hoverColour};
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
