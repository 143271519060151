import React, { ReactNode, PureComponent } from "react";
import styled from "styled-components";
import { Breadcrumb } from "../../services/Store/UIStore";
import { BreadCrumbNavigationBar } from "../../components";
import { media, config } from "../../config/Config";
import DropDown from "../../components/List/DropDown/DropDown";
import { Link } from "react-router-dom";

interface Props {
    breadcrumbs: Array<Breadcrumb>;
    summaries: ReactNode;
    list: ReactNode;
    selectListItems: Array<string>;
    onClickSelect: (selected: string) => void;
    disableDropdown?: boolean;
    displayBanner?: boolean;
    bannerComp?: ReactNode;
    displaySummaries?: boolean;
    buildingId?: number;
    floorId?: number;
}

class Management extends PureComponent<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): ReactNode {
        const {
            breadcrumbs,
            summaries,
            list,
            selectListItems,
            onClickSelect,
            disableDropdown,
            displayBanner,
            bannerComp,
            displaySummaries,
            buildingId,
            floorId,
        } = this.props;

        return (
            <PortalLayout>
                <BreadcrumbsContainer>
                    <BreadCrumbNavigationBar breadcrumbs={breadcrumbs} />
                </BreadcrumbsContainer>
                <PageContainer>
                    <LeftNavigation>{/* Left navigation placeholder. Hidden for now. */}</LeftNavigation>
                    <PageLayout maxWidth={config.breakpoints.tablet} minWidth={config.breakpoints.mobile}>
                        {displayBanner && <BannerContainer>{bannerComp}</BannerContainer>}
                        <LocationBarContainer>
                            {displaySummaries && (
                                <DropDown
                                    listItems={selectListItems}
                                    onClick={onClickSelect}
                                    disable={disableDropdown}
                                />
                            )}
                            {floorId && (
                                <FloorplanLink>
                                    <StyledLink
                                        to={`/building-management/buildings/${buildingId}/floors/${floorId}/floorplan`}
                                    >
                                        Manage floorplan
                                    </StyledLink>
                                </FloorplanLink>
                            )}
                        </LocationBarContainer>
                        <SummaryContainer summariesVisible={displaySummaries}>{summaries}</SummaryContainer>
                        <ListContainer>{list}</ListContainer>
                    </PageLayout>
                </PageContainer>
            </PortalLayout>
        );
    }
}

const PortalLayout = styled.div`
    height: auto;
    flex-direction: column;
    user-select: none;
    background: #f4f4f4;
`;

const BreadcrumbsContainer = styled.div`
    box-sizing: border-box;
    padding: 24px 20px;
`;

const PageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LeftNavigation = styled.div``;

const PageLayout = styled.div<{ minWidth?: number; maxWidth?: number }>`
    width: ${(props): string => props.maxWidth + "px"};
    ${media.tablet} {
        width: ${(props): string => props.minWidth + "px"};
    }
`;

const LocationBarContainer = styled.div`
    height: 28px;
    position: relative;
    z-index: 3;
    display: flex;
`;

const SummaryContainer = styled.div<{ summariesVisible?: boolean }>`
    margin-top: ${(props): string => (props.summariesVisible ? "30px" : "")}
    height: ${(props): string => (props.summariesVisible ? "200px" : "")};
`;

const ListContainer = styled.div`
    margin: 72px auto;
`;

const BannerContainer = styled.div`
    margin-top: 18px;
    margin-bottom: 64px;
`;

const FloorplanLink = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 50%;
    flex: 1;
`;

const StyledLink = styled(Link)`
    font-weight: 400;
    color: #001e82;
`;

export default Management;
