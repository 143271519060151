import React from "react";
import { Dashboard } from "../../../layouts";

const BlankPage: React.FC = () => {
    return (
        <Dashboard>
            <></>
        </Dashboard>
    );
};

export default BlankPage;
