import { useContext } from "react";
import { appContext } from "./../../components";
import AppConfig from "./AppConfig";

/**
 * useStore hook to provide easy access to the store
 */
const useConfig = (): AppConfig => useContext(appContext).config;

export default useConfig;
