export interface ValidationResult {
    isValid: boolean;
    message: string;
}

export const validateEmail = (email: string): ValidationResult => {
    let result: ValidationResult = {
        isValid: true,
        message: "",
    };

    // Blank input check.
    const emptyEmailCheck = email.trim();
    if (emptyEmailCheck.length === 0) {
        result = {
            isValid: false,
            message: "Enter an email address",
        };

        return result;
    }

    // Validate that the email is in the correct format.
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
        result = {
            isValid: false,
            message: "This is not a valid email address",
        };
    }

    return result;
};
