import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { CounterButton } from "../index";
import ConfigurePanelCards from "./ConfigurePanelCards";

export interface Props {
    maxFloorCapacity: number;
}

const ConfigurePanel: FC<Props> = (props: Props) => {
    const { maxFloorCapacity } = props;

    const configureFloor = (): ReactNode => {
        return (
            <ConfigurePanelCards cardHeader="Configure Floor">
                <FloorCapacity>
                    <CurrentCapacity>{maxFloorCapacity}</CurrentCapacity>
                    Current Maximum floor capacity
                </FloorCapacity>
                <SetCapacity>
                    <Label>Set Maximum floor capacity</Label>
                    <CounterButton value={maxFloorCapacity} />
                </SetCapacity>
                <Line fill="#000000" />
            </ConfigurePanelCards>
        );
    };
    return (
        <ConfigurePanelContainer>
            <ConfigurePanelCards cardHeader="Floor summary">
                <FloorSummary />
            </ConfigurePanelCards>
            {configureFloor()}
        </ConfigurePanelContainer>
    );
};

export default ConfigurePanel;

const ConfigurePanelContainer = styled.div`
    margin-left: 20px;
    width: 377px;
    border: 1px solid #ececf1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    transition: 0.5s;
    box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.15);
    margin-top: 67px;
    overflow-y: auto;
`;

const FloorCapacity = styled.div`
    height: 20px;
    padding: 20px 0px 20px 20px;
`;

const CurrentCapacity = styled.h3`
    margin: 5px 0px;
`;

const SetCapacity = styled.div`
    padding: 20px;
`;

const Label = styled.div`
    margin: 15px 0px;
`;

const FloorSummary = styled.div`
    height: 100px;
`;

const Line = styled.hr<{ fill: string }>`
    background-color: ${(props): string => props.fill};
    margin: 0px;
    height: 0.5px;
`;
