import EnvConfig from "./EnvConfig";

export interface AppConfiguration {
    [key: string]: string | number | boolean;
}

class AppConfig {
    view: AppConfiguration = {};

    ui: AppConfiguration = {
        ConfigToggle: false,
    };

    route: AppConfiguration = {
        homePath: "/home",
    };

    constructor() {
        if (EnvConfig.enableExperimentalFeatures()) {
            this.ui.ConfigToggle = false;
        }
    }

    /**
     * Check a given route or an array of routes are allowed to be navigated
     */
    public isRouteAllowed(path: string | Array<string>): boolean {
        const paths = typeof path === "string" ? [path] : path;

        return paths.findIndex((pathString) => this.route[pathString.split("/")[1]] === false) > -1 ? false : true;
    }
}

export default AppConfig;
