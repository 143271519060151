import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ToggleAssetModal from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import { useStore } from "../../../../services";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import AssetName from "../../Assets/AssetName/AssetName";
import { ModalText, InputTextWrapper } from "../../Assets/DisplayAssetModal/DisplayAssetModal";
import { EditAssetContainer, EditIconButton } from "../../../../components/List/AssetListItem/AssetListItem";
import EditIcon from "@material-ui/icons/Edit";
import { toJS } from "mobx";

export interface Props {
    editedFloorId?: number;
    isEdit?: boolean;
}

const ManageFloor: FC<Props> = observer((props: Props) => {
    const { isEdit, editedFloorId } = props;

    const store = useStore();
    const floorList = toJS(store.data.management.adminFloors);
    const floorData = floorList?.results;

    const [showModal, setShowModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [sortIsError, setSortIsError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [floorName, setFloorName] = useState<string>("");
    const [floorNumber, setFloorNumber] = useState<string>("");
    const [editedFloorNumber, setEditedFloorNumber] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<number | undefined>();

    const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);

    const handleAddFloor = (): void => {
        if (isEdit) {
            const [filteredData] = floorData && floorData.filter((item) => item.floorId === editedFloorId);
            setFloorName(filteredData.floorName);
            setFloorNumber(filteredData.floorNumber);
            setEditedFloorNumber(filteredData.floorNumber);
            setSortOrder(filteredData.sortOrder);
        } else {
            setFloorName("");
            setFloorNumber("");
            setSortOrder(undefined);
        }

        setShowModal(true);
    };

    const clearModal = () => {
        setFloorName("");
        setFloorNumber("");
        setSortOrder(undefined);
        setShowModal(false);
        setErrorMsg("");
        setIsError(false);
        setSortIsError(false);
    };
    const modalCancel = () => {
        clearModal();
    };

    const handleFloorName = (floor: string) => {
        setFloorName(floor);
        if (floor === "" || floor.length > 35 || !sortOrder) setIsSubmitDisabled(true);
        else setIsSubmitDisabled(false);
    };

    const handleFloorNumber = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const targetValue = event.target.value;
        setFloorNumber(targetValue);
        let isError = false;
        if (editedFloorNumber !== targetValue) {
            isError = !!floorData.filter((floor) => floor.floorNumber === event.target.value).length;
        }
        if (targetValue.length > 10) {
            setIsSubmitDisabled(true);
            setErrorMsg("The maximum allowed length for the floor number is 10");
        } else if (isError) {
            setErrorMsg("Floor number already exists.");
            setIsSubmitDisabled(true);
        } else {
            if (floorName === "" || !sortOrder) setIsSubmitDisabled(true);
            else setIsSubmitDisabled(false);
            setErrorMsg("");
        }
    };

    const handleSort = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const targetValue = parseInt(event.target.value);
        setSortOrder(targetValue);
        if (!targetValue) {
            setSortIsError(true);
            setIsSubmitDisabled(true);
        } else if (floorName === "") {
            setSortIsError(false);

            setIsSubmitDisabled(true);
        } else {
            setSortIsError(false);
            setIsSubmitDisabled(false);
        }
    };
    const saveDesk = () => {
        const deskProperties = {
            floorName: floorName,
            floorId: editedFloorId ?? null,
            floorNumber: floorNumber ?? 0,
            buildingId: buildingId,
            sortOrder: sortOrder ?? 0,
        };

        const updatedAssetPromise = store.subscription.management.addFloor(deskProperties);
        updatedAssetPromise
            .then(() => {
                store.subscription.management.getCustomerFloorData(buildingId);
                setShowModal(false);
            })
            .catch(() => {
                setIsError(true);
                setIsSubmitDisabled(true);
            });
    };

    const isScreenLarge = window.matchMedia("(min-height: 720px)").matches;

    // Define theme
    const theme = {
        primaryBlack: "#000000",
        secondaryBlack: "#666666",
        primaryWhite: "#ffffff",
        palette: {
            errorRed: "#b00020",
            secondary: "#0064d2",
        },
    };

    // Custom styling used for MUI textfield
    const useStyles = makeStyles(() =>
        createStyles({
            textField: {
                background: theme.primaryWhite,
                width: 350,
                height: 46,
                marginTop: 5,
                marginBottom: 0,
                paddingBottom: 10,
            },
            underline: {
                color: theme.secondaryBlack,
                "&::before": {
                    borderBottom: `1px solid ${theme.secondaryBlack}`,
                },
                "&$error:after": {
                    borderBottom: `2px solid ${theme.palette.errorRed}`,
                },
            },
            inputFocused: {
                "&::after": {
                    borderBottom: `2px solid ${theme.palette.secondary}`,
                },
            },
            floatingLabelFocusStyle: {
                "&.Mui-focused": {
                    color: theme.palette.secondary,
                    fontWeight: "bold",
                },
                "&.Mui-error": {
                    color: theme.palette.errorRed,
                    fontWeight: "bold",
                },
            },
            error: {
                "&.MuiFormHelperText-root.Mui-error": {
                    color: theme.palette.errorRed,
                },
            },
        }),
    );

    const classes = useStyles();

    const modal = (
        <ToggleAssetModal
            title={isError ? "Sorry, an error has occurred" : isEdit ? "Edit Floor" : "Add Floor"}
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={isScreenLarge ? "608px" : "518px"}
            width="712px"
            contentWidth="632px"
            contentHeight={isScreenLarge ? "446px" : "326px"}
            onClickPrimary={saveDesk}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
            isButtonDisabled={isSubmitDisabled}
        >
            {isError ? (
                <ModalText>Please try again later.</ModalText>
            ) : (
                <>
                    <InputTextWrapper>
                        <AssetName
                            label="Floor name"
                            value={floorName}
                            onNameChange={(floor) => handleFloorName(floor.toString())}
                            assetTypeLabel={""}
                        />
                    </InputTextWrapper>

                    <DeskNumberWrapper>
                        <TextField
                            error={Boolean(errorMsg)}
                            label={"Floor number"}
                            value={floorNumber}
                            onChange={handleFloorNumber}
                            id={"deskNumber"}
                            type="text"
                            helperText={errorMsg ?? "Add floor number"}
                            margin="normal"
                            variant="filled"
                            color="primary"
                            FormHelperTextProps={{
                                classes: {
                                    error: classes.error,
                                },
                            }}
                            InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                            }}
                            InputProps={{
                                className: classes.textField,
                                classes: {
                                    underline: classes.underline,
                                    focused: classes.inputFocused,
                                    error: classes.error,
                                },
                                style: {
                                    color: theme.primaryBlack,
                                    backgroundColor: theme.primaryWhite,
                                },
                            }}
                        />
                    </DeskNumberWrapper>
                    <DeskNumberWrapper>
                        <TextField
                            label="Sort order *"
                            value={sortOrder}
                            onChange={handleSort}
                            id={"sortOrder"}
                            type="number"
                            helperText={"You need to enter a sort order to Save"}
                            margin="normal"
                            variant="filled"
                            color="primary"
                            error={sortIsError}
                            FormHelperTextProps={{
                                classes: {
                                    error: classes.error,
                                },
                            }}
                            InputProps={{
                                className: classes.textField,
                                classes: {
                                    underline: classes.underline,
                                    focused: classes.inputFocused,
                                },
                                style: {
                                    color: theme.primaryBlack,
                                    backgroundColor: theme.primaryWhite,
                                },
                            }}
                        />
                    </DeskNumberWrapper>
                </>
            )}
        </ToggleAssetModal>
    );

    return (
        <>
            {showModal && modal}
            {isEdit ? (
                <EditAssetContainer>
                    <EditIconButton onClick={handleAddFloor}>
                        <EditIcon fontSize="small" aria-label="edit asset" />
                    </EditIconButton>
                </EditAssetContainer>
            ) : (
                <ActionButton onClick={handleAddFloor}>
                    <span>
                        <AddCircleOutlineIcon />
                        &nbsp;
                    </span>
                    Add Floor
                </ActionButton>
            )}
        </>
    );
});

export default ManageFloor;

const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: none;
    color: #001e82;
    margin-right: 15px;
`;

const DeskNumberWrapper = styled(InputTextWrapper)`
    & > div > div {
        width: 350px;
    }
`;
