import * as React from "react";
import styled from "styled-components";
import { CheckboxGroup } from "../CheckboxGroup/CheckboxGroup";

export interface UserModel {
    userId: number;
    name: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    allowedCarPark?: boolean;
}

export interface Props {
    userData: Array<UserModel>;
    onRemove: (user: string | number) => void;
    focus?: (focusedHtmlElement: HTMLButtonElement) => void;
    onSelect: (userList: Array<UserModel>) => void;
}
export type OptionType = {
    value: number;
    label: string;
    checked?: boolean;
};

export const UserChipContainer: React.FC<Props> = (props) => {
    const { onRemove, userData } = props;

    const handleClick = (e: Array<OptionType>): void => {
        const userData: Array<UserModel> = [];
        e.forEach((d, iter) => {
            const resultSet = {
                userId: d.value,
                name: d.label,
                allowedCarPark: d.checked,
            };
            userData.push(resultSet);
        });

        props.onSelect(userData);
    };

    const option: Array<OptionType> = [];
    userData.forEach((userData, iter) => {
        const resultSet = {
            value: userData.userId,
            label: userData.name,
            checked: userData.allowedCarPark,
        };
        option.push(resultSet);
    });

    const createUserChips = (): React.ReactElement => {
        return (
            <>
                <CheckboxGroup options={option} onChange={handleClick} onRemove={(e) => onRemove(e)} />
            </>
        );
    };
    return (
        <Wrapper>
            <HeadingText>Allow/Revoke carpark permission</HeadingText>
            {createUserChips()}
        </Wrapper>
    );
};

export default UserChipContainer;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px 0;
    flex-direction: column;
`;

const HeadingText = styled.span`
    color: #484848;
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 10px;
`;
