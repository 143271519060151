import React, { FC, ReactNode } from "react";
import styled from "styled-components";

export interface Props {
    children: ReactNode;
    cardHeader?: string;
}

const ConfigurePanelCards: FC<Props> = (props: Props) => {
    const { cardHeader, children } = props;
    return (
        <ConfigureContainer>
            <Line fill="rgb(87 99 221)" />
            {cardHeader && <CardHeader>{cardHeader}</CardHeader>}
            {children}
        </ConfigureContainer>
    );
};

export default ConfigurePanelCards;

const ConfigureContainer = styled.div`
    transition: 0.5s;
    width: 377px;
`;

const CardHeader = styled.div`
    height: 25px;
    background-color: rgb(240, 253, 255);
    padding: 10px 0px 10px 20px;
`;

const Line = styled.hr<{ fill: string }>`
    background-color: ${(props): string => props.fill};
    margin: 0px;
    height: 0.5px;
`;
