import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    label: React.ReactNode | string;
    backgroundColour: string;
    backgroundHoverColour: string;
    labelColour: string;
    labelHoverColour: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default class CircleButton extends React.Component<Props> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
    }

    static defaultProps = {
        label: "",
        backgroundColour: "#FFFFFF",
        backgroundHoverColour: "#F1F5F9",
        labelColour: "#9E9E9E",
        labelHoverColour: "#0064D2",
    };

    public render(): ReactNode {
        return (
            <ButtonDiv
                backgroundColour={this.props.backgroundColour}
                backgroundHoverColour={this.props.backgroundHoverColour}
                onClick={this.props.onClick}
            >
                <ButtonContents labelColour={this.props.labelColour} labelHoverColour={this.props.labelHoverColour}>
                    {this.props.label}
                </ButtonContents>
            </ButtonDiv>
        );
    }
}

const ButtonDiv = styled.button<{ backgroundColour: string; backgroundHoverColour: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 31px;
    width: 31px;
    border: 1px solid #ececf1;
    border-radius: 25px;
    background-color: ${(props): string => props.backgroundColour};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

    :hover {
        cursor: pointer;
    }
    :active {
        background: ${(props): string => props.backgroundHoverColour};
    }
    :focus {
        outline: none;
    }
`;

const ButtonContents = styled.span<{ labelColour: string; labelHoverColour: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props): string => props.labelColour};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.41px;
    text-align: center;
    user-select: none;
    fill: ${(props): string => props.labelColour};

    ${ButtonDiv}:hover & {
        color: ${(props): string => props.labelHoverColour} !important;
        fill: ${(props): string => props.labelHoverColour};
    }
`;
