import { UserAgentApplication, Configuration } from "msal";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: window.location.origin,
        validateAuthority: false,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loadFrameTimeout: 10 * 1000,
    },
};

const loginRequest = {
    scopes: process.env.REACT_APP_API_SCOPE_SITES_READ?.replace(" ", "").split(","),
    extraQueryParameters: { domain_hint: "" },
    loginHint: "",
};

export const msalInstance = new UserAgentApplication(msalConfig as Configuration);
export const clientId = msalConfig.auth.clientId;

export const refreshToken = async (): Promise<unknown> => {
    const tokenRequest = {
        scopes: loginRequest.scopes,
    };

    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    } catch (err) {
        if (err.name === "InteractionRequiredAuthError") {
            try {
                const response = await msalInstance.acquireTokenPopup(tokenRequest);
                return response.accessToken;
            } catch (error) {
                console.log(error);
            }
        }
    }
    return null;
};

export const login = async (domainHint: string, loginHint: string): Promise<void> => {
    try {
        loginRequest.extraQueryParameters.domain_hint = domainHint;
        loginRequest.loginHint = loginHint;
        await msalInstance.loginRedirect(loginRequest);
    } catch (err) {
        console.error("Error:", err);
    }
};

export const logout = async (): Promise<void> => {
    try {
        sessionStorage.clear();
        await msalInstance.logout();
    } catch (err) {
        console.error("Error:", err);
    }
};
