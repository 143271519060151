import * as React from "react";
import styled from "styled-components";
import SVGIconVideoConference from "../Icon/VideoConference";
import SVGIconPeopleBust from "../Icon/PeopleBust";
import ListItemInsight from "../Tiles/ListItemInsight";

export interface Occupancy {
    floorOccupancyCount: number;
    floorOccupancyCapacity: number;
    deskOccupancyCount: number;
    deskRecentOccupancyCount: number;
    deskOccupancyCapacity: number;
    roomOccupancyCount: number;
    roomOccupancyCapacity: number;
}
export interface Props {
    occupancyMode: "Vacant" | "Occupied";
    occupancy: Occupancy;
}

export const RoomTab: React.FC<Props> = (props: Props) => {
    const { occupancy, occupancyMode } = props;
    const { floorOccupancyCount, roomOccupancyCapacity, roomOccupancyCount } = occupancy;

    const title = (): string => {
        return occupancyMode !== "Occupied"
            ? `${roomOccupancyCapacity - roomOccupancyCount} / ${roomOccupancyCapacity} Rooms`
            : `${roomOccupancyCount} / ${roomOccupancyCapacity} Rooms`;
    };

    const roomTitle = (): string => {
        const vacantPercent = ((roomOccupancyCapacity - roomOccupancyCount) / roomOccupancyCapacity) * 100;
        const occupiedPercent = (roomOccupancyCount / roomOccupancyCapacity) * 100;
        return occupancyMode !== "Occupied"
            ? `${Math.min(vacantPercent > 0 ? vacantPercent : 0, 100).toFixed(0)}% Vacant`
            : `${Math.min(occupiedPercent > 0 ? occupiedPercent : 0, 100).toFixed(0)}% Occupied`;
    };

    const generateListItem = (title: string): React.ReactNode => (
        <ListItemInsight
            reverseProgress={occupancyMode !== "Occupied"}
            title={title}
            subtitle={occupancyMode !== "Occupied" ? "Rooms Vacant" : "Room Occupancy"}
            svgComponent={<SVGIconVideoConference fill="#0099F8" />}
            progress={roomOccupancyCount / roomOccupancyCapacity}
        />
    );

    return (
        <InsightWrapperInner>
            <ListItemInsight
                reverseProgress={occupancyMode !== "Occupied"}
                title={`${Math.max(floorOccupancyCount, 0)} People`}
                subtitle={"People Count"}
                svgComponent={<SVGIconPeopleBust fill="#0099F8" />}
            />
            {roomOccupancyCapacity > 0 && (
                <>
                    {generateListItem(title())}
                    {generateListItem(roomTitle())}
                </>
            )}
        </InsightWrapperInner>
    );
};

const InsightWrapperInner = styled.div`
    display: flex;
    margin-right: 12px;
`;
