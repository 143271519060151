import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
    width?: string;
    height?: string;
}

export default class Timer extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof Level
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        const { width, height } = this.props;
        return (
            <SVGWrapper
                width={width ? width : "100%"}
                height={height ? height : "100%"}
                viewBox="0 0 32 38"
                fillColor={this.fillColor()}
            >
                <g
                    id="3308---I-want-to-see-the-overall-building-efficiency-metrics-with-key-stats"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Building-View----Desks-Selected"
                        transform="translate(-24.000000, -207.000000)"
                        fill="#9E9E9E"
                        fillRule="nonzero"
                    >
                        <g id="Group-4" transform="translate(0.000000, 65.000000)">
                            <g id="Realtime-Indicator" transform="translate(24.000000, 133.000000)">
                                <path
                                    d="M13.8652449,11.6655309 L13.8652593,9.6515214 L14.9326297,9.6515214 L14.9326297,13.9179173 C14.9326297,14.2124505 14.6936907,14.4512168 14.3989445,14.4512168 L10.1294631,14.4512168 L10.1294631,13.3846178 L13.8483426,13.3838383 C12.0507697,10.5574292 8.4673497,9.40809621 5.34374944,10.6986957 C2.12719595,12.0277012 0.393946751,15.5326122 1.29142613,18.8931505 C2.18890551,22.2536889 5.4392914,24.4295725 8.89080334,23.9803497 C12.3423153,23.5311269 14.9265095,20.5958583 14.9326297,17.1177142 L16,17.1177142 C16,21.5357119 12.4159151,25.1172066 7.9947224,25.1172066 C4.1127917,25.1117253 0.794233821,22.3239306 0.121779988,18.5034461 C-0.550673845,14.6829615 1.6167953,10.9309515 5.26388098,9.60218239 C8.33685605,8.48258316 11.713994,9.35525749 13.8652449,11.6655309 Z M8.52844491,13.3846178 L8.52844491,17.1177142 C8.53013303,17.2591494 8.47198461,17.394731 8.36830202,17.4910239 L5.16619098,20.6908208 L4.41903174,19.9442015 L7.46103722,16.8937285 L7.46103722,13.3846178 L8.52844491,13.3846178 Z"
                                    id="Icon-UpdateFrequency"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </SVGWrapper>
        );
    }
}
const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
