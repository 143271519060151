import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { media, config } from "../../../config/Config";
// -- component imports
import OccupancyListItem from "../OccupancyListItem/OccupancyListItem";

interface ListItem {
    label: string;
    deskPopulation?: number;
    deskCapacity?: number;
    meetingRoomPopulation?: number;
    meetingRoomCapacity?: number;
    carparkPopulation?: number;
    carparkCapacity?: number;
    urlPath?: string;
    displayToggle?: boolean;
    bookable?: boolean;
}

/**
 * Definition of the data required for each list item.
 *
 * @interface ListData
 */
export interface ListData {
    id: number;
    listItem: ListItem;
}

/**
 * Props
 */
export interface Props {
    header: string;
    icon: React.ReactElement;
    listData: Array<ListData>;
    onChangeBookable?: (floorId: number, bookable: boolean, label: string) => void;
}

const OccupancyList: FC<Props> = (props: Props) => {
    const { listData, header, icon, onChangeBookable } = props;

    const onClickBookable = (floorId: number, bookable: boolean, label: string) => {
        onChangeBookable && onChangeBookable(floorId, bookable, label);
    };

    const generateListItem = (list: ListData): ReactNode => {
        return (
            <Content role="listitem" key={list.id}>
                <OccupancyListItem
                    header={header}
                    label={list.listItem.label}
                    id={list.id}
                    deskCapacity={list.listItem.deskCapacity}
                    deskPopulation={list.listItem.deskPopulation}
                    meetingRoomCapacity={list.listItem.meetingRoomCapacity}
                    meetingRoomPopulation={list.listItem.meetingRoomPopulation}
                    carSpaceCapacity={list.listItem.carparkCapacity}
                    carSpacePopulation={list.listItem.carparkPopulation}
                    urlPath={list.listItem.urlPath}
                    displayToggle={list.listItem.displayToggle}
                    bookable={list.listItem.bookable}
                    onChangeBookable={(isBookable: boolean) =>
                        onClickBookable(list.id, isBookable, list.listItem.label)
                    }
                />
            </Content>
        );
    };

    /**
     * Generates a list from buildingsData.
     */
    const listItems = listData.map(generateListItem);
    const generateList = (): ReactNode => <Item role="list">{listItems}</Item>;

    return (
        <>
            <Header>
                <IconContainer>{icon}</IconContainer>
                <BuildingText>{header}</BuildingText>
            </Header>
            <ListContainer
                aria-label={header}
                maxWidth={config.breakpoints.tablet}
                minWidth={config.breakpoints.mobile}
            >
                {generateList()}
            </ListContainer>
        </>
    );
};

export default OccupancyList;

const Content = styled.div`
    padding-bottom: 5px;
`;

const Item = styled.div``;

const ListContainer = styled.div<{ maxWidth: number; minWidth: number }>`
    min-width: ${(props): string => props.minWidth + "px"};
    max-width: ${(props): string => props.maxWidth + "px"};
`;

const Header = styled.div`
    display: flex;
    min-width: 700px;
    font-size: 12px;
    font-weight: bold;
`;

const IconContainer = styled.div`
    width: 28px;
    height: 28px;
    padding-bottom: 6px;
`;

const BuildingText = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    padding-top: 4px;
    padding-left: 10px;
    margin-bottom: 6px;
    width: 410px;
    flex-shrink: 1;
    ${media.tablet} {
        width: 320px;
    }
`;
