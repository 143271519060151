import React, { useEffect, useState } from "react";
import styled from "styled-components";

export type CheckboxType = {
    label: string;
    value: string | number;
    id: number;
    onChange: (value: string) => void;
    checked?: boolean;
};

export const Checkbox = ({ label, value, id, onChange, checked }: CheckboxType) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChange = (e: { target: { value: string } }) => {
        setIsChecked(!isChecked);
        onChange(e.target.value);
    };

    return (
        <Wrapper>
            <CheckedBox
                aria-checked={checked}
                tabIndex={0}
                onChange={handleChange}
                type="checkbox"
                value={value}
                id={`id ${id}`}
                checked={isChecked}
            />
            <Label htmlFor="flexCheckDefault">{label}</Label>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 95%;
    margin-bottom: 5px;
`;

const CheckedBox = styled.input`
    height: 18px;
    width: 18px;
`;

const Label = styled.label`
    color: #000;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 3px 10px 0;
`;
