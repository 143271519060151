import React, { useState, FC } from "react";
import styled from "styled-components";

export interface Props {
    id: string;
    startEnabled: boolean;
    readonly: boolean;
    toggleOnText: string;
    toggleOffText: string;
    enabledStateColour: string;
    offStateColour: string;
    onChange: (active: boolean) => void;
}

const Toggle: FC<Props> = (props: Props) => {
    const { id, startEnabled, readonly, toggleOnText, toggleOffText, enabledStateColour, offStateColour, onChange } =
        props;

    const [isActive, setIsActive] = useState(startEnabled);

    const onSwitched = () => {
        if (readonly === false) {
            setIsActive(!isActive);
            onChange(!isActive);
        }
    };

    return (
        <Wrapper>
            <ToggleText htmlFor={id}>{isActive ? toggleOnText : toggleOffText}</ToggleText>
            <SwitchButton
                id={id}
                type="button"
                role="switch"
                isActive={isActive}
                isReadOnly={readonly}
                aria-label={id}
                aria-checked={isActive}
                aria-readonly={readonly}
                onClick={onSwitched}
                enabledStateColour={enabledStateColour}
                offStateColour={offStateColour}
            >
                <SwitchIndicator />
            </SwitchButton>
        </Wrapper>
    );
};

export default Toggle;

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ToggleText = styled.label`
    flex: 0 1 auto;
    color: #333333;
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    padding-bottom: 3px;
`;

const SwitchButton = styled.button<{
    isActive: boolean;
    isReadOnly: boolean;
    enabledStateColour: string;
    offStateColour: string;
}>`
    border: none;
    border-radius: 14px;
    display: inline-block;
    vertical-align: middle;
    height: 18px;
    padding: 0 18px;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    background-color: ${(props): string =>
        props.offStateColour && props.isActive ? props.enabledStateColour : props.offStateColour};

    ${({ isActive }) =>
        isActive &&
        `
    ${SwitchIndicator} {
      left: 1.15rem;
    }
  `}
    ${({ isReadOnly }) =>
        isReadOnly &&
        `
    ${SwitchIndicator} {
      background-color: #dddddd;
      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`;

const SwitchIndicator = styled.span`
    background-color: #ffffff;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 2px;
    left: 4px;
    transition: all 600ms ease;
    box-shadow: 0px 1px 2px 0px #a4a4a4;
`;
