import React, { FC, ReactElement } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import styled from "styled-components";

export interface Props {
    infoElement: ReactElement;
    width?: string;
    backgroundColor?: string;
}

const InfoBanner: FC<Props> = (props: Props) => {
    const { infoElement, backgroundColor, width } = props;

    return (
        <BannerContainer background={backgroundColor} width={width}>
            <InfoOutlinedIcon htmlColor="#0064d2" />
            <BannerInfoText>{infoElement}</BannerInfoText>
        </BannerContainer>
    );
};

export default InfoBanner;

const BannerInfoText = styled.span`
    padding-left: 11px;
    font-size: 14px;
    color: #000000;
`;

const BannerContainer = styled.div<{ background?: string; width?: string }>`
    width: ${(props): string => (props.width ? props.width : "639px")};
    display: flex;
    padding: 12px 15px;
    background-color: ${(props): string => (props.background ? props.background : "rgba(0, 100, 210, 0.06)")};
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    border-left: 4px solid rgba(0, 100, 210, 1);
`;
