import React, { ReactNode } from "react";
import styled from "styled-components";
import Throbber from "../../Common/Throbber/Throbber";

/**
 *The Props for the component are defined here with their types.
 */
export interface Props {
    isLoading: boolean;
    children: ReactNode;
}

export default class LoadableContainer extends React.Component<Props> {
    /**
     *Constructor for class defines the typing for props.
     */
    public constructor(props: Props) {
        super(props);
    }

    public render(): ReactNode {
        return (
            <React.Fragment>
                {this.props.isLoading ? (
                    <Wrapper>
                        <Throbber />
                    </Wrapper>
                ) : (
                    this.props.children
                )}
            </React.Fragment>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
