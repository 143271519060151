import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import { Close } from "@material-ui/icons";
import { useOuterClickNotifier } from "../ProfileTab/OuterClickNotifierHook";

export interface Props {
    onGoBack: () => void;
    onRemoveFloorPlan: () => void;
    onUploadFloorPlan: () => void;
    buildingName?: string;
    floorName?: string;
    isFloorplanAvailable?: boolean;
}

const FloorplanNavigationBar: React.FC<Props> = (props: Props) => {
    const { onGoBack, onRemoveFloorPlan, onUploadFloorPlan, buildingName, floorName, isFloorplanAvailable } = props;
    const [showMenuItems, setShowMenuItems] = useState(false);
    const menuItemInnerRef = useRef<HTMLDivElement | null>(null);

    const handleMoreIconClick = () => {
        setShowMenuItems(!showMenuItems);
    };

    const handleOuterClick = useCallback(
        (event: MouseEvent) => {
            if (showMenuItems) {
                setShowMenuItems(false);
            }
        },
        [showMenuItems],
    );

    // Register callback with hook
    useOuterClickNotifier(handleOuterClick, menuItemInnerRef);

    return (
        <>
            <ColourStrip />
            <BarBackground data-cy="floorplan-navigation-bar" role="navigation">
                <LeftNavigation>
                    <HeadingText>Static Floorplan</HeadingText>
                    {buildingName && floorName && (
                        <FloorDetailText>
                            <span>{buildingName}</span>
                            <Divider />
                            <span>
                                <strong>{floorName}</strong>
                            </span>
                        </FloorDetailText>
                    )}
                </LeftNavigation>
                <RightNavigation>
                    <MoreIconWrapper aria-label="More" onClick={handleMoreIconClick}>
                        <div ref={menuItemInnerRef}>
                            <MoreHorizIcon fontSize="large" />
                            <MoreMenu isVisible={showMenuItems}>
                                <MoreMenuItemText onClick={onUploadFloorPlan}>
                                    <ActionIconWrapper>
                                        <PublishIcon />
                                    </ActionIconWrapper>
                                    Upload Floorplan
                                </MoreMenuItemText>
                                <MoreMenuItemText isDisabled={!isFloorplanAvailable} onClick={onRemoveFloorPlan}>
                                    <ActionIconWrapper>
                                        <DeleteIcon />
                                    </ActionIconWrapper>
                                    Remove Floorplan
                                </MoreMenuItemText>
                            </MoreMenu>
                        </div>
                    </MoreIconWrapper>
                    <CloseIconWrapper>
                        <IconActionButton
                            onClick={onGoBack}
                            disableRipple
                            disableFocusRipple
                            aria-label="Close"
                            role="button"
                        >
                            <Close style={{ fontSize: "36px", color: "black" }} />
                        </IconActionButton>
                    </CloseIconWrapper>
                </RightNavigation>
            </BarBackground>
        </>
    );
};

export default FloorplanNavigationBar;

const ColourStrip = styled.div`
    height: 2px;
    background: linear-gradient(90deg, #0064d2 0%, #5cd6e0 31.99999999999998%, #5e50bf 99.99999999999999%);
    padding-left: 24px;
    align-items: center;
    ${media.mobile} {
        width: auto;
        min-height: 2px;
    }
`;

const BarBackground = styled.div`
    height: 14vh;
    border: 1px solid #ececf1;
    background-color: #ffffff;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    user-select: none;
    ${media.mobile} {
        display: none;
    }
    ${media.print} {
        display: none;
    }
    /* Ensure dropdown is shown above all other elements. */
    z-index: 100;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
`;

const LeftNavigation = styled.div`
    width: 50%;
    padding-left: 36px;
`;

const HeadingText = styled.h2`
    margin: 18px 0 12px 0;
`;

const RightNavigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 15%;
    padding-right: 36px;
`;

const FloorDetailText = styled.div`
    margin-bottom: 18px;
`;
const CloseIconWrapper = styled.div``;

const MoreIconWrapper = styled.button`
    cursor: pointer;
    background-color: #ffffff;
    border: none;
`;

const IconActionButton = styled(IconButton)`
    width: 32px;
    height: 32px;
    &:focus {
        box-shadow: 0 0 0 2px #ffffff !important;
    }
`;

const Divider = styled.span`
    display: inline-block;
    position: relative;
    height: 6px;
    width: 3px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0px;
    margin-bottom: 2px;
    transform: rotate(270deg);
    :before {
        margin-left: -2px;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: #333333;
        transform: skew(30deg, 0deg);
    }
    :after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background: #333333;
        transform: skew(-30deg, 0deg);
    }
`;

const MoreMenu = styled.div<{ isVisible: boolean }>`
    display: ${(props): string => (props.isVisible ? "block" : "none")};
    padding: 10px 0 10px 0;
    position: absolute;
    min-width: 100px;
    overflow: auto;
    z-index: 1;
    top: 58%;
    right: 14%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 10%);
    opacity: ${(props): number => (props.isVisible ? 1 : 0)};
    transition: opacity 0.2s;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    width: 250px;
`;

const MoreMenuItemText = styled.div<{ isDisabled?: boolean }>`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 34px 10px 42px;

    ${(props): string =>
        props.isDisabled
            ? `color: #0006;
        background: none;
        :hover {
            cursor: not-allowed;
        }
        `
            : `
        :hover {
            background-color: #f1f1f1;
            cursor: pointer;
        }
        `};
`;

const ActionIconWrapper = styled.div`
    padding-right: 14px;
`;
