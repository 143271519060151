import { useContext } from "react";
import { appContext } from "./../../components";
import { RootStore } from "..";

/**
 * useStore hook to provide easy access to the store
 */
const useStore = (): RootStore => useContext(appContext).store;

export default useStore;
