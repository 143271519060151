import * as React from "react";
import styled from "styled-components";
import CircleButton from "../Button/CircleButton/CircleButton";
import SVGIconPlus from "../Icon/Plus";
import SVGIconMinus from "../Icon/Minus";

/**
 * Defines Canvas Props.
 */
export interface Props {
    toggleCamera3D: (degreesWhen3D: number) => number;
    setCameraZoomLevel: (zoomAmount: number) => void;
    cameraTilt: number;
}

export default class ZoomControls extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    private zoomAmount = 0.5;
    private degreesWhen3D = 45;

    private setCameraZoomLevel = (zoomAmount: number): void => {
        this.props.setCameraZoomLevel(zoomAmount);
    };

    private cameraToggle = (): void => {
        this.props.toggleCamera3D(this.degreesWhen3D);
    };

    /**
     * Renderer
     */
    public render(): React.ReactNode {
        const zoomIn = (): void => this.setCameraZoomLevel(this.zoomAmount);
        const zoomOut = (): void => this.setCameraZoomLevel(-this.zoomAmount);

        return (
            <Wrapper>
                <ButtonContainer>
                    <CircleButton onClick={this.cameraToggle} label={this.props.cameraTilt > 0 ? "3D" : "2D"} />
                </ButtonContainer>
                <ButtonContainer>
                    <CircleButton
                        label={<SVGIconPlus />}
                        backgroundColour="#FFFFFF"
                        backgroundHoverColour="#F1F5F9"
                        labelColour="#9E9E9E"
                        labelHoverColour="#0064D2"
                        onClick={zoomIn}
                    />
                </ButtonContainer>
                <ButtonContainer>
                    <CircleButton
                        label={<SVGIconMinus />}
                        backgroundColour="#FFFFFF"
                        backgroundHoverColour="#F1F5F9"
                        labelColour="#9E9E9E"
                        labelHoverColour="#0064D2"
                        onClick={zoomOut}
                    />
                </ButtonContainer>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: absolute;
    bottom: 11px;
    right: 11px;
`;

const ButtonContainer = styled.div`
    padding-bottom: 0.5rem;
`;
