import React, { FC } from "react";
import styled from "styled-components";

export interface Props {
    width?: number;
    height?: number;
    display?: string;
}

export const defaultProps = {
    width: 96,
    height: 96,
    display: "block",
};

const Throbber: FC<Props> = (props: Props) => {
    const { width, height, display } = Object.assign(defaultProps, props);

    /**
     * Notes:
     * - Please clean class names and IDs before pasting code
     * - Animate the clipping path to rotate while keeping the gradient stationary
     * - Animate the shape (i.e. the rectangle) to rotate everything including the gradient
     * - An alternative animation function of note is `cubic-bezier(0.25, 0.25, 0.25, 1)`
     */
    return (
        <Container $display={display}>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 96 96">
                <defs>
                    {/* ID is required for clipping path to be used in shape object. I.e. in rect */}
                    <ClippingPath id="clippingPath">
                        <path d="M48,96V88A40,40,0,1,0,8,48H0A48,48,0,1,1,48,96Z" fill="transparent" />
                    </ClippingPath>
                    <linearGradient id="linearGradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#0064d2" />
                        <stop offset="0.316" stopColor="#5cd6e0" />
                        <stop offset="1" stopColor="#5e50bf" />
                    </linearGradient>
                </defs>
                <rect
                    id="gradientRect"
                    width="96"
                    height="96"
                    fill="url(#linearGradient)"
                    clipPath="url(#clippingPath)"
                />
            </svg>
        </Container>
    );
};

export default Throbber;

const Container = styled.div<{ $display: string }>`
    box-sizing: content-box;
    display: ${({ $display }): string => $display ?? "block"};
`;

const ClippingPath = styled.clipPath`
    animation: rotate 1s linear infinite;
    transform-origin: center;
    stroke-linecap: round;

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
`;
