const convertToCSV = (objArray: string | Array<string>): string => {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    array.forEach((_: unknown, i: number) => {
        let line = "";
        for (const index in array[i]) {
            if (line !== "") line += ",";
            line += array[i][index];
        }
        str += line + "\r\n";
    });

    return str;
};

export const exportCSVFile = (items: Array<{}>, fileTitle: string, headers?: Record<string, string>): string => {
    // -- create headers
    if (headers) {
        items.unshift(headers);
    } else {
        const headers = [Object.keys(items[0])];
        items.unshift(headers);
    }

    // Convert Object to JSON and then to CSV
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);

    // -- Create file and download
    const exportedFileName = fileTitle + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (typeof document.createElement === "function") {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
        }
        document.body.removeChild(link);
    }
    return csv;
};

export const getNewDate = (): string => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
};
