import { autorun } from "mobx";
import UserStore from "./UserStore";
import UIStore from "./UIStore";
import SubscriptionStore from "./SubscriptionStore";
import DataStore from "./DataStore";
import FeatureStore from "./FeatureStore";

interface AutoSaveData {
    ui: UIStore;
    data: DataStore;
    features: FeatureStore;
}

class RootStore {
    user: UserStore;
    ui: UIStore;
    subscription: SubscriptionStore;
    data: DataStore;
    features: FeatureStore;

    private _sessionStorageKey = "ss2.storage";
    private _validPeriod = 30;

    constructor() {
        const savedData = this.getAutoSaveData();

        this.user = new UserStore();
        this.subscription = new SubscriptionStore(this);

        // Load saved data to ui and data store if they are available
        this.ui = new UIStore(this, savedData?.ui);
        this.data = new DataStore(savedData?.data);
        this.features = new FeatureStore(savedData?.features);

        autorun(() => {
            this.autoSave();
        });
    }

    private getAutoSaveData(): AutoSaveData | undefined {
        const currentTimestamp = new Date();
        const sessionData = sessionStorage.getItem(this._sessionStorageKey);

        let returnedData;

        if (sessionData) {
            const jsonData = JSON.parse(sessionData);
            const timeDiff = (currentTimestamp.getTime() - new Date(jsonData.timestamp).getTime()) / 1000;

            if (timeDiff < this._validPeriod) {
                const { ui, data, features } = jsonData;
                returnedData = { ui, data, features };
            }
        }

        return returnedData;
    }

    private autoSave(): void {
        const ui = this.ui.toJSON();
        const data = this.data.toJSON();
        const features = this.features.toJSON();

        if (this.ui.currentView < 1) {
            return;
        }

        const timestamp = new Date();
        const storeData = { timestamp, ui, data, features };

        sessionStorage.setItem(this._sessionStorageKey, JSON.stringify(storeData));
    }
}

export default RootStore;
