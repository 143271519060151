import React, { FC, ReactNode } from "react";
import { BookingData, EditedAssetDetails, ToggleAssetModalProps } from "../Assets";
import styled from "styled-components";
import RadioButtonGroup, { Option } from "../../../../components/Input/RadioButtonGroup/RadioButtonGroup";
import CheckboxGroup, { Option as CheckboxOption } from "../../../../components/Input/CheckboxGroup/CheckboxGroup";
import ToggleAssetModal, { ModalTypes } from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import { Throbber } from "../../../../components";
import { Admin } from "../../../../services/WebService/Admin/ApiDefinition";
import AssetName from "../AssetName/AssetName";

export interface Props {
    modalType: ModalTypes;
    numBookings: number;
    bookingData: BookingData;
    assetFeatureGroups: Array<Admin.AssetFeatureGroups>;
    editedAssetDetails: EditedAssetDetails;
    toggleProps: ToggleAssetModalProps;
    isSubmitDisabled: boolean;
    updateSelectedAssetFeature: (optionNames: Array<string>, featureGroupName: string) => void;
    onClearHandler: (featureGroupName: string) => void;
    modalCancel: () => void;
    modalConfirm: () => void;
    onChangeCheckbox: (checked: boolean) => void;
    editAssetSubmitHandler: () => void;
    closeEditAssetModal: () => void;
    handleAssetNameChange: (assetName: string) => void;
    handleAssetCapacityChange: (capacity: string) => void;
}

const DisplayAssetModal: FC<Props> = (props: Props) => {
    const {
        modalType,
        numBookings,
        bookingData,
        assetFeatureGroups,
        editedAssetDetails,
        toggleProps,
        isSubmitDisabled,
        updateSelectedAssetFeature,
        onClearHandler,
        modalCancel,
        modalConfirm,
        onChangeCheckbox,
        editAssetSubmitHandler,
        closeEditAssetModal,
        handleAssetNameChange,
        handleAssetCapacityChange,
    } = props;

    const constructFeatureOptions = (): ReactNode => {
        const { resourceId } = editedAssetDetails;
        return (
            assetFeatureGroups?.length &&
            assetFeatureGroups?.map((assetFeatureGroup) => {
                const { id, name, featureOptions, isMultipleSelectable } = assetFeatureGroup;
                const radioOptions: Array<Option> = [];
                let selectedOptionForRadio = "";
                const checkboxOptions: Array<CheckboxOption> = [];
                const selectedOptionsForCheckbox: Array<string> = [];
                const shouldDisplayCheckbox = isMultipleSelectable || featureOptions.length === 1;

                featureOptions.forEach((featureOption) => {
                    const { optionName } = featureOption;
                    !shouldDisplayCheckbox
                        ? radioOptions.push({
                              label: optionName,
                              value: optionName,
                          })
                        : checkboxOptions.push({
                              label: optionName,
                              value: optionName,
                          });

                    if (editedAssetDetails?.features?.find((assetFeature) => assetFeature.optionName === optionName)) {
                        !shouldDisplayCheckbox
                            ? (selectedOptionForRadio = optionName)
                            : selectedOptionsForCheckbox.push(optionName);
                    }
                });

                return (
                    <FeatureGroupWrapper key={`${resourceId}_${id}`}>
                        {!shouldDisplayCheckbox ? (
                            <>
                                <RadioGroupWrapper>
                                    <StyledLabel>{name}</StyledLabel>
                                    <RadioButtonGroup
                                        name={name}
                                        options={radioOptions}
                                        onChange={(selectedOption) =>
                                            updateSelectedAssetFeature([selectedOption], name)
                                        }
                                        defaultSelection={selectedOptionForRadio}
                                        width="288px"
                                    />
                                </RadioGroupWrapper>
                                <ClearLink onClick={() => onClearHandler(name)}>
                                    Clear {name.toLowerCase()} selection
                                </ClearLink>
                            </>
                        ) : (
                            <>
                                <StyledLabel>{name}</StyledLabel>
                                <CheckboxGroup
                                    name={name}
                                    options={checkboxOptions}
                                    onChange={(selectedOptions) => updateSelectedAssetFeature(selectedOptions, name)}
                                    defaultSelection={selectedOptionsForCheckbox}
                                    width="288px"
                                />
                            </>
                        )}
                    </FeatureGroupWrapper>
                );
            })
        );
    };

    const checkHigherScreenHeight = () => {
        return window.matchMedia("(min-height: 720px)").matches;
    };

    const deriveResourceType = (resourceType: string) => {
        return resourceType
            .replace(/([A-Z]+)/g, " $1")
            .replace(/([A-Z][a-z])/g, " $1")
            .toLowerCase();
    };

    const generateAssetName = (assetType: string) => {
        const assetName = assetType === "Desk" ? "Desk" : assetType === "Room" ? "Meeting Room" : "";
        return assetName;
    };

    // Set loading indicator as the default
    let modal = (
        <ToggleAssetModal
            {...toggleProps}
            title="Retrieving booking status..."
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText=""
            secondaryButtonText="Cancel"
            onClickPrimary={modalCancel}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
        >
            <ModalContent>
                <Throbber />
            </ModalContent>
        </ToggleAssetModal>
    );

    switch (modalType) {
        case ModalTypes.Bookable: {
            const resourceType =
                bookingData.resourceType === "Room" ? "meeting room" : deriveResourceType(bookingData.resourceType);

            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    title={`Make ${resourceType} bookable?`}
                    renderCheckbox={false}
                    checkboxText=""
                    onClickPrimary={modalConfirm}
                    onClickSecondary={modalCancel}
                    onEscapeClicked={modalCancel}
                    contentWidth={bookingData.resourceType === "Room" ? "335px" : "325px"}
                >
                    <ModalText>
                        Confirm to change {resourceType} status to <strong>bookable.</strong>
                    </ModalText>
                    <ModalText>It will become available to be booked in the myWorkplace app.</ModalText>
                </ToggleAssetModal>
            );
            break;
        }

        case ModalTypes.NonBookableWithBookings: {
            const resourceType =
                bookingData.resourceType === "Room" ? "meeting room" : deriveResourceType(bookingData.resourceType);
            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    title={`Make ${resourceType} not bookable and cancel upcoming bookings?`}
                    renderCheckbox={true}
                    checkboxText="Download CSV of upcoming bookings"
                    onClickPrimary={modalConfirm}
                    onClickSecondary={modalCancel}
                    onChangeCheckbox={onChangeCheckbox}
                    onEscapeClicked={modalCancel}
                    contentWidth={bookingData.resourceType === "Room" ? "335px" : "325px"}
                >
                    <ModalText>
                        Confirm to change {resourceType} status to <strong>not bookable.</strong>
                    </ModalText>
                    <ModalText>It will no longer be available to book in the myWorkplace app.</ModalText>
                    <ModalText>
                        <strong>{numBookings}</strong> upcoming bookings will be cancelled if you proceed. Download CSV
                        for details.
                    </ModalText>
                </ToggleAssetModal>
            );
            break;
        }

        case ModalTypes.NonBookable: {
            const resourceType =
                bookingData.resourceType === "Room" ? "meeting room" : deriveResourceType(bookingData.resourceType);
            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    onClickPrimary={modalConfirm}
                    onClickSecondary={modalCancel}
                    onEscapeClicked={modalCancel}
                    title={`Make ${resourceType} not bookable?`}
                    renderCheckbox={false}
                    checkboxText=""
                    contentWidth={bookingData.resourceType === "Room" ? "335px" : "325px"}
                >
                    <ModalText>
                        Confirm to change {resourceType} status to <strong>not bookable.</strong>
                    </ModalText>
                    <ModalText>It will no longer be available to book in the myWorkplace app.</ModalText>
                </ToggleAssetModal>
            );
            break;
        }

        case ModalTypes.EditAsset: {
            const { assetName, peopleCapacity } = editedAssetDetails;
            let assetCapacity = "";
            if (peopleCapacity) {
                assetCapacity = peopleCapacity.toString();
            }
            const isScreenLarge = checkHigherScreenHeight();
            modal = (
                <ToggleAssetModal
                    title={`Edit ${generateAssetName(editedAssetDetails.resourceType)}`}
                    renderCheckbox={false}
                    checkboxText=""
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                    height={isScreenLarge ? "608px" : "518px"}
                    width="712px"
                    contentWidth="632px"
                    contentHeight={isScreenLarge ? "446px" : "326px"}
                    onClickPrimary={editAssetSubmitHandler}
                    onClickSecondary={closeEditAssetModal}
                    onEscapeClicked={closeEditAssetModal}
                    isButtonDisabled={isSubmitDisabled}
                >
                    <InputTextWrapper>
                        <AssetName
                            value={assetName}
                            onNameChange={(assetName) => handleAssetNameChange(assetName.toString())}
                            assetTypeLabel={editedAssetDetails.resourceType}
                        />
                        {editedAssetDetails.resourceType === "Room" && (
                            <>
                                <CapacityLabel>Capacity (people)</CapacityLabel>
                                <AssetName
                                    value={assetCapacity}
                                    onNameChange={(assetCapacity) => handleAssetCapacityChange(assetCapacity)}
                                    assetTypeLabel={editedAssetDetails.resourceType}
                                    defaultHelperMessage="capacity"
                                />
                            </>
                        )}
                    </InputTextWrapper>
                    {constructFeatureOptions()}
                </ToggleAssetModal>
            );
            break;
        }

        case ModalTypes.EditAssetLoading: {
            const isScreenLarge = checkHigherScreenHeight();
            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    title="Submitting asset details..."
                    renderCheckbox={false}
                    checkboxText=""
                    primaryButtonText=""
                    secondaryButtonText="Cancel"
                    height={isScreenLarge ? "608px" : "518px"}
                    width="712px"
                    contentWidth="632px"
                    contentHeight={isScreenLarge ? "446px" : "300px"}
                    onClickPrimary={closeEditAssetModal}
                    onClickSecondary={closeEditAssetModal}
                    onEscapeClicked={closeEditAssetModal}
                >
                    <ThrobberWrapper>
                        <Throbber />
                    </ThrobberWrapper>
                </ToggleAssetModal>
            );
            break;
        }
        case ModalTypes.CloneAssetLoading: {
            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    title="Cloning asset..."
                    renderCheckbox={false}
                    checkboxText=""
                    primaryButtonText=""
                    secondaryButtonText="Cancel"
                    onClickPrimary={closeEditAssetModal}
                    onClickSecondary={closeEditAssetModal}
                    onEscapeClicked={closeEditAssetModal}
                >
                    <ThrobberWrapper>
                        <Throbber />
                    </ThrobberWrapper>
                </ToggleAssetModal>
            );
            break;
        }

        case ModalTypes.Error: {
            const isScreenLarge = checkHigherScreenHeight();
            modal = (
                <ToggleAssetModal
                    {...toggleProps}
                    title="Sorry, an error has occurred"
                    renderCheckbox={false}
                    checkboxText=""
                    primaryButtonText="Close"
                    secondaryButtonText=""
                    height={isScreenLarge ? "608px" : "518px"}
                    width="712px"
                    contentWidth="632px"
                    contentHeight={isScreenLarge ? "446px" : "300px"}
                    onClickPrimary={modalCancel}
                    onClickSecondary={modalCancel}
                    onEscapeClicked={modalCancel}
                >
                    <ModalText>Please try again later.</ModalText>
                </ToggleAssetModal>
            );
            break;
        }

        default:
            break;
    }
    return modal;
};

export default DisplayAssetModal;

const CapacityLabel = styled.p`
    font-size: 14px;
    font-weight: 700;
    margin-bottom: -20px;
    margin-top: 30px;
`;

export const ModalText = styled.div`
    width: 348px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: normal;
    padding-bottom: 12px;
`;

const ModalContent = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 28px;
`;

export const InputTextWrapper = styled.div`
    & > div {
        margin-top: 0;
    }
    & > div > div {
        width: 454px;
    }
    margin-bottom: 22px;
    margin-top: -20px;
`;

export const StyledLabel = styled.label`
    font-weight: 700;
`;

export const RadioGroupWrapper = styled.div`
    margin-bottom: 10px;
`;

export const ThrobberWrapper = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ClearLink = styled.button`
    background: none;
    outline: none;
    border: none;
    text-align: left;
    line-height: 24px;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
    color: #001e82;

    :hover {
        cursor: pointer;
    }
`;

export const FeatureGroupWrapper = styled.div`
    margin-bottom: 40px;
`;
