import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../../../config/Config";
import { BreadCrumbNavigationBar, SidePanel, WrldMap, MapLegend, ConfigurePanel } from "../../../components";
import { Dashboard } from "../../../layouts";
import { observer } from "mobx-react";
import { useStore, UIStore, useConfig } from "../../../services";
import { Building, UIView, Role } from "../../../services/Store/UIStore";
import Toggle from "../../../components/Tab/Toggle";
import { useParams } from "react-router";

const RealTimeFloor: FC = observer(() => {
    const store = useStore();
    const config = useConfig();
    const [viewToggle, setToggle] = useState(false);
    /**
     * Prevent breadcrumbs being lost if RTF is directly reached
     */
    let selectedBuilding: Building | null = store.ui.selectedBuilding;

    /**
     * Conditional rendering based on role and environment
     */
    const renderConfigToggle = config.ui.ConfigToggle && store.ui.role === Role.SuperAdmin; // -- remove/replace this line with Azure-app-config when ready

    if (!selectedBuilding) {
        const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);
        const buildingTitle = Object.values(store.data.spaces.buildings)
            .filter((building) => {
                return building.id === buildingId ? building : null;
            })
            .reduce((building) => {
                return building;
            }).name;

        selectedBuilding = { id: buildingId, label: buildingTitle };
    }

    const floorNumber: number = parseInt((useParams() as { floorNumber: string }).floorNumber);
    const selectedPlan = Object.values(store.data.spaces.floors)
        .filter((floor): boolean => {
            return floor.spaceId === selectedBuilding?.id && floor.id === floorNumber;
        })
        .reduce((filteredFloor) => {
            return filteredFloor;
        });
    const floorTitle = selectedPlan.name;
    const selectedFloor = { id: floorNumber, label: floorTitle };
    const breadcrumbs = UIStore.generateBreadcrumbs(
        UIView.RealtimeFloor,
        selectedBuilding ?? (store.ui.selectedBuilding as Building),
        selectedFloor,
    );

    useEffect(() => {
        store.ui.setCurrentView(UIView.RealtimeFloor);
        store.ui.setSelectedFloor(selectedFloor);
        return (): void => {
            store.ui.setSelectedFloor();
        };
    }, [store.ui, selectedFloor, selectedPlan]);

    const building = store.data.spaces.buildings[selectedBuilding.id];
    const planId = selectedPlan.wrldId;
    const group = store.data.spaces.areaGroups;
    const telemetry = store.data.spaces.telemetry;

    const selectedFloorInsights = store.data.spaces.floorInsights[selectedPlan.id || 0] || [];
    const floorUtilization = {
        title: "Floor Utilisation",
        progressBarTitle: "Population",
        currentValueTitle: "NO. OF PEOPLE",
        currentValue: selectedFloorInsights.reduce(
            (a, e) => a + ((e.type === "PLAN" && e.countIn - e.countOut) || 0),
            0,
        ),
        maxValueTitle: "MAX OCCUPANCY",
        maxValue: selectedPlan.capacity,
    };
    const deskOccupancy = {
        title: "Desk Occupancy",
        progressBarTitle: "Desks Utilised",
        currentValueTitle: "DESKS UTILISED",
        currentValue: selectedFloorInsights.reduce((a, e) => a + ((e.type === "DESK" && e.recentlyOccupied) || 0), 0),
        maxValueTitle: "TOTAL DESKS",
        maxValue: selectedFloorInsights.reduce((a, e) => a + ((e.type === "DESK" && e.available) || 0), 0),
    };
    const roomOccupancy = {
        title: "Meeting Room Occupancy",
        progressBarTitle: "Occupied",
        currentValueTitle: "OCCUPIED ROOMS",
        currentValue: selectedFloorInsights.reduce((a, e) => a + ((e.type === "MEETING_ROOM" && e.occupied) || 0), 0),
        maxValueTitle: "TOTAL ROOMS",
        maxValue: selectedFloorInsights.reduce((a, e) => a + ((e.type === "MEETING_ROOM" && e.available) || 0), 0),
    };
    const sidePanelList = [floorUtilization, deskOccupancy, roomOccupancy];
    const legendList = [
        {
            label: "Vacant",
            borderColour: "#777B8F",
            fillColour: "#FFFFFF",
        },
        {
            label: "Occupied",
            borderColour: "#0064D2",
            fillColour: "#0099F8",
        },
    ];
    const toggleElements = ["Live View", "Configure"];
    const onUpdateView = (ToggleView: string): void => {
        if (ToggleView === toggleElements[0]) {
            setToggle(false);
        } else if (ToggleView === toggleElements[1]) {
            setToggle(true);
        }
    };
    return (
        <Dashboard>
            <BreadcrumbInfoContainer>
                <BreadcrumbsHeaderContainer>
                    <BreadCrumbNavigationBar textColor="#001E82" breadcrumbs={breadcrumbs} />
                    {renderConfigToggle && (
                        <ToggleContainer>
                            {
                                <Toggle
                                    elements={toggleElements}
                                    ToggleStyle={"Blue"}
                                    selected={"0"}
                                    onCallback={onUpdateView}
                                />
                            }
                        </ToggleContainer>
                    )}
                </BreadcrumbsHeaderContainer>
            </BreadcrumbInfoContainer>
            <WrldMapContainer>
                <WrldMap
                    building={{
                        buildingId: building.wrldId || "",
                        latLng: [building.address.geoPosition.latitude, building.address.geoPosition.longitude],
                    }}
                    planId={planId}
                    groups={group}
                    telemetry={telemetry}
                />
            </WrldMapContainer>
            {viewToggle ? (
                <SidePanelContainerConfigure>
                    <ConfigurePanel maxFloorCapacity={floorUtilization.maxValue} />
                </SidePanelContainerConfigure>
            ) : (
                <SidePanelContainer>
                    <SidePanel insightTileValues={sidePanelList} lastUpdated={new Date()} />
                </SidePanelContainer>
            )}
            <LegendContainer>
                <MapLegend legendList={legendList} />
            </LegendContainer>
        </Dashboard>
    );
});

export default RealTimeFloor;

const WrldMapContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 377px);
    height: 100%;
    ${media.mobile} {
        width: 100%;
    }
`;

const BreadcrumbInfoContainer = styled.div`
    height: 100%;
    white-space: nowrap;
    border-radius: 4px;
    z-index: 10;

    width: relative;
`;

const ToggleContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 450px;
    ${media.tablet || media.mobile} {
        display: none;
    }
`;

const BreadcrumbsHeaderContainer = styled.div`
    position: relative;
    padding: 12px 35px 11px 25px;
    border-radius: 4px;
    background-color: rgba(241, 245, 249, 0.901);
    display: flex;
    height: 25px;
`;

const SidePanelContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    overflow: auto;
    ${media.mobile} {
        display: none;
    }
    z-index: 10;
`;
const SidePanelContainerConfigure = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    ${media.mobile} {
        display: none;
    }
    z-index: 10;
`;

const LegendContainer = styled.div`
    position: absolute;
    left: 12px;
    bottom: 1rem;
`;
