import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Bell from "../../Icon/Bell";

export interface Props {
    to: string;
    selected?: boolean;
    count?: number;
}

class NotificationsTab extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public render(): React.ReactNode {
        const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): boolean => {
            let propagateEvent = true;

            if (this.props.selected) {
                event.preventDefault();
                propagateEvent = false;
            }
            return propagateEvent;
        };

        return (
            <StyledLink onClick={handleOnClick} to={this.props.to} selected={this.props.selected}>
                <TabIcon>
                    <Bell fill="#000000" hoverFill="#0099f8" count={this.props.count || 0} />
                </TabIcon>
            </StyledLink>
        );
    }
}

export default NotificationsTab;

const TabIcon = styled.div`
    width: 65px;
    height: 65px;
`;

const StyledLink = styled(Link)<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    user-select: none;
    border-bottom: ${(props): number => (props.selected ? 2 : 0)}px solid #0099f8;
    text-decoration: none;
    cursor: pointer;
    :visited {
        text-decoration: none;
        color: none;
    }
    :hover {
        cursor: pointer;
        text-decoration: none;
        color: #0099f8;
    }
`;
