import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
export interface Props {
    legendList: Array<{
        borderColour: string;
        fillColour: string;
        label: string;
    }>;
}

export default class MapLegend extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /**
     * Count Render
     */
    public render(): ReactNode {
        const { legendList } = this.props;

        const legendItems = legendList.map((e, pos) => (
            <ItemContainer key={pos.toString()}>
                <ItemIcon
                    borderColour={e.borderColour ? e.borderColour : "#0064D2"}
                    fillColour={e.fillColour ? e.fillColour : "#0099F8"}
                />
                <ItemTitle>{e.label ? e.label : "Label"}</ItemTitle>
            </ItemContainer>
        ));

        return <Container noItems={legendList.length}>{legendItems}</Container>;
    }
}

// For container height calc, 13px = icon+text height, 8px = icon+text top-margin, 12px = container padding
const Container = styled.div<{ noItems: number }>`
    height: ${(props): string => `calc((13px + 8px) * ${props.noItems})`};
    opacity: 0.9;
    border-radius: 4px;
    background-color: #f1f5f9;

    padding: calc(12px - 8px) 12px 12px 12px;
    display: inline-block;

    // Disable text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
`;

const ItemContainer = styled.div`
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 8px;
`;

const ItemIcon = styled.div<{ borderColour: string; fillColour: string }>`
    box-sizing: border-box;
    width: 13px;
    height: 13px;
    border: 1px solid ${(props): string => props.borderColour};
    border-radius: 6.5px;
    background-color: ${(props): string => props.fillColour};
`;

const ItemTitle = styled.div`
    color: #3e3e3e;
    font-size: 13px;
    height: 13px;

    margin-left: 8px;
`;
