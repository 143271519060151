import { makeAutoObservable, action, toJS } from "mobx";
import { SmartSpaces } from "../WebService/SmartSpaces";

export type StaticFeatures = "FloorToggle";
export type DynamicFeatures = "Navigation";
export type FeatureValues = Array<SmartSpaces.WebApp.Features.Navigation>;
export type Features = Record<StaticFeatures | DynamicFeatures, FeatureValues>;

export type EnvironmentFlags = "dev" | "staging" | "prod";

class FeatureStore {
    private _features: Features;

    constructor(features = {} as FeatureStore) {
        // Merge features
        this._features = { ...features } as Features;

        makeAutoObservable(this, {
            setFeature: action,
        });
    }

    public get getAllFeatures(): Features {
        return this._features;
    }

    public getFeature(flag: keyof Features): FeatureValues | null {
        return flag in this._features ? toJS(this._features[flag]) : null;
    }

    public setFeature(label: keyof Features, value: FeatureValues): void {
        this._features = Object.assign(this._features, { [label]: value });
    }

    public toJSON(): {} {
        return {
            ...this._features,
        };
    }
}

export default FeatureStore;
