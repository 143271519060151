import React, { FC, ReactNode, useEffect, useState, useReducer } from "react";
import { Dashboard, Management } from "../../../layouts";
import { observer } from "mobx-react";
import { useStore, UIView, UIStore } from "../../../services";
import CarouselCard from "../../../components/Tiles/CarouselCard/CarouselCard";
import DeskList from "../../../components/List/AssetList/AssetList";
import styled from "styled-components";
import { Building, Floor } from "../../../services/Store/UIStore";
import { toJS } from "mobx";
import { media } from "../../../config/Config";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import { ModalTypes } from "../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import { exportCSVFile, getNewDate } from "../../../services/Hooks/csvTools";
import { Throbber, ErrorBanner } from "../../../components";
import LoadableContainer from "../../../components/Common/LoadableContainer/LoadableContainer";
import { useParams } from "react-router";
import DisplayAssetModal from "./DisplayAssetModal/DisplayAssetModal";
import CarparkSpot from "./CarparkSpot/CarparkSpot";
import AddDesk from "./AddDesk/AddDesk";
import BulkUpload from "./AddDesk/BulkUpload";

interface ResourceListData {
    resourceId: number;
    name: string;
    resourceType: string;
    bookable: boolean;
    features: Array<Admin.ResourceFeature>;
}

enum BookingTypes {
    Unspecified,
    SiteVisit,
    Desk,
    Room,
    CollaborationSpace,
    CarSpace,
}

export interface BookingData {
    assetName: string;
    assetId: number;
    bookable: boolean;
    resourceType: string;
}

export interface CarSpotDetails {
    buildingId: number;
    floorId: number;
    name: string;
}

export interface EditedAssetDetails {
    assetName: string;
    resourceId: number | undefined;
    features: Array<Admin.ResourceFeature> | undefined;
    resourceType: string;
    peopleCapacity?: number;
}

export interface ToggleAssetModalProps {
    primaryButtonBackgroundColour: string;
    secondaryButtonTextColour: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    closeOnClickSecondary: boolean;
    primaryButtonTextColour: string;
    closeOnClickPrimary: boolean;
}

const toggleProps: ToggleAssetModalProps = {
    primaryButtonBackgroundColour: "#0064d2",
    secondaryButtonTextColour: "#001e82",
    primaryButtonText: "Confirm",
    secondaryButtonText: "Cancel",
    closeOnClickSecondary: true,
    primaryButtonTextColour: "#ffffff",
    closeOnClickPrimary: true,
};

interface Booking {
    email: string;
    bookingDate: string;
    assetType: string;
    assetName: string;
}

const csvHeaders = {
    email: "Email address",
    date: "Booking Date",
    type: "Type",
    name: "Name",
};

const assetTypes = ["Desks", "Meeting Rooms", "Car Parks"];

const Assets: FC = observer(() => {
    const store = useStore();

    const metaData = toJS(store.data.management.customerMetaData);
    const adminBuilding = toJS(store.data.management.adminBuilding);
    const adminFloor = toJS(store.data.management.adminFloor);
    const resources = toJS(store.data.management.customerResources);
    const deskFeatureGroups = toJS(store.data.management.customerDeskFeatureGroups);
    const roomFeatureGroups = toJS(store.data.management.customerRoomFeatureGroups);

    const assets = resources?.results;
    const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);
    const floorId = parseInt((useParams() as { floorId: string }).floorId);
    const hasLoaded =
        adminBuilding?.buildingId === buildingId && adminFloor?.floorId === floorId && resources?.pageSize;
    const buildingLabel: string = hasLoaded
        ? `${adminBuilding?.buildingName}, ${adminBuilding?.address.city}, ${adminBuilding?.address.country}`
        : "";
    const floorLabel: string = hasLoaded ? `${adminFloor?.floorName}` : "";

    const selectedBuilding = {
        id: buildingId,
        label: buildingLabel,
    };

    const selectedFloor = {
        id: floorId,
        label: floorLabel,
    };

    const [exportBookings, setExportBookings] = useState(false);
    const [exportCSV, setExportCSV] = useState<Array<Booking>>([
        { email: "", bookingDate: "", assetType: "", assetName: "" },
    ]);

    const [updateList, setUpdateList] = useReducer((x) => x + 1, 0);
    const [toggleBooking, setToggleBooking] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [numBookable, setNumBookable] = useState(0);
    const [bookingData, setBookingData] = useState<BookingData>({
        assetName: "",
        assetId: 0,
        bookable: false,
        resourceType: "",
    });

    const [isMetaDataLoading, setIsMetaDataLoading] = useState(true);
    const [showEditAssetModal, setShowEditAssetModal] = useState(false);
    const [editedAssetDetails, setEditedAssetDetails] = useState<EditedAssetDetails>({
        assetName: "",
        features: undefined,
        resourceId: undefined,
        resourceType: "",
        peopleCapacity: undefined,
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [editedAssetName, setEditedAssetName] = useState<string>("");
    const [isCloning, setIsCloning] = useState(false);
    const [displayDesks, setDisplayDesks] = useState(true);
    const [displayRooms, setDisplayRooms] = useState(true);
    const [displayCarSpaces, setDisplayCarSpaces] = useState(true);

    const [editedAssetCapacity, setEditedAssetCapacity] = useState<string>("");
    const [assetNameActive, setAssetNameActive] = useState(true);
    const [assetCapacityActive, setAssetCapacityActive] = useState(true);

    let showDeskSummary = false;
    let showCarSpaceSummary = false;
    let showRoomSummary = false;

    useEffect(() => {
        setIsMetaDataLoading(true);
        store.ui.setSelectedBuilding(selectedBuilding);
        store.ui.setSelectedFloor(selectedFloor);

        // Make the API call if there is parity
        if (adminBuilding.buildingId !== buildingId) {
            store.subscription.management.getAdminBuilding(buildingId);
        }

        // Make the API call if there is parity
        // E.g.selecting a different floor on the same building
        if (adminFloor.floorId !== floorId) {
            store.subscription.management.getAdminFloor(buildingId, floorId);
        }

        // Remove any selected location filtering.
        store.ui.setSelectedLocation("");

        // Start API calls (remove this comment after API is added)
        store.subscription.management
            .getAllCustomerMetaData(undefined, store.ui.selectedBuilding?.id, store.ui.selectedFloor?.id)
            .then(() => {
                setIsMetaDataLoading(false);
            });
        // End API calls (remove this comment after API is added)

        store.ui.setCurrentView(UIView.AssetManagement);
    }, [hasLoaded]);

    // -- Reset data on unmount for loading indicator until page load
    useEffect(() => {
        return () => {
            store?.data.management.setCustomerResources({ total: 0, page: 0, pageSize: 0, results: [] });
        };
    }, []);

    // -- get selected item from select list for filtering items
    const selectedItem = (item: string) => {
        switch (item) {
            case assetTypes[0]:
                setDisplayDesks(true);
                setDisplayRooms(false);
                setDisplayCarSpaces(false);
                break;

            case assetTypes[1]:
                setDisplayDesks(false);
                setDisplayRooms(true);
                setDisplayCarSpaces(false);
                break;

            case assetTypes[2]:
                setDisplayDesks(false);
                setDisplayRooms(false);
                setDisplayCarSpaces(true);
                break;

            default:
                setDisplayDesks(true);
                setDisplayRooms(true);
                setDisplayCarSpaces(true);
                break;
        }
    };

    // -- Drop down integration For Post MVP
    const generateDropDown = (): Array<string> => {
        const dropDownList: Array<string> = ["All Resources"];

        const resourceTypes: Array<string> = assets
            .map((asset) => {
                return asset.resourceType === "Desk"
                    ? assetTypes[0]
                    : asset.resourceType === "Room"
                    ? assetTypes[1]
                    : asset.resourceType === "CarSpace"
                    ? assetTypes[2]
                    : "";
            })
            .filter(
                (resourceType, index, resourceTypes) =>
                    resourceTypes.indexOf(resourceType) === index && resourceType !== "",
            )
            .sort((a, b) => assetTypes.indexOf(a) - assetTypes.indexOf(b));

        return dropDownList.concat(resourceTypes);
    };

    // -- Breadcrumb navigation
    const breadcrumbs = UIStore.generateBreadcrumbs(
        UIView.BuildingManagement,
        selectedBuilding ?? (store.ui.selectedBuilding as Building),
        selectedFloor ?? (store.ui.selectedFloor as Floor),
    );

    const addCarpark = (carParkName: string) => {
        const carSpot: CarSpotDetails = { buildingId, floorId, name: carParkName };
        const carSpotPromise = store.subscription.management.saveCarparkSpot(carSpot);
        carSpotPromise
            .then(() => {
                store.subscription.management.getCustomersResources(buildingId, floorId, true, "SVG");
                store.subscription.management.getAllCustomerMetaData(undefined, buildingId, floorId);
            })
            .catch(() => {
                setModalType(ModalTypes.Error);
            });
    };

    const generateSummaryCards = (): ReactNode => {
        let deskTotal = 0;
        let deskBookable = 0;
        let carSpaceTotal = 0;
        let carSpaceBookable = 0;
        let roomTotal = 0;
        let roomBookable = 0;

        metaData.forEach((resource) => {
            if (resource.resourceType === BookingTypes[BookingTypes.Desk]) {
                deskTotal = resource.total;
                deskBookable = resource.bookable;
                showDeskSummary = deskTotal > 0 && displayDesks ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.CarSpace]) {
                carSpaceTotal = resource.total;
                carSpaceBookable = resource.bookable;
                showCarSpaceSummary = carSpaceTotal > 0 && displayCarSpaces ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.Room]) {
                roomTotal = resource.total;
                roomBookable = resource.bookable;
                showRoomSummary = roomTotal > 0 && displayRooms ? true : false;
            }
        });

        return (
            <SummariesContainer showSummaries={showDeskSummary || showCarSpaceSummary || showRoomSummary}>
                <LoadableContainer isLoading={isMetaDataLoading}>
                    {showDeskSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Desks" capacity={deskTotal} population={deskBookable} />
                        </SummaryContainer>
                    )}

                    {showRoomSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Meeting Rooms" capacity={roomTotal} population={roomBookable} />
                        </SummaryContainer>
                    )}

                    {showCarSpaceSummary && (
                        <SummaryContainer>
                            <CarouselCard
                                headerText="Car Parks"
                                capacity={carSpaceTotal}
                                population={carSpaceBookable}
                            />
                        </SummaryContainer>
                    )}
                </LoadableContainer>
            </SummariesContainer>
        );
    };

    const onChangeBooking = (assetName: string, assetId: number, bookable: boolean, resourceType: string) => {
        setBookingData({ assetName, assetId, bookable, resourceType });
        setToggleBooking(true);
        setModalType(ModalTypes.Loading);

        const bookings = store.subscription.management.getCustomerBookings(buildingId, floorId, assetId);
        bookings.then((res) => {
            if (bookable === true) {
                setExportBookings(false);
                setModalType(ModalTypes.Bookable);
            } else if (bookable === false && res.results.length > 0) {
                const toExport = res.results.map((result) => {
                    const booking: Booking = {
                        email: result.user.email,
                        bookingDate: result.start,
                        assetType: resourceType,
                        assetName: assetName,
                    };
                    return booking;
                });

                toExport.sort(compareBookingDates).forEach((entry) => {
                    entry.assetType = entry.assetType === "Room" ? "Meeting Room" : entry.assetType;
                });

                setExportCSV(toExport);
                setExportBookings(true);
                setNumBookable(res.results.length);
                setModalType(ModalTypes.NonBookableWithBookings);
            } else if (bookable === false) {
                setExportBookings(false);
                setModalType(ModalTypes.NonBookable);
            }
        });
    };

    const closeEditAssetModal = () => {
        setEditedAssetDetails({
            assetName: "",
            features: undefined,
            resourceId: undefined,
            resourceType: "",
            peopleCapacity: undefined,
        });
        setShowEditAssetModal(false);
        setIsBtnDisabled(false);
        setEditedAssetName("");
        setEditedAssetCapacity("");
    };

    const editDeskClickHandler = (selectedAssetForEdit: EditedAssetDetails) => {
        setEditedAssetDetails(selectedAssetForEdit);
        deskFeatureGroups.length ? setModalType(ModalTypes.EditAsset) : setModalType(ModalTypes.Error);
        setShowEditAssetModal(true);
    };

    const editRoomClickHandler = (selectedAssetForEdit: EditedAssetDetails) => {
        setEditedAssetDetails(selectedAssetForEdit);
        roomFeatureGroups.length ? setModalType(ModalTypes.EditAsset) : setModalType(ModalTypes.Error);
        setShowEditAssetModal(true);
    };

    const editAssetSubmitHandler = () => {
        /* Check for asset name change */
        const editedAssetDetailsCopy = { ...editedAssetDetails };
        const isNameChanged = editedAssetDetails.assetName !== editedAssetName;
        editedAssetDetailsCopy.assetName = isNameChanged ? editedAssetName : "";
        const isCapacityChanged = editedAssetDetails.peopleCapacity !== parseInt(editedAssetCapacity);
        editedAssetDetailsCopy.peopleCapacity = isCapacityChanged ? parseInt(editedAssetCapacity) : undefined;

        if (editedAssetDetailsCopy.features && editedAssetDetailsCopy.features[0]?.name === "People Capacity") {
            editedAssetDetailsCopy.features?.splice(0, 1);
        }

        setModalType(ModalTypes.EditAssetLoading);
        const updatedAssetPromise = store.subscription.management.submitEditedAssetDetails(editedAssetDetailsCopy);
        updatedAssetPromise
            .then((updatedAsset) => {
                store.data.management.updateCustomerResources(updatedAsset);
                closeEditAssetModal();
            })
            .catch(() => {
                setModalType(ModalTypes.Error);
            });
    };

    /**
     * common method for both checkbox and radio button onChange
     * @param optionNames for radio will be a single value in array
     * @param featureGroupName
     */
    const updateSelectedAssetFeature = (optionNames: Array<string>, featureGroupName: string) => {
        // clear existing state values of selected featureGroup
        const editedAssetDetailsCopy = { ...editedAssetDetails };
        const updatedFeatures = editedAssetDetailsCopy.features?.filter(
            (feature) => feature.name?.toLowerCase() !== featureGroupName.toLowerCase(),
        );

        // update the selected values in state
        const selectedFeatureGroup =
            editedAssetDetails.resourceType === "Desk"
                ? deskFeatureGroups.find((assetFeatureGroup) => assetFeatureGroup.name === featureGroupName)
                : editedAssetDetails.resourceType === "Room"
                ? roomFeatureGroups.find((assetFeatureGroup) => assetFeatureGroup.name === featureGroupName)
                : undefined;

        const featureOptions = selectedFeatureGroup?.featureOptions.filter((featureOption) =>
            optionNames.includes(featureOption.optionName),
        );

        featureOptions &&
            featureOptions.forEach((featureOption) => {
                updatedFeatures?.push({
                    icon: featureOption.icon,
                    optionId: featureOption.optionId,
                    optionName: featureOption.optionName,
                    name: featureGroupName,
                });
            });

        editedAssetDetailsCopy.features = updatedFeatures;
        setEditedAssetDetails(editedAssetDetailsCopy);
    };

    const handleAssetNameChange = (assetName: string) => {
        if (assetName && assetName.length <= 35) {
            setEditedAssetName(assetName);
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
        }
    };

    const handleAssetCapacityChange = (capacity: string) => {
        if (!isNaN(Number(capacity)) && assetCapacityActive && assetNameActive && capacity.length <= 35) {
            setEditedAssetCapacity(capacity);
            setAssetCapacityActive(true);
            setIsBtnDisabled(false);
            setAssetNameActive(true); // To remove
        } else if (!isNaN(Number(capacity)) && !assetNameActive && capacity.length <= 35) {
            setEditedAssetCapacity(capacity);
            setAssetCapacityActive(true);
            setIsBtnDisabled(true);
        } else {
            setAssetCapacityActive(false);
            setIsBtnDisabled(true);
        }
    };

    const onClearHandler = (featureGroupName: string) => {
        const editedAssetDetailsCopy = { ...editedAssetDetails };
        const featureGroupIndex = editedAssetDetails.features?.findIndex(
            (feature) => feature.name === featureGroupName,
        );
        featureGroupIndex !== undefined &&
            featureGroupIndex >= 0 &&
            editedAssetDetailsCopy.features?.splice(featureGroupIndex, 1);
        setEditedAssetDetails(editedAssetDetailsCopy);
    };

    const getAssetFeatures = (resourceType: string): Array<Admin.AssetFeatureGroups> => {
        return resourceType === "Desk"
            ? deskFeatureGroups
            : resourceType === "Room"
            ? roomFeatureGroups
            : deskFeatureGroups;
    };

    const displayModal = (modalType: number, numBookings: number, bookingData: BookingData): ReactNode => (
        <DisplayAssetModal
            assetFeatureGroups={getAssetFeatures(editedAssetDetails.resourceType)}
            bookingData={bookingData}
            modalType={modalType}
            numBookings={numBookings}
            editedAssetDetails={editedAssetDetails}
            toggleProps={toggleProps}
            isSubmitDisabled={isBtnDisabled}
            updateSelectedAssetFeature={updateSelectedAssetFeature}
            onClearHandler={onClearHandler}
            modalConfirm={modalConfirm}
            modalCancel={modalCancel}
            onChangeCheckbox={onChangeCheckbox}
            editAssetSubmitHandler={editAssetSubmitHandler}
            closeEditAssetModal={closeEditAssetModal}
            handleAssetNameChange={(value) => handleAssetNameChange(value)}
            handleAssetCapacityChange={(capacity) => handleAssetCapacityChange(capacity)}
        />
    );

    const onChangeCheckbox = (checked: boolean): void => {
        setExportBookings(checked);
    };

    const compareBookingDates = (a: Booking, b: Booking): number => {
        if (a.bookingDate < b.bookingDate) return -1;
        if (a.bookingDate > b.bookingDate) return 1;
        return 0;
    };

    const modalConfirm = () => {
        if (exportBookings && ModalTypes.NonBookableWithBookings) {
            const fileName = `CancelledBookingFor${exportCSV[0].assetName}_${getNewDate()}`;
            exportCSVFile(exportCSV, fileName, csvHeaders);
        }

        const assetBooked = setSelectedAsset(
            bookingData.resourceType,
            buildingId,
            floorId,
            bookingData.assetId,
            bookingData.bookable,
        );

        // Update the customer Metadata instead of calling api.
        const latestMetaData: Array<Admin.MetaData> = new Array<Admin.MetaData>();
        const timeStamp = Date.now();

        metaData.forEach((resource) => {
            if (
                Object.values(BookingTypes)
                    .filter((o) => typeof o === "string")
                    .includes(bookingData.resourceType)
            ) {
                bookingData.bookable ? resource.bookable++ : resource.bookable--;
            }
            latestMetaData.push(resource);
        });

        store.subscription.management.getCustomersResources(buildingId, floorId, true, "SVG");
        store.data.management.setCustomerMetaDataData(latestMetaData, timeStamp);

        assetBooked
            .catch(() => {
                store.subscription.management.getAllCustomerMetaData(
                    undefined,
                    store.ui.selectedBuilding?.id,
                    store.ui.selectedFloor?.id,
                );

                setUpdateList();
            })
            .finally(() => {
                setToggleBooking(false);
            });
    };

    const modalCancel = () => {
        setUpdateList();
        setToggleBooking(false);
        closeEditAssetModal();
    };

    const setSelectedAsset = async (
        resourceType: string,
        buildingId: number,
        floorId: number,
        assetId: number,
        status: boolean,
    ): Promise<boolean> => {
        const assetBooked = await store.subscription.management.setAssetBookable(
            resourceType,
            buildingId,
            floorId,
            assetId,
            status,
        );
        return assetBooked;
    };
    const cloneAssetHandler = (resourceId: number) => {
        setIsCloning(true);
        setModalType(ModalTypes.CloneAssetLoading);
        const carSpotPromise = store.subscription.management.cloneDesk(resourceId);
        carSpotPromise
            .then(() => {
                const fetchData = store.subscription.management.getCustomersResources(buildingId, floorId, true, "SVG");
                fetchData
                    .then(() => {
                        setIsCloning(false);
                    })
                    .catch(() => {
                        setModalType(ModalTypes.Error);
                    });
            })
            .catch(() => {
                setModalType(ModalTypes.Error);
            });
    };

    const generateAssetList = (assets: Array<Admin.Resource> = []): ReactNode => {
        const resourceData: Array<ResourceListData> = assets.map(
            ({ resourceId, name, resourceType, bookable, features, peopleCapacity }) => ({
                resourceId,
                name,
                resourceType,
                bookable,
                features,
                peopleCapacity,
            }),
        );

        const deskResourceData: Array<ResourceListData> = resourceData.filter(
            ({ resourceType }) => resourceType === BookingTypes[BookingTypes.Desk],
        );
        const roomResourceData: Array<ResourceListData> = resourceData.filter(
            ({ resourceType }) => resourceType === BookingTypes[BookingTypes.Room],
        );
        const carSpaceResourceData: Array<ResourceListData> = resourceData.filter(
            ({ resourceType }) => resourceType === BookingTypes[BookingTypes.CarSpace],
        );

        return (
            <ListContainer>
                <ActionContainer>
                    <AddDesk />
                    <BulkUpload buildingId={selectedBuilding.id} floorId={selectedFloor.id} />
                    <CarparkSpot onSave={addCarpark} />
                </ActionContainer>
                <AssetListContainer>
                    {deskResourceData.length > 0 && displayDesks && (
                        <>
                            <AssetListHeader>
                                <AssetListHeaderText>Desk Settings</AssetListHeaderText>
                            </AssetListHeader>

                            <DeskList
                                listHeader="Asset List"
                                buildingId={selectedBuilding.id}
                                floorId={selectedFloor.id}
                                listData={deskResourceData}
                                onChangeBookable={onChangeBooking}
                                onEditAsset={editDeskClickHandler}
                                onCloneAsset={cloneAssetHandler}
                            />
                        </>
                    )}
                </AssetListContainer>

                <Spacer />

                {roomResourceData.length > 0 && displayRooms && (
                    <AssetListContainer>
                        <AssetListHeader>
                            <AssetListHeaderText>Meeting Room Settings</AssetListHeaderText>
                        </AssetListHeader>
                        <DeskList
                            listHeader="Asset List"
                            buildingId={selectedBuilding.id}
                            floorId={selectedFloor.id}
                            listData={roomResourceData}
                            onChangeBookable={onChangeBooking}
                            onEditAsset={editRoomClickHandler}
                        />
                    </AssetListContainer>
                )}

                <Spacer />

                {carSpaceResourceData.length > 0 && displayCarSpaces && (
                    <AssetListContainer>
                        <AssetListHeader>
                            <AssetListHeaderText>Car Park Settings</AssetListHeaderText>
                        </AssetListHeader>
                        <DeskList
                            listHeader="Asset List"
                            buildingId={selectedBuilding.id}
                            floorId={selectedFloor.id}
                            listData={carSpaceResourceData}
                            onChangeBookable={onChangeBooking}
                            onEditAsset={editDeskClickHandler}
                        />
                    </AssetListContainer>
                )}

                {assets.length === 0 && <></>}
            </ListContainer>
        );
    };

    return (
        <Dashboard>
            {(toggleBooking || showEditAssetModal || isCloning) && displayModal(modalType, numBookable, bookingData)}
            {hasLoaded ? (
                <Management
                    key={updateList}
                    breadcrumbs={breadcrumbs}
                    summaries={generateSummaryCards()}
                    list={generateAssetList(assets)}
                    selectListItems={generateDropDown()}
                    onClickSelect={selectedItem}
                    disableDropdown={false}
                    displayBanner={!adminFloor.bookable}
                    displaySummaries={showDeskSummary || showCarSpaceSummary || showRoomSummary}
                    buildingId={selectedBuilding.id}
                    floorId={selectedFloor.id}
                    bannerComp={
                        <ErrorBanner
                            headerElement={
                                <>
                                    <span>{`This level is set to `}</span>
                                    <Highlight>{`not bookable `}</Highlight>
                                    <span>and will not be available in the myWorkplace app</span>
                                </>
                            }
                            infoElement={
                                <>
                                    {`Any resources set to bookable will not be available in the myWorkplace app until this level is set to bookable. You will need to go back to the building view to change this `}
                                    <span>setting.</span>
                                </>
                            }
                        />
                    }
                />
            ) : (
                <Container>
                    <Throbber />
                </Container>
            )}
        </Dashboard>
    );
});

export default Assets;

const AssetListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 60px;
`;

const AssetListHeaderText = styled.span`
    font-weight: bold;
    font-size: 20px;
`;

const Spacer = styled.div`
    clear: both;
`;

const AssetListContainer = styled.div`
    margin-bottom: 50px;
`;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;

const SummariesContainer = styled.div<{ showSummaries?: boolean }>`
    display: flex;
    margin-top: ${(props): string => (props.showSummaries ? "32px" : "0px")};
    height: ${(props): string => (props.showSummaries ? "214px" : "0px")};
`;
const SummaryContainer = styled.div`
    width: 100%;
    max-width: 50%;
    padding-right: 8px;
    &:last-child {
        padding-right: 0;
    }
`;

const ListContainer = styled.div`
    width: 1080px;
    ${media.tablet} {
        width: 530px;
    }
`;

const Highlight = styled.span`
    color: #d0021b;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
