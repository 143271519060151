import React, { FC, ReactNode } from "react";
import { RootStore as Store, AppConfig as Config } from "../../services";
import appContext from "./AppContext";

interface Props {
    children: ReactNode;
}

const AppContextProvider: FC<Props> = ({ children }: Props) => {
    const store = new Store();
    const config = new Config();
    const context = { store, config };

    return <appContext.Provider value={context}>{children}</appContext.Provider>;
};

export default AppContextProvider;
