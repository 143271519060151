import React, { FC, useRef, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import ToggleAssetModal from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import { ModalText } from "../DisplayAssetModal/DisplayAssetModal";
import { useStore } from "../../../../services";
import {
    DownloadTemplate,
    ExtendedPublishIcon,
    ThrobberWrapper,
    UploadText,
    UploadWrapper,
    WarningText,
} from "../CarparkUser/CarparkUser";
import { UploadButton } from "../../Floors/FloorPlan/FloorPlan";
import { Throbber } from "../../../../components";
import { exportCSVFile, getNewDate } from "../../../../services/Hooks/csvTools";
import { CheckCircle } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import { Link } from "react-router-dom";

export interface Props {
    buildingId: number;
    floorId: number;
}

const BulkUpload: FC<Props> = observer((props: Props) => {
    const { buildingId, floorId } = props;

    const sampleTemplate = "/assets/SampleTemplates/Desk/template.csv";

    const [showModal, setShowModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isError, setIsError] = useState(false);
    const [CSVData, setCSVData] = React.useState<File | null>();
    const [invalidMessage, setInvalidMessage] = useState<string>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [fileName, setFileName] = useState<string>();
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [uploadErrorList, setUploadErrorList] = useState<Array<number>>([]);

    const store = useStore();

    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const modalCancel = () => {
        setShowModal(false);
        setIsError(false);
        setFileName("");
        setIsUploadSuccess(false);
        setIsLoading(false);
        setUploadErrorList([]);
    };
    const handleBulkUpload = () => {
        setShowModal(true);
    };

    const uploadCSVHandler = () => {
        hiddenFileInput.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const fileUploaded = target?.files ? target?.files[0] : null;
        setInvalidMessage("");
        setFileName("");
        if (fileUploaded) {
            if (fileUploaded.type !== "text/csv") {
                setInvalidMessage("Invalid file type");
            } else if (fileUploaded.size > 1000000) {
                setInvalidMessage("File must be below 1 MB");
            } else {
                setFileName(fileUploaded.name);

                handleUpload(fileUploaded);
            }
        }
        target.value = "";
    };

    const handleUpload = (data: File | null): void => {
        setCSVData(data);
        if (data) {
            setIsSubmitDisabled(false);
        }
    };

    const csvHeaders = {
        resourceId: "Resource Id",
    };
    const downloadCSVReport = () => {
        const name = `failedreport-${getNewDate()}`;
        const toExport = uploadErrorList.map((result) => {
            const booking = {
                resourceId: result,
            };
            return booking;
        });
        exportCSVFile(toExport, name, csvHeaders);
    };

    const handleSave = (): void => {
        setIsLoading(true);
        if (CSVData) {
            store.subscription.management
                .uploadDeskCSV(CSVData)
                .then((res) => {
                    setIsLoading(false);

                    if (res.invalidResourceIds?.length > 0) {
                        setUploadErrorList(res.invalidResourceIds);
                    } else {
                        setIsUploadSuccess(true);
                        store.subscription.management.getCustomersResources(buildingId, floorId, true, "SVG");
                        store.subscription.management.getAllCustomerMetaData(undefined, buildingId, floorId);
                    }
                })
                .catch((e) => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsSubmitDisabled(true);
                    setIsLoading(false);
                });
        }
    };
    const isScreenLarge = window.matchMedia("(min-height: 720px)").matches;

    const getError = () => (
        <>
            <ContentWrapper>
                <ErrorIcon fontSize={"medium"} htmlColor="#a60014" />
                <MessageText>Error in processing the CSV file.</MessageText>
            </ContentWrapper>
            {uploadErrorList.length > 0 ? (
                <UploadButton onClick={downloadCSVReport}>Download report</UploadButton>
            ) : (
                <>
                    <Label>Invalid Desk ids</Label>
                    {uploadErrorList.map((resourceId) => (
                        <p key={resourceId}>Resource Id : {resourceId}</p>
                    ))}
                </>
            )}
        </>
    );

    const getSuccess = () => {
        return (
            <ContentWrapper>
                <CheckCircle htmlColor="#4B7900" fontSize="small" />
                <MessageText>Desks are imported/updated successfully</MessageText>
            </ContentWrapper>
        );
    };

    const modal = (
        <ToggleAssetModal
            title={isError ? "Sorry, an error has occurred" : "Bulk Upload"}
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={isScreenLarge ? "608px" : "518px"}
            width="712px"
            contentWidth="632px"
            contentHeight={isScreenLarge ? "446px" : "326px"}
            onClickPrimary={handleSave}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
            isButtonDisabled={isSubmitDisabled}
        >
            {isError ? (
                <ModalText>Please try again later.</ModalText>
            ) : isLoading ? (
                <ThrobberWrapper>
                    <Throbber />
                </ThrobberWrapper>
            ) : uploadErrorList.length ? (
                getError()
            ) : isUploadSuccess ? (
                getSuccess()
            ) : (
                <>
                    <UploadWrapper>
                        <UploadButton onClick={uploadCSVHandler}>
                            <ExtendedPublishIcon htmlColor="#001e82" />
                            <UploadText>Upload CSV</UploadText>
                        </UploadButton>
                        <WarningText>{invalidMessage}</WarningText>
                        <span>{fileName}</span>
                    </UploadWrapper>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e)}
                    />

                    <DownloadTemplate>
                        <Link to={sampleTemplate} target="_blank" download>
                            Download CSV template
                        </Link>
                    </DownloadTemplate>
                </>
            )}
        </ToggleAssetModal>
    );

    return (
        <>
            {showModal && modal}
            <ActionButton onClick={handleBulkUpload}>
                <span>
                    <ExtendedPublishIcon style={{ paddingRight: 0 }} />
                    &nbsp;
                </span>
                Bulk Upload Desks
            </ActionButton>
        </>
    );
});

export default BulkUpload;

const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: none;
    color: #001e82;
    margin-right: 15px;
`;

const Label = styled.span`
    font-size: 14px;
    font-weight: bold;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const MessageText = styled.p`
    padding-left: 10px;
    padding-top: 2px;
    font-size: 16px;
`;
