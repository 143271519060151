import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";

export interface Option {
    label: string;
    value: string;
}

export interface Props {
    name: string;
    defaultSelection?: string;
    onChange: (value: string) => void;
    options: Array<Option>;
    width?: string;
}

const RadioButtonGroup: React.FC<Props> = (props: Props) => {
    const { name, onChange, options, defaultSelection, width } = props;
    const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultSelection);

    useEffect(() => {
        setSelectedOption(defaultSelection);
    }, [defaultSelection]);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e;
        setSelectedOption(value);
        onChange(value);
    };

    return (
        <FieldSet>
            {options &&
                options.map((option, index) => {
                    const { label, value } = option;
                    return (
                        <RadioWrapper key={index} width={width}>
                            <Label>
                                <LabelText>{label}</LabelText>
                                <Input
                                    name={name}
                                    type="radio"
                                    checked={value === selectedOption}
                                    value={value}
                                    onChange={onChangeHandler}
                                />
                                <Radio />
                            </Label>
                        </RadioWrapper>
                    );
                })}
        </FieldSet>
    );
};

export default RadioButtonGroup;

const FieldSet = styled.fieldset`
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
`;

const Label = styled.label`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin: 14px 16px 0 0;
    width: fit-content;
    cursor: pointer;

    &:hover {
        outline: none;
        border-color: #1976d2;
    }

    &:focus-within:focus-visible {
        outline: 2px #1976d2 solid;
        outline-offset: 6px;
        border-radius: 4px;
    }
`;

const Input = styled.input.attrs({ type: "radio" })`
    opacity: 0;
`;

const LabelText = styled.span`
    &:hover {
        color: #1976d2;
    }
`;

const Radio = styled.span`
    background: #ffffff;
    border-radius: 100%;
    border: 2px solid #333333;
    width: 1rem;
    height: 1rem;
    transition: all 250ms ease;

    ${Input}:checked + & {
        background-color: #1976d2;
        box-shadow: inset 0 0 0 4px #ffffff;
        border-color: #1976d2;
    }

    ${Input}:focus + &,
    ${Input}:hover + & {
        outline: none;
        border-color: #1976d2;
    }
`;

const RadioWrapper = styled.div<{ width?: string }>`
    width: ${(props): string => (props.width ? props.width : "unset")};
`;
