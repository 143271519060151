import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ToggleAssetModal from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import { useStore } from "../../../../services";
import AssetName from "../../Assets/AssetName/AssetName";
import { ModalText, ThrobberWrapper } from "../../Assets/DisplayAssetModal/DisplayAssetModal";
import { EditAssetContainer, EditIconButton } from "../../../../components/List/AssetListItem/AssetListItem";
import EditIcon from "@material-ui/icons/Edit";
import { toJS } from "mobx";
import CreatableSelect from "react-select/creatable";
import { Admin } from "../../../../services/WebService/Admin/ApiDefinition";
import { Throbber } from "../../../../components";
import { StylesConfig } from "react-select";

export interface Props {
    editedBuildingId?: number;
    isEdit?: boolean;
}

type OptionType = {
    label: string;
    value: string;
};

interface BuildingReqParam {
    [key: string]: string | number | boolean;
}

type IsMulti = false;

const ManageBuilding: FC<Props> = observer((props: Props) => {
    const { isEdit, editedBuildingId } = props;

    const store = useStore();
    const apiResponse = toJS(store.data.management.adminBuildings);
    const buildings: Array<Admin.Building> = apiResponse?.results;

    const timeZoneData = toJS(store.data.management.timeZoneData) ?? [];

    const [formData, setFormData] = useState<Admin.BuildingReqParam>({
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        country: "",
        postCode: "",
        latitude: "",
        longitude: "",
        buildingId: 0,
        buildingName: "",
        timezoneId: 1,
        bookable: true,
    });

    useEffect(() => {
        const selectedBuildingList =
            editedBuildingId && buildings ? buildings.filter(({ buildingId }) => buildingId === editedBuildingId) : [];
        const [selectedBuilding] = selectedBuildingList;

        setFormData({
            streetNumber: selectedBuilding?.address.streetNumber ?? "",
            streetName: selectedBuilding?.address.streetName ?? "",
            city: selectedBuilding?.address.city ?? "",
            state: selectedBuilding?.address.state ?? "",
            country: selectedBuilding?.address.country ?? "",
            postCode: selectedBuilding?.address.postCode ?? "",
            latitude: selectedBuilding?.address.latitude ?? "",
            longitude: selectedBuilding?.address.longitude ?? "",
            buildingId: editedBuildingId ?? 0,
            timezoneId: selectedBuilding?.timeZone?.timezoneId ?? 1,
            buildingName: selectedBuilding?.buildingName ?? "",
            bookable: selectedBuilding?.bookable ?? true,
        });
    }, [isEdit]);
    const customerLocations = toJS(store.data.management.customerLocationStates);

    const generateDropdownList = (data: Array<string>) =>
        data.map((metadata: string) => ({
            label: metadata,
            value: metadata,
        }));

    const stateList = generateDropdownList(customerLocations);
    const timezoneList = timeZoneData.map((metadata) => ({
        label: metadata.localeName,
        value: JSON.stringify(metadata.timezoneId),
    }));
    const [showModal, setShowModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleAddBuilding = (): void => {
        setShowModal(true);
        if (!isEdit) {
            setFormData({
                streetNumber: "",
                streetName: "",
                city: "",
                state: "",
                country: "",
                postCode: "",
                latitude: "",
                longitude: "",
                buildingId: 0,
                buildingName: "",
                timezoneId: 1,
                bookable: true,
            });
        }
    };

    const clearModal = () => {
        setShowModal(false);
        setIsLoading(false);
        setIsError(false);
    };

    const modalCancel = () => {
        clearModal();
    };

    const handleOnChange = (type: string, value: string, required = false) => {
        const formDataDummy: BuildingReqParam = formData;
        formDataDummy[type] = value;
        const { buildingName, streetNumber, streetName, state, city, postCode, country } = formDataDummy;
        const isValid = buildingName && streetNumber && streetName && state && city && postCode && country;

        if (!isValid || value.length > 50) {
            setIsSubmitDisabled(true);
        } else {
            setFormData({ ...formData, [type]: type === "timezoneId" ? parseInt(value) : value });

            setIsSubmitDisabled(false);
        }
    };

    const saveBuilding = () => {
        setIsLoading(true);
        const updatedAssetPromise = store.subscription.management.updateBuilding(formData);
        updatedAssetPromise
            .then(() => {
                setIsLoading(false);
                store.subscription.management.getAllAdminBuildings();
                setShowModal(false);
                clearModal();
            })
            .catch(() => {
                setIsError(true);
                setIsSubmitDisabled(true);
                clearModal();
            });
    };

    const isScreenLarge = window.matchMedia("(min-height: 720px)").matches;

    const customStyles: StylesConfig<OptionType, IsMulti> = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        menuList: (provided) => ({
            ...provided,
            height: "130px",
        }),
        option: (defaultStyles) => ({
            ...defaultStyles,
            breakInside: "avoid-column",
            visibility: "visible",
            paddingBottom: "11px",
            paddingTop: "5px",
            backgroundColor: "#ffffff",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
            overflow: "hidden",
        }),
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
    };

    const renderTextFields = (
        label: string,
        isRequired: boolean,
        key:
            | "buildingName"
            | "country"
            | "city"
            | "streetName"
            | "streetNumber"
            | "postCode"
            | "latitude"
            | "longitude",
        charLimit = 50,
        helperText?: string,
    ) => (
        <InputTextWrapper>
            <AssetName
                label={label}
                maxChar={charLimit}
                value={formData[key]}
                onNameChange={(value) => handleOnChange(key, value.toString(), true)}
                assetTypeLabel={""}
                width={250}
                isRequired={isRequired}
            />
        </InputTextWrapper>
    );

    const modal = (
        <ToggleAssetModal
            title={isError ? "Sorry, an error has occurred" : isEdit ? "Edit Building" : "Add Building"}
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={isScreenLarge ? "680px" : "518px"}
            width="900px"
            contentWidth="800px"
            contentHeight={isScreenLarge ? "446px" : "326px"}
            onClickPrimary={saveBuilding}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
            isButtonDisabled={isSubmitDisabled}
        >
            {isError ? (
                <ModalText>Please try again later.</ModalText>
            ) : isLoading ? (
                <ThrobberWrapper>
                    <Throbber />
                </ThrobberWrapper>
            ) : (
                <ContentWrapper>
                    <div>
                        {renderTextFields("Building name", true, "buildingName")}
                        <SelectWrapper>
                            <Label>State *</Label>
                            <CreatableSelect
                                name="state"
                                allowCreateWhileLoading
                                onChange={(state) => handleOnChange("state", state?.value ?? "")}
                                menuPosition="absolute"
                                styles={customStyles}
                                isClearable
                                options={stateList}
                                defaultValue={stateList.filter((d) => d.label === formData.state)}
                                filterOption={(option, inputValue) =>
                                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 &&
                                    !formData.state.includes(option.label)
                                }
                            />
                        </SelectWrapper>
                        {renderTextFields("Street Name", true, "streetName")}
                        {renderTextFields("Country", true, "country")}

                        {renderTextFields("Latitude", false, "latitude", 10)}
                        <SectionPadding />
                        <SelectWrapper>
                            <Label htmlFor="flexCheckDefault">Bookable</Label>
                            <CheckedBox
                                aria-checked={formData.bookable}
                                tabIndex={0}
                                onChange={() => setFormData({ ...formData, bookable: !formData.bookable })}
                                type="checkbox"
                                value="bookable"
                                checked={formData.bookable}
                            />
                        </SelectWrapper>
                    </div>
                    <div>
                        {renderTextFields("City", true, "city")}
                        <SelectWrapper>
                            <Label>Time Zone </Label>
                            <CreatableSelect
                                name="timezone"
                                allowCreateWhileLoading={false}
                                onChange={(timeZone) => handleOnChange("timezoneId", timeZone?.value ?? "")}
                                menuPosition="absolute"
                                styles={customStyles}
                                isClearable
                                options={timezoneList}
                                defaultValue={timezoneList.filter((d) => parseInt(d.value) === formData.timezoneId)}
                                filterOption={(option, inputValue) =>
                                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 &&
                                    !JSON.stringify(formData.timezoneId).includes(option.value)
                                }
                            />
                        </SelectWrapper>
                        {renderTextFields("Street Number", true, "streetNumber")}
                        {renderTextFields("Postal Code", true, "postCode")}

                        {renderTextFields("Longitude", false, "longitude", 10)}
                    </div>
                </ContentWrapper>
            )}
        </ToggleAssetModal>
    );

    return (
        <>
            {showModal && modal}
            {isEdit ? (
                <EditAssetContainer>
                    <EditIconButton onClick={handleAddBuilding}>
                        <EditIcon fontSize="small" aria-label="edit asset" />
                    </EditIconButton>
                </EditAssetContainer>
            ) : (
                <ActionButton onClick={handleAddBuilding}>
                    <span>
                        <AddCircleOutlineIcon />
                        &nbsp;
                    </span>
                    Add Building
                </ActionButton>
            )}
        </>
    );
});

export default ManageBuilding;

const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: none;
    color: #001e82;
    margin-right: 15px;
`;

const Label = styled.label`
    color: #0000008a;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    padding: 3px 10px 0;
`;

export const InputTextWrapper = styled.div`
    & > div {
        margin-top: 0;
    }
    & > div > div {
        width: 454px;
        margin: 0;
    }
`;

export const SelectWrapper = styled.div`
    & > div {
        margin-top: 10px;
    }
    & > div > div {
        width: 250px;
    }
    margin: 10px 0;
`;

const CheckedBox = styled.input`
    height: 18px;
    width: 18px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const SectionPadding = styled.div`
    height: 15px;
`;
