import React, { FC, ReactNode, useEffect, useState } from "react";
import { Dashboard, Management } from "../../../layouts";
import { observer } from "mobx-react";
import { useStore, UIView } from "../../../services";
import CarouselCard from "../../../components/Tiles/CarouselCard/CarouselCard";
import OccupancyList from "../../../components/List/OccupancyList/OccupancyList";
import styled from "styled-components";
import BusinessIcon from "@material-ui/icons/Business";
import { toJS } from "mobx";
import { Admin } from "../../../services/WebService/Admin/ApiDefinition";
import { Throbber } from "../../../components";
import LoadableContainer from "../../../components/Common/LoadableContainer/LoadableContainer";
import ManageBuilding from "./ManageBuilding/ManageBuilding";

interface ListData {
    id: number;
    listItem: {
        label: string;
        deskPopulation?: number;
        deskCapacity?: number;
        meetingRoomPopulation?: number;
        meetingRoomCapacity?: number;
        carparkPopulation?: number;
        carparkCapacity?: number;
        urlPath?: string;
    };
}

enum BookingTypes {
    Unspecified,
    SiteVisit,
    Desk,
    Room,
    CollaborationSpace,
    CarSpace,
}

const Buildings: FC = observer(() => {
    const breadcrumbs = [{ to: "/building-management", label: "Buildings" }];
    const store = useStore();

    const apiResponse = toJS(store.data.management.adminBuildings);
    const buildings = apiResponse?.results;
    let listData: Array<ListData> = [];
    const metaData = toJS(store.data.management.customerMetaData);
    const customerLocations = toJS(store.data.management.customerLocationStates);
    const hasLoaded = buildings?.length > 0 && metaData?.length > 0 && customerLocations?.length > 0;
    const allLocations = ["All locations"].concat(customerLocations);
    let showDeskSummary = false;
    let showCarSpaceSummary = false;
    let showRoomSummary = false;

    const [isMetaDataLoading, setIsMetaDataLoading] = useState(true);
    const [isCancelled, setIsCancelled] = useState(false);

    useEffect(() => {
        if (!isCancelled) {
            setIsMetaDataLoading(true);
            // Set Default values in the store to clear out any prior selected data.
            store.ui.setSelectedLocation("");
            store.ui.setSelectedBuilding();

            store.ui.setCurrentView(UIView.BuildingManagement);
            store.subscription.management.getAllCustomerMetaData().then(() => {
                setIsMetaDataLoading(false);
            });
        }
        return () => {
            setIsCancelled(true);
        };
    }, [hasLoaded, store.ui]);

    // -- get selected item from select list for filtering items
    const selectedItem = (item: string) => {
        // No need to update when we have already set this location.
        if (store.ui.selectedLocation === "" && item === allLocations[0]) {
            return;
        }
        if (store.ui.selectedLocation === item) {
            return;
        }
        setIsMetaDataLoading(true);

        store.ui.setSelectedLocation(item);

        if (item === "All locations") {
            store.subscription.management.getAllAdminBuildings();
            store.subscription.management.getAllCustomerMetaData().then(() => {
                setIsMetaDataLoading(false);
            });
        } else {
            store.subscription.management.getAllAdminBuildings(item);
            store.subscription.management.getAllCustomerMetaData(item).then(() => {
                setIsMetaDataLoading(false);
            });
        }
    };

    const generateSummaryCards = (): ReactNode => {
        let deskTotal = 0;
        let deskBookable = 0;
        let carSpaceTotal = 0;
        let carSpaceBookable = 0;
        let roomTotal = 0;
        let roomBookable = 0;

        metaData.forEach((resource) => {
            if (resource.resourceType === BookingTypes[BookingTypes.Desk]) {
                deskTotal = resource.total;
                deskBookable = resource.bookable;
                showDeskSummary = deskTotal > 0 ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.CarSpace]) {
                carSpaceTotal = resource.total;
                carSpaceBookable = resource.bookable;
                showCarSpaceSummary = carSpaceTotal > 0 ? true : false;
            } else if (resource.resourceType === BookingTypes[BookingTypes.Room]) {
                roomTotal = resource.total;
                roomBookable = resource.bookable;
                showRoomSummary = roomTotal > 0 ? true : false;
            }
        });

        return (
            <SummariesContainer showSummaries={showDeskSummary || showCarSpaceSummary || showRoomSummary}>
                <LoadableContainer isLoading={isMetaDataLoading}>
                    {showDeskSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Desks" capacity={deskTotal} population={deskBookable} />
                        </SummaryContainer>
                    )}

                    {showRoomSummary && (
                        <SummaryContainer>
                            <CarouselCard headerText="Meeting Rooms" capacity={roomTotal} population={roomBookable} />
                        </SummaryContainer>
                    )}

                    {showCarSpaceSummary && (
                        <SummaryContainer>
                            <CarouselCard
                                headerText="Car Parks"
                                capacity={carSpaceTotal}
                                population={carSpaceBookable}
                            />
                        </SummaryContainer>
                    )}
                </LoadableContainer>
            </SummariesContainer>
        );
    };

    const generateOccupancyList = (buildings: Array<Admin.Building>): ReactNode => {
        listData = buildings.map((building) => {
            let desksBookable: number | undefined;
            let desksCapacity: number | undefined;
            let meetingRoomBookable: number | undefined;
            let meetingRoomCapacity: number | undefined;
            let carParksBookable: number | undefined;
            let carParksCapacity: number | undefined;

            building.bookables.forEach((bookingType) => {
                if (bookingType.resourceType === BookingTypes[BookingTypes.Desk]) {
                    desksBookable = bookingType.bookable;
                    desksCapacity = bookingType.total;
                }
                if (bookingType.resourceType === BookingTypes[BookingTypes.Room]) {
                    meetingRoomBookable = bookingType.bookable;
                    meetingRoomCapacity = bookingType.total;
                }
                if (bookingType.resourceType === BookingTypes[BookingTypes.CarSpace]) {
                    carParksBookable = bookingType.bookable;
                    carParksCapacity = bookingType.total;
                }
            });

            const data: ListData = {
                id: building.buildingId,
                listItem: {
                    label: `${building.buildingName}, ${building.address.city}, ${building.address.country}`,
                    deskPopulation: desksBookable,
                    deskCapacity: desksCapacity,
                    meetingRoomPopulation: meetingRoomBookable,
                    meetingRoomCapacity: meetingRoomCapacity,
                    carparkPopulation: carParksBookable,
                    carparkCapacity: carParksCapacity,
                    urlPath: `/building-management/buildings/${building.buildingId}`,
                },
            };

            return data;
        });

        return (
            <>
                <ActionContainer>
                    <ManageBuilding />
                </ActionContainer>
                <OccupancyList
                    header="Buildings"
                    icon={<BusinessIcon style={{ fontSize: 28, color: "#333333" }} />}
                    listData={listData}
                />
            </>
        );
    };
    return (
        <Dashboard>
            {hasLoaded ? (
                <Management
                    breadcrumbs={breadcrumbs}
                    summaries={generateSummaryCards()}
                    list={generateOccupancyList(buildings)}
                    selectListItems={allLocations}
                    onClickSelect={selectedItem}
                />
            ) : (
                <Container>
                    <Throbber />
                </Container>
            )}
        </Dashboard>
    );
});

export default Buildings;

const Container = styled.div`
    text-align: center;
    margin-top: 25vh;
`;

const SummariesContainer = styled.div<{ showSummaries?: boolean }>`
    display: flex;
    margin-top: ${(props): string => (props.showSummaries ? "32px" : "0px")};
    height: ${(props): string => (props.showSummaries ? "214px" : "0px")};
`;

const SummaryContainer = styled.div`
    width: 100%;
    padding-right: 8px;
    max-width: 50%;
    &:last-child {
        padding-right: 0;
    }
`;
const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
