import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconPlus extends React.Component<Props> {
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 14 14">
                <path
                    transform="translate(-8, -8)"
                    d="M14,14 L14,9 C14,8.44771525 14.4477153,8 15,8 C15.5522847,8 16,8.44771525 16,9 L16,14 L21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L16,16 L16,21 C16,21.5522847 15.5522847,22 15,22 C14.4477153,22 14,21.5522847 14,21 L14,16 L9,16 C8.44771525,16 8,15.5522847 8,15 C8,14.4477153 8.44771525,14 9,14 L14,14 Z"
                    id="Icon-Plus"
                />
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg``;
