import { SmartSpaces } from "./SmartSpaces";
import {
    createRequest,
    createAdminRequest,
    errorCallback,
    maxContinuousWaitTime,
    continuousWaitTimeIncrease,
} from "./ServiceHandlers";
/**
 * Get customer data, if request fails then retry with an exponentially increasing timeout (capped).
 * @param waitTime the amount the wait time before the request for more information will be sent out.
 * @param authToken the amount the wait time will be increased by.
 */
export const getCustomerWithRetry = async (
    waitTime: number,
    authToken: string,
): Promise<SmartSpaces.WebApp.Customer> => {
    // Wait for given timeout
    return new Promise((resolve) =>
        setTimeout(async () => {
            // Attempt to get the data
            await getCustomer(authToken).then(
                (success) => {
                    resolve(success);
                },
                async (error) => {
                    // Check if we have a 401 unauthorized.
                    await errorCallback(error);

                    // Calculate wait (1 = 1 second).
                    const wait = Math.min(waitTime + continuousWaitTimeIncrease, maxContinuousWaitTime);

                    // If fail attempt to check the magnitude of issue and handle
                    resolve(getCustomerWithRetry(wait, authToken));
                },
            );
        }, waitTime * 1000),
    );
};

/**
 * Gets the details of the customer
 *
 * @param token - the auth token
 */
const getCustomer = async (token: string): Promise<SmartSpaces.WebApp.Customer> => {
    const { body }: { body: string | null } = await createRequest({
        apiPath: `/customer-info`,
        method: "GET",
        authorization: `Bearer ${token}`,
        contentType: "application/json",
    });

    const customer: SmartSpaces.WebApp.Customer = typeof body === "object" ? body : JSON.parse(body);

    // Explicitly pull out payload properties to avoid unexpected data from being passed back into the app.
    return {
        name: customer.name,
        logo: customer.logo,
        recentOccupancyDuration: customer.recentOccupancyDuration,
        pbiReports: customer.pbiReports,
    };
};

/**
 * API Implementation - Customer Subscription Data
 * @param token - the auth token
 */
export const getCustomerFeatures = async (
    authToken: string,
): Promise<Array<SmartSpaces.WebApp.Features.Navigation>> => {
    const { body }: { body: string | Blob | null } = await createAdminRequest({
        apiPath: `/app/customer/subscription`,
        method: "GET",
        authorization: `Bearer ${authToken}`,
        contentType: "application/json",
    });

    const subscriptionData: Array<SmartSpaces.WebApp.Features.Navigation> =
        typeof body === "object" ? body : JSON.parse(body);

    return subscriptionData;
};
