/**
 * Returns Parameter string for Object input.
 * E.g.:
 *      {filter: 'John', id: null, includeAvailability: true}
 *      will return a string as "filter=John&includeAvailability=true"
 *
 * @param params API params in an Object format.
 */

export const generateParamsString = (params: Record<string, unknown>): string => {
    const parameters: Array<Record<string, unknown> | unknown> = [];

    Object.entries(params).forEach(([key, value]) => {
        parameters.push(value !== undefined && value !== null && value !== "" ? `${key}=${value}` : null);
    });

    return parameters.filter(Boolean).join("&");
};
