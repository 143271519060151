import React from "react";
import { RootStore as Store, AppConfig as Config } from "../../services";

export interface AppContext {
    store: Store;
    config: Config;
}

const initialContext: AppContext = { store: {} as Store, config: {} as Config };

// Pass an empty object to initialise context
const appContext = React.createContext<AppContext>(initialContext as AppContext);

export default appContext;
