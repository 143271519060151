type Breakpoint = keyof typeof config.breakpoints;
type MediaWidth = number | undefined | null;

// To be used for any web app configurations
export const config = {
    brand: {
        title: "Smart Spaces",
        logo: "/images/telstra-logo.png",
    },
    breakpoints: {
        desktop: 1220,
        tablet: 1060,
        mobile: 896,
    },
    supportedBrowsers: ["Chrome", "Safari", "Edge", "Mobile Safari", "Samsung Browser", "Chrome Headless", "WebKit"],
    unsupportedBrowserDesc: {
        desktop:
            "To continue to use the Telstra Smart Spaces app, we recommend using the latest stable version of Google Chrome, Safari or Microsoft Edge",
        mobile: "To continue to use the Telstra Smart Spaces app, we recommend using the latest stable mobile version of Google Chrome, Safari or Samsung Internet",
    },
};

const mediaQuery = (minWidth?: MediaWidth, maxWidth?: MediaWidth, type = "screen"): string =>
    `@media ${type ? `only ${type}` : ``} ${minWidth || maxWidth ? `and` : ``} ${
        minWidth ? `(min-width: ${minWidth}px)` : ``
    } ${minWidth && maxWidth ? `and` : ``} ${maxWidth ? `(max-width: ${maxWidth}px)` : ``}`
        .replace(/\s\s+/g, " ")
        .trim();

const minWidth = (breakpoint: number): string => mediaQuery(breakpoint, undefined);

const maxWidth = (breakpoint: number): string => mediaQuery(undefined, breakpoint);

const between = (minWidth: Breakpoint, maxWidth: Breakpoint): string =>
    mediaQuery(config.breakpoints[minWidth] + 1, config.breakpoints[maxWidth]);

/**
 * Media query for the given screen size
 */
export const media = {
    mobile: maxWidth(config.breakpoints.mobile),
    tablet: maxWidth(config.breakpoints.tablet),
    desktop: maxWidth(config.breakpoints.desktop),
    print: mediaQuery(null, null, "print"),
    custom: mediaQuery,
    between: between,
    minWidth: minWidth,
    maxWidth: maxWidth,
};

/**
 * renderEnv: For conditional rendering/functionality based on dev environment
 */
export const renderEnv = {
    dev: "https://ss2devv1apim.azure-api.net/smartspaces-dev-webapp-api",
    staging: "https://ss2stagingv1apim.azure-api.net/smartspaces-staging-webapp-api",
    mock: "http://localhost:3000",
};

const App = { config, media, renderEnv };

export default App;
