import React, { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { media, config } from "../../../config/Config";
// -- component imports
import Toggle from "../../Button/ToggleButton/Toggle";
import PopulationCard from "../../Tiles/PopulationCard/PopulationCard";
import ManageFloor from "../../../views/Management/Floors/ManageFloor/ManageFloor";
import ManageBuilding from "../../../views/Management/Buildings/ManageBuilding/ManageBuilding";

export interface Props {
    header: string;
    label: string;
    id: number;
    deskPopulation?: number | undefined;
    deskCapacity?: number | undefined;
    meetingRoomPopulation?: number | undefined;
    meetingRoomCapacity?: number | undefined;
    carSpacePopulation?: number | undefined;
    carSpaceCapacity?: number | undefined;
    urlPath?: string;
    displayToggle?: boolean;
    toggleText?: "Bookable";
    bookable?: boolean;
    onChangeBookable?: (bookable: boolean) => void;
}

const OccupancyListItem: FC<Props> = (props: Props) => {
    const {
        header,
        label,
        id,
        deskPopulation,
        deskCapacity,
        meetingRoomPopulation,
        meetingRoomCapacity,
        carSpacePopulation,
        carSpaceCapacity,
        urlPath,
        bookable,
        onChangeBookable,
    } = props;
    const bookablePercent = (population: number | undefined, capacity: number | undefined): number => {
        if (typeof population === "number" && typeof capacity === "number") {
            const bookable = Math.round((population / capacity) * 100);
            return bookable < 100 ? bookable : 100;
        } else return 0;
    };

    //This variable is used to render the toggle if the display toggle flag is true
    const renderToggle = (
        <ToggleWrapper>
            <Toggle
                id={"floorToggle"}
                startEnabled={bookable || false}
                readonly={false}
                toggleOnText="Bookable"
                toggleOffText="Not Bookable"
                enabledStateColour="#38822b"
                offStateColour="#a4393e"
                onChange={onChangeBookable || ((x) => x)}
            ></Toggle>
        </ToggleWrapper>
    );
    return (
        <FlexContainer
            tabIndex={0}
            maxWidth={config.breakpoints.tablet}
            minWidth={config.breakpoints.mobile}
            $bookable={bookable}
            aria-label={`
        Building ${label} with
        ${deskPopulation ? bookablePercent(deskPopulation, deskCapacity) + "% bookable desks" : ""}
        ${carSpacePopulation ? bookablePercent(carSpacePopulation, carSpaceCapacity) + "% bookable car spaces" : ""}
     `}
        >
            {header === "Floors" && <ManageFloor isEdit={true} editedFloorId={id} />}
            {header === "Buildings" && <ManageBuilding isEdit={true} editedBuildingId={id} />}
            <EditTileContainer>
                <AddressText>{label}</AddressText>
            </EditTileContainer>
            <ToggleContainer>{props.displayToggle && renderToggle}</ToggleContainer>
            <PopulationCard label="Desks" capacity={deskCapacity} population={deskPopulation} bookable={bookable} />
            <PopulationCard
                label="Meeting Rooms"
                capacity={meetingRoomCapacity}
                population={meetingRoomPopulation}
                bookable={bookable}
            />
            <PopulationCard
                label="Carparks"
                capacity={carSpaceCapacity}
                population={carSpacePopulation}
                bookable={bookable}
            />
            {urlPath ? (
                <SelectBuilding $bookable={bookable} to={urlPath} aria-label="Select building">
                    <ChevronRightIcon />
                </SelectBuilding>
            ) : (
                <DisabledLink />
            )}
        </FlexContainer>
    );
};

export default OccupancyListItem;

const DisabledLink = styled.div`
    min-width: 48px;
`;

const AddressText = styled.div`
    font-weight: 700;
    color: #333333;
    text-align: left;
    line-height: normal;
    padding-left: 43px;
    font-size: 12px;
    overflow-wrap: break-word;
`;

const FlexContainer = styled.div<{ maxWidth: number; minWidth: number; $bookable?: boolean }>`
    display: flex;
    min-height: 72px;
    background: ${(props): string => (props.$bookable === false ? "#fcf1f2" : "#ffffff")};
    min-width: ${(props): string => props.minWidth + "px"};
    max-width: ${(props): string => props.maxWidth + "px"};
`;

const EditTileContainer = styled.div`
    flex: 4;
    height: 72px;
    max-height: 60px;
    padding-top: 14px;
    overflow: hidden;
    ${media.tablet} {
        width: 180px;
    }
`;

const SelectBuilding = styled(Link)<{ $bookable?: boolean }>`
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
    color: #333333;
    background-color: ${(props): string => (props.$bookable === false ? "#fcf1f2" : "#ffffff")};
    border: none;
    padding-left: 12px;
    padding-right: 12px;
`;

const ToggleWrapper = styled.div`
    min-width: 80px;
    overflow-wrap: break-word;
    justify-content: center;
    font-weight: 700;
    color: #333333;
    text-align: left;
    line-height: normal;
    font-size: 7px;
    text-align: "center";
    text-align: justify;
`;

const ToggleContainer = styled.div`
    display: flex;
    flex 4;
    padding-bottom:10px;
    overflow-wrap: break-word;
    justify-content: center;
    text-align: "center";
    text-align: justify;
`;
