import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconBuilding extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconBuilding
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="100%" height="100%" viewBox="0 0 32 38" fillColor={this.fillColor()}>
                <g id="IconBuilding-Icon" transform="translate(-13.000000, -9.000000)" fillRule="nonzero">
                    <path
                        d="M43.0173611,9 L43.017,43.86 L44.6666667,43.8608696 L44.6666667,47 L13,47 L13,43.8608696 L14.484,43.86 L14.484375,9 L43.0173611,9 Z M40.0486111,12.1391304 L17.7829861,12.1391304 L17.7829861,43.8608696 L22.565,43.86 L22.5659722,35.9304348 L35.265625,35.9304348 L35.265,43.86 L40.0486111,43.8608696 L40.0486111,12.1391304 Z M31.9670139,39.0695652 L25.6996528,39.0695652 L25.6996528,43.8608696 L31.9670139,43.8608696 L31.9670139,39.0695652 Z M24.0503472,28 L24.0503472,31.1391304 L20.9166667,31.1391304 L20.9166667,28 L24.0503472,28 Z M30.3177083,28 L30.3177083,31.1391304 L27.1840278,31.1391304 L27.1840278,28 L30.3177083,28 Z M36.75,28 L36.75,31.1391304 L33.6163194,31.1391304 L33.6163194,28 L36.75,28 Z M24.0503472,21.7217391 L24.0503472,24.8608696 L20.9166667,24.8608696 L20.9166667,21.7217391 L24.0503472,21.7217391 Z M30.3177083,21.7217391 L30.3177083,24.8608696 L27.1840278,24.8608696 L27.1840278,21.7217391 L30.3177083,21.7217391 Z M36.75,21.7217391 L36.75,24.8608696 L33.6163194,24.8608696 L33.6163194,21.7217391 L36.75,21.7217391 Z M24.0503472,15.2782609 L24.0503472,18.4173913 L20.9166667,18.4173913 L20.9166667,15.2782609 L24.0503472,15.2782609 Z M30.3177083,15.2782609 L30.3177083,18.4173913 L27.1840278,18.4173913 L27.1840278,15.2782609 L30.3177083,15.2782609 Z M36.75,15.2782609 L36.75,18.4173913 L33.6163194,18.4173913 L33.6163194,15.2782609 L36.75,15.2782609 Z"
                        id="Icon-Building"
                    />
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
