import React, { ReactNode } from "react";
import styled from "styled-components";
import { SmartSpaces } from "../../services/WebService/SmartSpaces";
import BuildingListItem from "./BuildingListItem";
import SVGIconBuilding from "../Icon/Building";
import SVGIconLocation from "../Icon/LocationPin";
/**
 * Definition of the data required for each list item.
 *
 * @interface ListItemData
 */

/**
 * Props
 */
export interface Props {
    icon: JSX.Element;
    data: Array<SmartSpaces.WebApp.Space>;
}

/**
 * State
 */

export default class List extends React.Component<Props> {
    private generateListItem = (listItem: SmartSpaces.WebApp.Space): React.ReactNode => (
        <BuildingListItem
            icon={<SVGIconBuilding />}
            subtitleIcon={
                <LocationPinIcon>
                    <SVGIconLocation />
                </LocationPinIcon>
            }
            key={listItem.id}
            listItem={listItem}
        />
    );

    /**
     * Count Render
     */
    public render(): ReactNode {
        const buildingsData = this.props.data && this.props.data.map((data) => this.generateListItem(data));
        return (
            <Container role="buildingList">
                <ListContent>{buildingsData}</ListContent>
            </Container>
        );
    }
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
`;

const ListContent = styled.div`
    overflow: hidden;
    position: relative;
    padding: 0px 24px 18px 24px;
    & > div {
        margin-bottom: 12px;
    }
`;

const LocationPinIcon = styled.div`
    user-select: none;
    // See: https://stackoverflow.com/a/53336754/699963
    // Target color is #007AF8.
    filter: brightness(0) saturate(100%) invert(33%) sepia(93%) saturate(4652%) hue-rotate(198deg) brightness(104%)
        contrast(106%);
`;
