import React, { FC, ReactNode, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ToggleAssetModal from "../../../../components/Windows/ToggleAssetModal/ToggleAssetModal";
import AssetName from "../AssetName/AssetName";
import {
    ClearLink,
    FeatureGroupWrapper,
    InputTextWrapper,
    ModalText,
    RadioGroupWrapper,
    StyledLabel,
} from "../DisplayAssetModal/DisplayAssetModal";
import RadioButtonGroup, { Option } from "../../../../components/Input/RadioButtonGroup/RadioButtonGroup";
import CheckboxGroup, { Option as CheckboxOption } from "../../../../components/Input/CheckboxGroup/CheckboxGroup";
import { toJS } from "mobx";
import { useStore } from "../../../../services";
import { Admin } from "../../../../services/WebService/Admin/ApiDefinition";
import { useParams } from "react-router-dom";

export type DeskDetails = {
    name: string;
    buildingId?: number;
    floorId?: number;
    resourceFeatures: Array<Admin.ResourceFeature> | undefined;
};

const AddDesk: FC = observer(() => {
    const [showModal, setShowModal] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deskName, setDeskName] = useState("");
    const [deskDetails, setDeskDetails] = useState<DeskDetails>({
        name: "",
        resourceFeatures: [],
    });
    let selectedOptionForRadio = "";
    const buildingId = parseInt((useParams() as { buildingId: string }).buildingId);
    const floorId = parseInt((useParams() as { floorId: string }).floorId);
    const store = useStore();
    const deskFeatureGroups = toJS(store.data.management.customerDeskFeatureGroups);
    const handleAddDesk = (): void => {
        setShowModal(true);
        setDeskName("");
    };

    const modalCancel = () => {
        setShowModal(false);
        setIsError(false);
    };

    const handleChange = (desk: string) => {
        setDeskName(desk);
        if (desk === "" || desk.length > 35) setIsSubmitDisabled(true);
        else setIsSubmitDisabled(false);
    };

    const saveDesk = () => {
        const deskProperties = {
            name: deskName,
            deskNumber: 0, // ToDo - Remove it when API updates
            buildingId: buildingId,
            floorId: floorId,
            is_bookable: true,
            is_covid_safe: true,
            resourceFeatures: deskDetails.resourceFeatures,
        };

        const updatedAssetPromise = store.subscription.management.saveDeskDetails(deskProperties);
        updatedAssetPromise
            .then(() => {
                store.subscription.management.getCustomersResources(buildingId, floorId, true, "SVG");
                store.subscription.management.getAllCustomerMetaData(undefined, buildingId, floorId);
                setShowModal(false);
            })
            .catch(() => {
                setIsError(true);
                setIsSubmitDisabled(true);
            });
    };

    /**
     * common method for both checkbox and radio button onChange
     * @param optionNames for radio will be a single value in array
     * @param featureGroupName
     */
    const updateSelectedAssetFeature = (optionNames: Array<string>, featureGroupName: string) => {
        // clear existing state values of selected featureGroup
        const deskDetailsCopy = { ...deskDetails };
        const updatedFeatures = deskDetailsCopy.resourceFeatures?.filter(
            (feature) => feature.name?.toLowerCase() !== featureGroupName.toLowerCase(),
        );
        // update the selected values in state
        const selectedFeatureGroup = deskFeatureGroups.find(
            (assetFeatureGroup) => assetFeatureGroup.name === featureGroupName,
        );

        const featureOptions = selectedFeatureGroup?.featureOptions.filter((featureOption) =>
            optionNames.includes(featureOption.optionName),
        );

        featureOptions &&
            featureOptions.forEach((featureOption) => {
                updatedFeatures?.push({
                    icon: featureOption.icon,
                    optionId: featureOption.optionId,
                    optionName: featureOption.optionName,
                    name: featureGroupName,
                });
            });

        deskDetailsCopy.resourceFeatures = updatedFeatures;
        setDeskDetails(deskDetailsCopy);
    };

    const isScreenLarge = window.matchMedia("(min-height: 720px)").matches;

    // Clear particular selection
    const onClearHandler = (featureGroupName: string) => {
        setDeskDetails({
            name: "",
            resourceFeatures: [],
        });
    };

    const constructFeatureOptions = (): ReactNode => {
        return (
            deskFeatureGroups?.length &&
            deskFeatureGroups?.map((assetFeatureGroup: Admin.AssetFeatureGroups) => {
                const { id, name, featureOptions, isMultipleSelectable } = assetFeatureGroup;
                const radioOptions: Array<Option> = [];

                const checkboxOptions: Array<CheckboxOption> = [];
                const selectedOptionsForCheckbox: Array<string> = [];
                const shouldDisplayCheckbox = isMultipleSelectable || featureOptions.length === 1;

                featureOptions.forEach((featureOption) => {
                    const { optionName } = featureOption;
                    !shouldDisplayCheckbox
                        ? radioOptions.push({
                              label: optionName,
                              value: optionName,
                          })
                        : checkboxOptions.push({
                              label: optionName,
                              value: optionName,
                          });
                    if (deskDetails?.resourceFeatures?.find((assetFeature) => assetFeature.optionName === optionName)) {
                        !shouldDisplayCheckbox
                            ? (selectedOptionForRadio = optionName)
                            : selectedOptionsForCheckbox.push(optionName);
                    }
                });

                return (
                    <FeatureGroupWrapper key={`r${id}`}>
                        {!shouldDisplayCheckbox ? (
                            <>
                                <RadioGroupWrapper>
                                    <StyledLabel>{name}</StyledLabel>
                                    <RadioButtonGroup
                                        name={name}
                                        options={radioOptions}
                                        defaultSelection={selectedOptionForRadio}
                                        width="288px"
                                        onChange={(selectedOption) =>
                                            updateSelectedAssetFeature([selectedOption], name)
                                        }
                                    />
                                </RadioGroupWrapper>
                                <ClearLink onClick={() => onClearHandler(name)}>
                                    Clear {name.toLowerCase()} selection
                                </ClearLink>
                            </>
                        ) : (
                            <>
                                <StyledLabel>{name}</StyledLabel>
                                <CheckboxGroup
                                    name={name}
                                    options={checkboxOptions}
                                    defaultSelection={selectedOptionsForCheckbox}
                                    width="288px"
                                    onChange={(selectedOptions) => updateSelectedAssetFeature(selectedOptions, name)}
                                />
                            </>
                        )}
                    </FeatureGroupWrapper>
                );
            })
        );
    };

    const modal = (
        <ToggleAssetModal
            title={isError ? "Sorry, an error has occurred" : "Add Desk"}
            renderCheckbox={false}
            checkboxText=""
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            height={isScreenLarge ? "608px" : "518px"}
            width="712px"
            contentWidth="632px"
            contentHeight={isScreenLarge ? "446px" : "326px"}
            onClickPrimary={saveDesk}
            onClickSecondary={modalCancel}
            onEscapeClicked={modalCancel}
            isButtonDisabled={isSubmitDisabled}
        >
            {isError ? (
                <ModalText>Please try again later.</ModalText>
            ) : (
                <>
                    <InputTextWrapper>
                        <AssetName
                            label="Desk name"
                            value={deskName}
                            onNameChange={(desk) => handleChange(desk.toString())}
                            assetTypeLabel={""}
                        />
                    </InputTextWrapper>

                    {constructFeatureOptions()}
                </>
            )}
        </ToggleAssetModal>
    );

    return (
        <>
            {showModal && modal}
            <ActionButton onClick={handleAddDesk}>
                <span>
                    <AddCircleOutlineIcon />
                    &nbsp;
                </span>
                Add Desk
            </ActionButton>
        </>
    );
});

export default AddDesk;

const ActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
    border: none;
    background: none;
    color: #001e82;
    margin-right: 15px;
`;
