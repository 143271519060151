import React, { ReactNode } from "react";
import styled from "styled-components";

/**
 * Props
 */
interface Props {
    fill?: string;
}

export default class SVGIconLocation extends React.Component<Props> {
    /**
     * Sets colour depending on user input, if no colour given then set to a default of black.
     *
     * @private
     * @memberof SVGIconLocation
     */
    private fillColor = (): string => {
        // If no colour provided then default to black.
        if (!this.props.fill) {
            return "#000000";
        }
        // If we have a valid colour pass down.
        return this.props.fill;
    };

    /**
     * Render
     */
    public render(): ReactNode {
        return (
            <SVGWrapper width="9px" height="12px" viewBox="0 0 9 12" fillColor={this.fillColor()}>
                <g id="3327---Floor-Selection" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g
                        id="Floor---Hovering-over-a-list-item"
                        transform="translate(-122.000000, -230.000000)"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    >
                        <g id="Location_List--Expanded" transform="translate(24.000000, 86.000000)">
                            <g id="List" transform="translate(0.000000, 100.000000)">
                                <g id="Location" transform="translate(98.000000, 40.000000)">
                                    <path
                                        d="M0.718786854,10.9410515 L0.741302085,10.9735735 C0.7688207,11.0148515 0.797590162,11.0548785 0.826359624,11.0986582 L4.08606472,15.7818262 C4.18124876,15.9185172 4.33728071,16 4.50384734,16 C4.67041397,16 4.82644592,15.9185172 4.92162996,15.7818262 L8.17382998,11.1024107 C8.20510114,11.0586311 8.23512144,11.0161023 8.26514175,10.9773261 L8.28515529,10.9473058 L8.28515529,10.9473058 C9.50229483,9.06406036 9.14838565,6.57226375 7.45500477,5.10239875 C5.76162388,3.63253375 3.24481995,3.63253375 1.55143907,5.10239875 C-0.141941813,6.57226375 -0.495850994,9.06406036 0.721288546,10.9473058 L0.718786854,10.9410515 Z M4.50384734,6.62938482 C5.54008223,6.62938482 6.38011658,7.46941917 6.38011658,8.50565406 C6.38011658,9.54188895 5.54008223,10.3819233 4.50384734,10.3819233 C3.46761245,10.3819233 2.6275781,9.54188895 2.6275781,8.50565406 C2.6275781,7.46941917 3.46761245,6.62938482 4.50384734,6.62938482 L4.50384734,6.62938482 Z"
                                        id="Icon-Pin--Inverse"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </SVGWrapper>
        );
    }
}

const SVGWrapper = styled.svg<{ fillColor: string }>`
    fill: ${(props): string => props.fillColor};
`;
